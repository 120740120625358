import { useState } from 'react';
import styled from 'styled-components';
import { ResourceCount } from '../../engine/types';
import { Resources } from '../player/resources/Resources';
import { ActionButton } from '@adobe/react-spectrum';

type ResourcesSelectorCallback = (selection: ResourceCount) => void;

interface Props {
  resources: ResourceCount;
  onSelect: ResourcesSelectorCallback;
}

const Selector = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  inset: 0;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(2px);
  gap: 16px;
`;

export const DiscardResourcesSelector = ({ resources, onSelect }: Props) => {
  const [amountSelected, setAmountSelected] = useState<ResourceCount>({});

  const handleSelect = () => {
    onSelect(amountSelected);
  };

  const initialNumResources = Object.values(resources).reduce(
    (acc, resAmount) => acc + resAmount,
    0
  );
  const numSelectedResources = Object.values(amountSelected).reduce(
    (acc, resAmount) => acc + resAmount,
    0
  );
  const left = Math.floor(initialNumResources / 2) - numSelectedResources;

  return (
    <Selector>
      <Resources
        resources={resources}
        selectable={left > 0}
        onSelectionChange={setAmountSelected}
        selectionType='discard'
      />
      <ActionButton isDisabled={left > 0} onPress={handleSelect}>
        Discard {left > 0 && ` (pick ${left} more)`}
      </ActionButton>
    </Selector>
  );
};

DiscardResourcesSelector.displayName = 'DiscardResourcesSelector';
