import { BaseTile, Tile } from './tile';
import { Tiles } from './boardHelpers';
import { Corner } from './corner';
import { Edge } from './edge';
import { Player } from './player';

export function getTileCoords(tile: BaseTile): number[] {
  return tile.tileId.split(',').map(Number);
}

/**
 * Tile's corners, and where to find them.
 *
 * Listed starting north and clock-wise, that is
 * [N, NE, SE,
 * S, SW, NW]
 *
 * Each entry is defined as the "coords modifier" to spot the
 * tile that contains the corner to use, and which one of its
 * corners we need to use.
 *
 * [q, r, s, TileCorner]
 *
 * E.g, for Tile in coords 0,0,0:
 * - N(orth) corner is owned by itself, therefore the modifier
 *    to locate the tile is all 0's -> [0, 0, 0, N]
 * - NE corner is owned by its north-eastern tile,
 *    southern corner -> [1, -1, 0, S]
 * - etc...
 */
export enum TileCorner {
  N = 0,
  S = 1,
}

export enum TileCornerDir {
  N = 0,
  NE,
  SE,
  S,
  SW,
  NW,
}

// Hacky helper to get the equivalent TileCornerDir from TileCorner assuming the current tile
// is the owner of this corner.
export function getTileCornerDirFromHoldingTile(
  tileCorner: TileCorner
): TileCornerDir | undefined {
  return tileCorner === TileCorner.N
    ? TileCornerDir.N
    : tileCorner === TileCorner.S
    ? TileCornerDir.S
    : undefined;
}

const CornerLocations: [number, number, number, TileCorner][] = [
  [0, 0, 0, TileCorner.N], // N
  [1, -1, 0, TileCorner.S], // NE
  [0, 1, -1, TileCorner.N], // SE
  [0, 0, 0, TileCorner.S], // S
  [-1, 1, 0, TileCorner.N], // SW
  [0, -1, 1, TileCorner.S], // NW
];

/**
 * Tile's Edges and were to find them.
 *
 * Same process as for corners.
 */
export enum TileEdge {
  NE = 0,
  NW = 1,
  W = 2,
}

export enum TileEdgeDir {
  NE = 0,
  E,
  SE,
  SW,
  W,
  NW,
}

// Hacky helper to get the equivalent TileEdgeDir from TileEdge assuming the current tile
// is the owner of this Edge.
export function getTileEdgeDirFromHoldingTile(
  tileEdge: TileEdge
): TileEdgeDir | undefined {
  return tileEdge === TileEdge.NE
    ? TileEdgeDir.NE
    : tileEdge === TileEdge.NW
    ? TileEdgeDir.NW
    : tileEdge === TileEdge.W
    ? TileEdgeDir.W
    : undefined;
}

const EdgeLocations: [number, number, number, TileEdge][] = [
  [0, 0, 0, TileEdge.NE], // NE
  [1, 0, -1, TileEdge.W], // E
  [0, 1, -1, TileEdge.NW], // SE
  [-1, 1, 0, TileEdge.NE], // SW
  [0, 0, 0, TileEdge.W], // W
  [0, 0, 0, TileEdge.NW], // NW
];

/**
 * Get tile corner, given its location (dir): N, NE, SE, etc...
 */
export function getCorner(
  tile: BaseTile,
  dir: TileCornerDir,
  tiles: Tiles
): Corner | undefined {
  const [q, r, s] = getTileCoords(tile);
  const [qd, rd, sd, dird] = CornerLocations[dir]; // d = delta
  const tileId = [q + qd, r + rd, s + sd].join(',');
  return tiles[tileId]?.getCorners()[dird];
}

/**
 * Get all corners of a given tile
 */
export function getCorners(tile: BaseTile, tiles: Tiles): Corner[] {
  const [q, r, s] = getTileCoords(tile);
  return CornerLocations.map((l) => {
    const [qd, rd, sd, dird] = l;
    const tileId = [q + qd, r + rd, s + sd].join(',');
    return tiles[tileId].getCorners()[dird];
  });
}

export function getEdge(tile: BaseTile, dir: TileEdgeDir, tiles: Tiles): Edge {
  const [q, r, s] = getTileCoords(tile);
  const [qd, rd, sd, dird] = EdgeLocations[dir];
  const tileId = [q + qd, r + rd, s + sd].join(',');
  return tiles[tileId].getEdges()[dird];
}

export function getEdges(tile: BaseTile, tiles: Tiles): Edge[] {
  const [q, r, s] = getTileCoords(tile);
  return EdgeLocations.map((l) => {
    const [qd, rd, sd, dird] = l;
    const tileId = [q + qd, r + rd, s + sd].join(',');
    return tiles[tileId].getEdges()[dird];
  });
}

/**
 * Endpoints for each edge of a tile.
 * Corners given for the same tile (i.e, use `getCorner()` helper
 * to determine the final corner for the given direction).
 * The pair of endpoints are always listed in clock-wise order.
 * E.g, for TileEdgeDir.W -> [TileCornerDir.SW, TileCornerDir.NW]
 */
const edgeEndpoints = {
  [TileEdgeDir.NE]: [TileCornerDir.N, TileCornerDir.NE],
  [TileEdgeDir.E]: [TileCornerDir.NE, TileCornerDir.SE],
  [TileEdgeDir.SE]: [TileCornerDir.SE, TileCornerDir.S],
  [TileEdgeDir.SW]: [TileCornerDir.S, TileCornerDir.SW],
  [TileEdgeDir.W]: [TileCornerDir.SW, TileCornerDir.NW],
  [TileEdgeDir.NW]: [TileCornerDir.NW, TileCornerDir.N],
};

export function getEdgeEndpoints(
  tile: BaseTile,
  dir: TileEdgeDir,
  tiles: Tiles
): (Corner | undefined)[] {
  return edgeEndpoints[dir].map((d) => getCorner(tile, d, tiles));
}

/**
 * Corner protrudes are the edges that extends from a given corner.
 */
const cornerProtrudes: {
  [id: number]: [number, number, number, TileEdgeDir][];
} = {
  [TileCornerDir.N]: [
    [1, -1, 0, TileEdgeDir.W],
    [0, 0, 0, TileEdgeDir.NE],
    [0, 0, 0, TileEdgeDir.NW],
  ],
  [TileCornerDir.NE]: [
    [1, -1, 0, TileEdgeDir.SE],
    [0, 0, 0, TileEdgeDir.E],
    [0, 0, 0, TileEdgeDir.NE],
  ],
  [TileCornerDir.SE]: [
    [0, 0, 0, TileEdgeDir.E],
    [0, 1, -1, TileEdgeDir.NE],
    [0, 0, 0, TileEdgeDir.SE],
  ],
  [TileCornerDir.S]: [
    [0, 0, 0, TileEdgeDir.SE],
    [0, 1, -1, TileEdgeDir.W],
    [0, 0, 0, TileEdgeDir.SW],
  ],
  [TileCornerDir.SW]: [
    [0, 0, 0, TileEdgeDir.W],
    [0, 0, 0, TileEdgeDir.SW],
    [-1, 0, 1, TileEdgeDir.SE],
  ],
  [TileCornerDir.NW]: [
    [0, 0, 0, TileEdgeDir.NW],
    [0, 0, 0, TileEdgeDir.W],
    [-1, 0, 1, TileEdgeDir.NE],
  ],
};

/**
 * Returns the location of the neighbor edges for a given corner.
 *
 * Note: This returns a [tileId, dird] for each result, where dird is
 * a TileEdgeDir (aka, any direction in the tile), NOT the TileEdge (aka,
 * the directions of the edges that such tile HOLDS).
 *
 * If you want to get the final edge, use `getEdge(tiles[tileId], dird, tiles)`
 * see getCornerEdges below as an example.
 */
export function getCornerEdgesLoc(
  tile: BaseTile,
  dir: TileCornerDir
): [string, TileEdgeDir][] {
  const [q, r, s] = getTileCoords(tile);
  return cornerProtrudes[dir].map((edgeLoc) => {
    const [qd, rd, sd, dird] = edgeLoc;
    const tileId = [q + qd, r + rd, s + sd].join(',');
    return [tileId, dird];
  });
}

export function getCornerEdges(
  tile: BaseTile,
  dir: TileCornerDir,
  tiles: Tiles
): Edge[] {
  return getCornerEdgesLoc(tile, dir)
    .map(([tileId, dirInTile]) =>
      tiles[tileId] ? getEdge(tiles[tileId], dirInTile, tiles) : null
    )
    .filter(Boolean) as Edge[];
}

/**
 * TODO: TESTS!!
 * @param corner
 * @param tiles
 * @returns
 */
export function getCornerEdgesFromCorner(
  corner: Corner,
  tiles: Tiles
): Edge[] | undefined {
  const cornerLocation = getCornerLocation(corner, tiles);
  if (!cornerLocation) {
    return;
  }
  const tileCornerDir = getTileCornerDirFromHoldingTile(cornerLocation.dir);
  if (tileCornerDir === undefined) {
    return;
  }
  return getCornerEdges(cornerLocation.tile, tileCornerDir, tiles);
}

/**
 * Return all the edges that are neighbors of a given one.
 * The order of the edges are:
 * - Pick each edge's corner in clockwise order
 * - On each edge, return each edge (except for the given one) in clockwise order
 */
export function getEdgeNeighbors(
  tile: BaseTile,
  dir: TileEdgeDir,
  tiles: Tiles
): Edge[] {
  const mainEdge = getEdge(tile, dir, tiles);
  const edges: Edge[] = [];

  edgeEndpoints[dir].forEach((d) => {
    getCornerEdges(tile, d, tiles).forEach((edge) => {
      if (edge !== mainEdge) {
        edges.push(edge);
      }
    });
  });

  return edges;
}

export function getCornerNeighbors(
  tile: BaseTile,
  dir: TileCornerDir,
  tiles: Tiles
): (Corner | undefined)[] {
  const mainCorner = getCorner(tile, dir, tiles);
  let corners: (Corner | undefined)[] = [];

  getCornerEdgesLoc(tile, dir).forEach(([tileId, dirInTile]) => {
    corners = corners.concat(
      getEdgeEndpoints(tiles[tileId], dirInTile, tiles).filter(
        (corner) => corner !== mainCorner
      )
    );
  });
  return corners;
}

/**
 * TODO: TESTS!!
 */
export function getCornerNeighborsWithEdge(
  tile: BaseTile,
  dir: TileCornerDir,
  tiles: Tiles
): { corner: Corner; edge: Edge }[] {
  const mainCorner = getCorner(tile, dir, tiles);
  let corners: { corner: Corner; edge: Edge }[] = [];

  getCornerEdgesLoc(tile, dir).forEach(([tileId, dirInTile]) => {
    corners = corners.concat(
      getEdgeEndpoints(tiles[tileId], dirInTile, tiles)
        .filter(Boolean)
        .filter((corner) => corner !== mainCorner)
        .map((corner) => ({
          corner: corner!,
          edge: getEdge(tiles[tileId], dirInTile, tiles),
        }))
    );
  });
  return corners;
}

/**
 * TODO: TESTS!!
 * @returns tile and dir of the given corner
 */
export function getCornerLocation(
  corner: Corner,
  tiles: Tiles
): { tile: BaseTile; dir: TileCorner } | undefined {
  const tile = getTileForCorner(corner, tiles);
  if (tile) {
    return { tile, dir: tile.getCorners().indexOf(corner) };
  }
}

/**
 * Checks that rules apply to place a road for a given player, those are
 * - a. One of (2) edge's corners is owned by given player (with settlement or city)
 * - b. There's at least one neighboring edge owned by same player
 */
export function assertPlaceRoad(
  tile: BaseTile,
  dir: TileEdgeDir,
  tiles: Tiles,
  player: Player,
  doNotThrow = false
): boolean {
  // Assert player's settlement/city in any endpoint
  const [corner1, corner2] = getEdgeEndpoints(tile, dir, tiles);
  if (corner1?.isOwner(player) || corner2?.isOwner(player)) {
    return true;
  }

  // Assert player's road in any neighboring edge
  const neighbors = getEdgeNeighbors(tile, dir, tiles);
  if (!!neighbors.filter((edge) => edge.isOwner(player)).length) {
    return true;
  }

  if (doNotThrow) return false;
  throw new Error(
    `[Road Assert] Invalid road for user ${player.getName()} in tile ${
      tile.tileId
    } at edge ${dir}. It violates game rules.`
  );
}

/**
 * Check that Catan's rules apply to create a settlement:
 * - 1. At least a player's road reaches the corner AND
 * - 2. No settlement/city (disregard the player) is placed in a neighbor corner
 * - 3. No settlement/city is placed in this place already
 */
export function assertPlaceSettlement(
  tile: BaseTile,
  dir: TileCornerDir,
  tiles: Tiles,
  player: Player,
  bypassAssertForGameStartup = false,
  doNotThrow = false // only return false if can't be built
): boolean {
  const corner = getCorner(tile, dir, tiles);
  if (!!corner?.getOwner()) {
    if (doNotThrow) return false;
    throw new Error(
      `[Assert settlement] Unable to build. A player already owns this place: ${corner
        .getOwner()
        ?.getName()}`
    );
  }

  const playerRoads = getCornerEdges(tile, dir, tiles).filter((edge) =>
    edge.isOwner(player)
  );

  if (playerRoads.length === 0 && !bypassAssertForGameStartup) {
    if (doNotThrow) return false;
    throw new Error(
      '[Assert settlement] Unable to build. No player roads reach this corner.'
    );
  }

  const neighborBuildings = getCornerNeighbors(tile, dir, tiles).filter(
    (corner) => !!corner?.getOwner()
  );

  if (neighborBuildings.length > 0) {
    if (doNotThrow) return false;
    throw new Error(
      '[Assert settlement] Unable to build. A neighbor corner has a building.'
    );
  }

  return true;
}

export function assertPlaceCity(
  tile: BaseTile,
  dir: TileCornerDir,
  tiles: Tiles,
  player: Player,
  doNotThrow = false // only return false if can't be built
): boolean {
  const corner = getCorner(tile, dir, tiles);
  if (!corner) {
    if (doNotThrow) return false;
    throw new Error(
      `[Assert city] No corner found in tile: ${tile.tileId} dir: ${dir}.`
    );
  }

  const owner = corner.getOwner();
  if (!owner || owner !== player) {
    if (doNotThrow) return false;
    throw new Error(
      '[Assert city] Unable to build. Corner belongs to another user or no settlement found.'
    );
  }

  if (corner.hasCity()) {
    if (doNotThrow) return false;
    throw new Error('[Assert city] There is already a city in this place');
  }

  return true;
}

/**
 * Relative position of each neighbor of a tile, per direction (directions match the edges)
 */
const tileNeighbors: {
  [id: number]: [number, number, number];
} = {
  [TileEdgeDir.NW]: [0, -1, 1],
  [TileEdgeDir.NE]: [1, -1, 0],
  [TileEdgeDir.E]: [1, 0, -1],
  [TileEdgeDir.SE]: [0, 1, -1],
  [TileEdgeDir.SW]: [-1, 1, 0],
  [TileEdgeDir.W]: [-1, 0, 1],
};

/**
 * Get all the neighbor tiles of a given tile
 */
function getTileNeighborTiles(
  tile: Tile,
  tiles: Tiles
): { [dir: number]: Tile } {
  const [q, r, s] = getTileCoords(tile);
  const neighbors: { [dir: number]: Tile } = {};

  for (let dir in tileNeighbors) {
    const [qd, rd, sd] = tileNeighbors[dir];
    const tileId = [q + qd, r + rd, s + sd].join(',');
    neighbors[dir] = tiles[tileId] as Tile;
  }

  return neighbors;
}

/**
 * Returns the Tiles that match a given Corner
 * 1. find the tile this corner belongs to
 * 2. depending on it being a North or South corner, we'll check the respective neighbors
 */
export function getCornerTiles(corner: Corner, tiles: Tiles): Tile[] {
  const cornerTiles: Tile[] = [];

  const tile = getTileForCorner(corner, tiles);

  if (tile) {
    cornerTiles.push(tile);

    const neighbors = getTileNeighborTiles(tile, tiles);

    // If it's a north corner
    if (tile.getCorners()[TileCorner.N] === corner) {
      cornerTiles.push(neighbors[TileEdgeDir.NW]);
      cornerTiles.push(neighbors[TileEdgeDir.NE]);
    } else {
      // if it's a south corner
      cornerTiles.push(neighbors[TileEdgeDir.SW]);
      cornerTiles.push(neighbors[TileEdgeDir.SE]);
    }
  }

  return cornerTiles;
}

/**
 * Returns the Tile that a given Corner belongs to
 */
function getTileForCorner(corner: Corner, tiles: Tiles): Tile | undefined {
  for (let tile in tiles) {
    if (tiles[tile].getCorners().indexOf(corner) !== -1) {
      return tiles[tile] as Tile;
    }
  }
}
