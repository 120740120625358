import { Board } from '../engine/board';
import { Corner } from '../engine/corner';
import { Edge } from '../engine/edge';
import { Player } from '../engine/player';
import {
  getCornerLocation,
  getCornerNeighborsWithEdge,
  getTileCornerDirFromHoldingTile,
} from '../engine/tileHelpers';

export function computeRoadLength(player: Player, gameBoard: Board): number {
  let maxLen = 0;

  const ownedCorners = getOwnedCorners(player, gameBoard);
  const visitedCorners = new Set<Corner>();

  for (const corner of ownedCorners) {
    if (!visitedCorners.has(corner)) {
      const len = getMaxLenRoadFromCorner(
        corner,
        player,
        gameBoard,
        visitedCorners
      );
      maxLen = Math.max(maxLen, len);
    }
  }

  // now, try to also start by leaf nodes (those corners that only have 1 road connected)
  // These cases can find slightly longer roads
  visitedCorners.forEach((corner) => {
    const cornerLoc = getCornerLocation(corner, gameBoard.getTiles());
    if (!cornerLoc) return;

    const isLeaf =
      getCornerNeighborsWithEdge(
        cornerLoc.tile,
        getTileCornerDirFromHoldingTile(cornerLoc.dir)!,
        gameBoard.getTiles()
      ).filter((cornerWithEdge) => cornerWithEdge.edge.isOwner(player))
        .length === 1;

    if (isLeaf) {
      const len = getMaxLenRoadFromCorner(
        corner,
        player,
        gameBoard,
        visitedCorners
      );
      maxLen = Math.max(maxLen, len);
    }
  });

  return maxLen;
}

interface TreeNode {
  corner: Corner;
  visited: Set<Edge>;
  depth: number;
}

function getMaxLenRoadFromCorner(
  corner: Corner,
  player: Player,
  board: Board,
  visitedCorners: Set<Corner>
): number {
  let maxDepth = 0;
  // generate tree

  // start by getting neighbors from this corner whose road is owned by player
  const nodes2Visit: TreeNode[] = [
    {
      corner,
      depth: 0,
      visited: new Set<Edge>(),
    },
  ];

  // now, navigate all the nodes while there are still nodes to explore
  while (nodes2Visit.length > 0) {
    // get node pending to visit
    const node = nodes2Visit.shift()!;
    visitedCorners.add(node.corner);

    if (node.corner.getOwner() !== null && !node.corner.isOwner(player)) {
      // an enemy settlement is cutting this road! We can't keep on computing further.
      continue;
    }

    const cornerLoc = getCornerLocation(node.corner, board.getTiles());
    if (!cornerLoc) {
      throw Error(`Error finding longest road. Unable to find corner location`);
    }
    getCornerNeighborsWithEdge(
      cornerLoc.tile,
      getTileCornerDirFromHoldingTile(cornerLoc.dir)!,
      board.getTiles()
    )
      .filter(
        (cornerWithEdge) =>
          cornerWithEdge.edge.isOwner(player) &&
          !node.visited.has(cornerWithEdge.edge)
      )
      .map((cornerWithEdge) => {
        return {
          corner: cornerWithEdge.corner,
          depth: node.depth + 1,
          visited: new Set<Edge>(node.visited).add(cornerWithEdge.edge),
        };
      })
      // eslint-disable-next-line no-loop-func
      .forEach((node) => {
        maxDepth = Math.max(maxDepth, node.depth);
        nodes2Visit.push(node);
      });
  }

  return maxDepth;
}

export function getOwnedCorners(player: Player, gameBoard: Board): Corner[] {
  const result: Corner[] = [];
  const tiles = gameBoard.getTiles();

  Object.keys(tiles).forEach((k) => {
    const tile = tiles[k];
    tile
      .getCorners()
      .filter((corner) => corner.isOwner(player))
      .forEach((corner) => {
        result.push(corner);
      });
  });

  return result;
}

export function getOwnedRoads(player: Player, gameBoard: Board): Edge[] {
  const result: Edge[] = [];
  const ownedRoads = new Set<Edge>();
  const tiles = gameBoard.getTiles();

  Object.keys(tiles).forEach((k) => {
    const tile = tiles[k];
    tile
      .getEdges()
      .filter((edge) => edge.isOwner(player) && !ownedRoads.has(edge))
      .forEach((edge) => {
        ownedRoads.add(edge);
      });
  });

  ownedRoads.forEach((edge) => result.push(edge));

  return result;
}
