import { useState } from 'react';
import styled, { css } from 'styled-components';
import { InHandDevCard, Player } from '../../engine/player';
import { PlayerColor, Resource, ResourceCount } from '../../engine/types';
import { useDirectorContext, useGameContext } from '../../game/GameProvider';
import { PlayerUI } from '../player/CatanPlayer';
import { PlayerSummary } from './PlayerSummary';
import { useRemoteContext } from '../../game/RemoteProvider';

interface PositionedProps {
  index: number;
  available: boolean;
}
const Positioned = styled.div<PositionedProps>`
  position: fixed;

  ${({ available }) => {
    if (!available) {
      return css`
        filter: grayscale(1);
      `;
    }
  }}

  ${({ index }) => {
    switch (index) {
      case 0:
        return css`
          top: 12px;
          left: 12px;
        `;
      case 1:
        return css`
          top: 12px;
          right: 12px;
        `;
      case 2:
        return css`
          bottom: 12px;
          left: 12px;
        `;
      case 3:
        return css`
          bottom: 12px;
          right: 12px;
        `;
    }
  }}
`;

const PlayerWrapper = styled.div`
  height: 600px;
  width: 340px;
`;

const PlayerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlayerBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  background-color: #fff4;
`;

export const PlayerSummaryList = () => {
  const director = useDirectorContext();
  const { players, currentPlayer, diceNumber, phase, trade, turn } =
    useGameContext();
  const { players: remotePlayers } = useRemoteContext();
  const [viewPlayer, setViewPlayer] = useState<Player | null>();

  if (!director) {
    return null;
  }

  return (
    <>
      {players.map((player, index) => (
        <Positioned
          index={index}
          key={player.getName()}
          available={!!remotePlayers.find((rp) => rp.id === player.getId())}
        >
          <PlayerSummary
            player={player}
            onClick={() => director.handlePlayerClick(player)}
            onDiceClick={() => director.throwDices()}
            onDoubleClick={() => setViewPlayer(player)}
            current={player === currentPlayer}
            diceNumber={diceNumber}
            phase={phase}
            horInvert={!!(index % 2)}
            vertInvert={index > 1}
          />
        </Positioned>
      ))}

      {viewPlayer && (
        <PlayerOverlay>
          <PlayerBackdrop onClick={() => setViewPlayer(null)} />
          <PlayerWrapper>
            <PlayerUI
              player={viewPlayer}
              trade={trade}
              playing={currentPlayer === viewPlayer}
              turn={turn}
              phase={phase}
              onPlayCard={(card: InHandDevCard) =>
                director.handlePlayDevelopmentCard(viewPlayer.getId(), card)
              }
              onSelectResources={(resources: ResourceCount) =>
                director.handleResourcesSelected(viewPlayer.getId(), resources)
              }
              onThrowDices={() => director.throwDices()}
              onTrade={() => director.startTrade()}
              onPass={() => {
                director.nextPlayer();
                setViewPlayer(null);
              }}
              onConfirmTrade={(fromPlayer?: PlayerColor) =>
                director.acceptTradeOffer(viewPlayer.getId(), fromPlayer)
              }
              onCancelTrade={() => director.finishTrade()}
              onTradeOfferChange={(offer) =>
                director.offerTrade(viewPlayer.getId(), offer)
              }
              onDiscard={(resources: ResourceCount) =>
                director.handleDiscard(viewPlayer.getId(), resources)
              }
              onBankTrade={(from: Resource, to: Resource) =>
                director.handleBankTrade(viewPlayer.getId(), from, to)
              }
            />
          </PlayerWrapper>
        </PlayerOverlay>
      )}
    </>
  );
};

PlayerSummaryList.displayName = 'PlayerSummaryList';
