import styled, { css } from 'styled-components';
import { PlayerColor, PlayerColors } from '../engine/types';
import { RemotePlayer } from '../game/RemoteState';
import { useEffect, useState } from 'react';

interface Props {
  color: PlayerColor;
  onChange: (color: PlayerColor) => void;
  currentPlayers: RemotePlayer[];
}

const Selector = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px 0;
  justify-content: space-between;
`;

const HiddenRadio = styled.input`
  display: none;
`;

const ColorRadio = styled.label<{
  color: string;
  selected: boolean;
  disabled: boolean;
}>`
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  ${({ selected, color }) =>
    selected &&
    css`
      box-shadow: 0 0 0 2px white, 0 0 0 4px ${color};
    `}
`;

export const PlayerColorSelector = ({
  color,
  currentPlayers,
  onChange,
}: Props) => {
  const [selected, setSelected] = useState<PlayerColor>();

  useEffect(() => {
    setSelected(color);
  }, [color]);

  const handleColorChange = (color: PlayerColor) => {
    setSelected(color);
    onChange(color);
  };

  return (
    <Selector>
      {PlayerColors.map((c) => (
        <label
          key={`player-color-selector-${c.name}`}
          aria-label={`player-color-selector-${c.name}`}
        >
          <HiddenRadio
            type='radio'
            id={c.name}
            name='player_color_selector'
            value={c.code}
            onChange={() => handleColorChange(c.code)}
            disabled={
              !!currentPlayers.find((player) => player.color === c.code)
            }
          />

          <ColorRadio
            color={c.code}
            selected={selected === c.code}
            htmlFor={c.name}
            disabled={
              selected !== c.code &&
              !!currentPlayers.find((player) => player.color === c.code)
            }
          />
        </label>
      ))}
    </Selector>
  );
};

PlayerColorSelector.displayName = 'PlayerColorSelector';
