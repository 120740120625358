import {
  GameState,
  getEmptyGameState,
  TradeOffer,
  TradeState,
} from './GameState';
import { BasicGameConfig } from './config';
import { InHandDevCard, Player } from '../engine/player';
import { Corner, Corner as CornerData } from '../engine/corner';
import { Edge as EdgeData } from '../engine/edge';
import { BaseTile, Tile, TileType } from '../engine/tile';
import { Board } from '../engine/board';
import {
  getCorners,
  getCornerTiles,
  getTileCornerDirFromHoldingTile,
  getTileEdgeDirFromHoldingTile,
  TileCorner,
  TileEdge,
} from '../engine/tileHelpers';
import {
  findPlayer,
  getPlayersWithResourceSurplus,
  pickFirstPlayer,
  pickNextPlayer,
  pickPlayerRandomResource,
  pickRandomDevelopmentCard,
  resourcesToText,
  throwDice,
  updateVictoryPoints,
} from './helpers';
import {
  DevelopmentCard,
  PlayerColor,
  Resource,
  ResourceCount,
  ResourceEmoji,
  ResourceNames,
} from '../engine/types';
import { PlayerId } from './RemoteState';
import { numResourcesNeededForBankTrade } from '../view/player/trade/helpers';

/**
 * Game engine. State machine thru the different states of the game.
 *
 * This instance will take care of the following work:
 * - Initialize the game (from config)
 * - Orchestrate turns
 * - Drive each phase within each turn
 *
 * It's basically the brain of the game, enabling/disabling UI depending
 * the moment of the game we're in.
 * It'll init an update an instance of GameState, which will be fed to
 * Game.tsx to provide context to the entire game.
 */

type StateChangeCb = (newState: GameState, board: Board) => void;
type SendLogCb = (message: string, color?: PlayerColor) => void;

export class GameDirector {
  private gameState: GameState;
  private stateChangeCb: StateChangeCb = () => {};
  private sendLogCb: SendLogCb = () => {};
  // This will be initialized after "creating" players
  private gameConfig: BasicGameConfig;
  private gameBoard: Board;

  /**
   * Constructor
   * @param stateChangeCallback Function given to GameDirector instance to
   *        propagate changes in the state of the game. Used by Game.tsx to
   *        update React Context and keep UI in sync.
   */
  constructor(
    onStateChange: StateChangeCb,
    onLogSent: SendLogCb,
    initState?: { gameState: GameState; board: Board }
  ) {
    this.gameConfig = new BasicGameConfig();
    if (initState) {
      this.gameState = initState.gameState;
      this.gameBoard = initState.board;
    } else {
      this.gameState = getEmptyGameState();
      this.gameBoard = new Board(2, this.getConfig());
    }
    this.stateChangeCb = onStateChange;
    this.sendLogCb = onLogSent;
  }

  restore(state: { gameState: GameState; board: Board }) {
    this.gameState = state.gameState;
    this.gameBoard = state.board;
  }

  getConfig() {
    return this.gameConfig;
  }

  getBoard() {
    return this.gameBoard;
  }

  reset(onStateChange: StateChangeCb, onLogSent: SendLogCb) {
    this.stateChangeCb = onStateChange;
    this.sendLogCb = onLogSent;
  }

  /**
   * Call this method every time we make changes to the state.
   * To ensure we always propagate it.
   */
  updateGameState(changes: Partial<GameState>) {
    this.gameState = { ...this.gameState, ...changes };
    console.log('Game State Changed:', changes, this.gameState);
    this.stateChangeCb(this.gameState, this.gameBoard);
  }

  setPlayers(players: Player[]) {
    if (players.length > this.gameConfig.maxPlayers) {
      throw new Error('[Game Director] Too many players!');
    }
    this.updateGameState({ players });
  }

  // Method to provide player when starting a new game
  addPlayer(player: Player) {
    if (this.gameState.players.length >= this.gameConfig.maxPlayers) {
      throw new Error('[Game Director] Reached max number of players.');
    }
    this.updateGameState({
      players: [...this.gameState.players, player],
    });
  }

  startGame() {
    const numPlayers = this.gameState.players.length;
    if (numPlayers <= 1) {
      throw new Error(
        `[Game Director] Need more players to start a game. Currently: ${numPlayers}`
      );
    }

    this.log('Welcome to Catan!');

    // Randomly select who starts
    const firstPlayer = pickFirstPlayer(this.gameState.players);

    this.logPlayer('Build your first settlement', firstPlayer);

    this.updateGameState({
      currentPlayer: firstPlayer,
      startingPlayer: firstPlayer,
      phase: 'initialSetup',
      remainingDevCards: { ...this.getConfig().developmentCards },
    });
  }

  nextPlayer() {
    const { players, phase, currentPlayer, turn, startingPlayer } =
      this.gameState;

    if (!players.length || !phase) {
      throw new Error(
        `[Game Director] Start the game before being able to change player.`
      );
    }

    const inverse = phase === 'initialSetup_secondBuild';
    const nextPlayer = pickNextPlayer(players, currentPlayer, inverse);
    const isNextTurn =
      ['initialSetup', 'initialSetup_secondBuild'].indexOf(phase) === -1 &&
      nextPlayer === startingPlayer;

    // Recompute scoreboard and assign to players
    updateVictoryPoints(players, startingPlayer!, this.gameBoard);

    if (phase === 'initialSetup') {
      this.logPlayer('Build your first settlement', nextPlayer);
    } else if (phase === 'initialSetup_secondBuild') {
      this.logPlayer('Build your second settlement', nextPlayer);
    } else {
      this.logPlayer("It's your turn!", nextPlayer);
    }

    this.updateGameState({
      currentPlayer: nextPlayer,
      // if next player is requested during construction phase, move into dice phase
      phase: phase === 'construction' ? 'dice' : phase,
      turn: turn + (isNextTurn ? 1 : 0),
      diceNumber: 0,
    });
  }

  handleCornerClick = (corner: CornerData, tile: BaseTile, dir: TileCorner) => {
    const { currentPlayer, phase, players, startingPlayer } = this.gameState;
    if (!currentPlayer) {
      const error = '💀 [Game Director] No player selected to build.';
      this.log(error);
      throw new Error(error);
    }

    const numSetts = currentPlayer.getNumSettlements();
    switch (phase) {
      case 'initialSetup':
        // - do NOT allow to create any more settlements for this player :)
        if (numSetts !== 0) {
          this.logPlayer(
            `🚫 Can't build more settlements in this round. Round: ${phase}. #settlements: ${numSetts}`
          );
          return;
        }
        // - create a settlement but do NOT take resources from building in this phase
        this.buildSettlementForPlayer(
          currentPlayer,
          tile,
          dir,
          true /* free */
        );
        break;

      case 'initialSetup_secondBuild':
        // - check only 1 settlement exists so far
        if (numSetts !== 1) {
          this.logPlayer(
            `🚫 Can't build more settlements in this round. Round: ${phase}. #settlements: ${numSetts}`
          );
          return;
        }
        // - create a settlement but do NOT take resources from building in this phase
        this.buildSettlementForPlayer(
          currentPlayer,
          tile,
          dir,
          true /* free */
        );
        // - give resources to player
        this.giveInitialResources(currentPlayer, corner);
        break;

      case 'construction':
        if (corner.getOwner() === currentPlayer && !corner.hasCity()) {
          this.buildCityForPlayer(currentPlayer, tile, dir);
        } else {
          this.buildSettlementForPlayer(currentPlayer, tile, dir);
        }
        break;

      case 'dice':
      case 'discard':
      case 'trade':
      case 'robber':
      case 'robbing':
        this.robFromPlayer(corner.getOwner()!, currentPlayer);
        break;
      case 'devCard_road1':
      case 'devCard_road2':
      case 'devCard_2resources':
      case 'devCard_monopoly':
      case null:
        // DO NOTHING
        break;

      default:
        enforceExhaustive(phase);
    }

    // Recompute scoreboard and assign to players
    updateVictoryPoints(players, startingPlayer!, this.gameBoard);
    // force game state update
    this.updateGameState({});
  };

  handleEdgeClick = (edge: EdgeData, tile: BaseTile, dir: TileEdge) => {
    const { currentPlayer, phase, startingPlayer, players } = this.gameState;
    if (!currentPlayer) {
      throw new Error(`[Game Director] No player selected to build.`);
    }

    switch (phase) {
      case 'initialSetup':
        if (currentPlayer.getNumRoad() !== 0) {
          throw new Error(
            `Player ${currentPlayer.getName()} can't build more roads in this round. Round: ${phase}. #roads: ${currentPlayer.getNumRoad()}`
          );
        }
        // [] TODO: Verify the road to create can only stem from settlement created in this round

        // - do NOT take resources from building in this phase
        this.buildRoadForPlayer(currentPlayer, tile, dir, true /* free */);
        // - if we're done with all the players in this round...
        if (pickNextPlayer(players, currentPlayer) === startingPlayer) {
          // ...move to next round. Player will be the same one again, as second build is performed backwards
          this.logPlayer('Build your second settlement');
          this.updateGameState({ phase: 'initialSetup_secondBuild' });
        } else {
          // ...pass turn to next player
          this.nextPlayer();
        }
        break;

      case 'initialSetup_secondBuild':
        if (currentPlayer.getNumRoad() !== 1) {
          throw new Error(
            `Player ${currentPlayer.getName()} can't build more roads in this round. Round: ${phase}. #roads: ${currentPlayer.getNumRoad()}`
          );
        }
        // [] TODO: Verify the road to create can only stem from settlement created in this round

        // - do NOT take resources from building in this phase
        this.buildRoadForPlayer(currentPlayer, tile, dir, true /* free */);
        // - if we're done with all the players in this round...
        if (currentPlayer === startingPlayer) {
          // finish the setup rounds, and start by starting player
          updateVictoryPoints(players, startingPlayer, this.gameBoard);
          this.updateGameState({
            phase: 'dice',
            currentPlayer: startingPlayer,
          });
        } else {
          this.nextPlayer();
        }
        break;
      case 'construction':
        this.buildRoadForPlayer(currentPlayer, tile, dir);
        break;

      case 'devCard_road1':
        this.buildRoadForPlayer(currentPlayer, tile, dir, true /* free */);
        this.updateGameState({ phase: 'devCard_road2' });
        break;

      case 'devCard_road2':
        this.buildRoadForPlayer(currentPlayer, tile, dir, true /* free */);
        this.updateGameState({ phase: 'construction' });
        break;

      case 'dice':
      case 'discard':
      case 'trade':
      case 'robber':
      case 'robbing':
      case 'devCard_2resources':
      case 'devCard_monopoly':
      case null:
        // DO NOTHING
        break;

      default:
        enforceExhaustive(phase);
    }

    // Recompute scoreboard and assign to players
    updateVictoryPoints(players, startingPlayer!, this.gameBoard);
    // force game state update
    this.updateGameState({});
  };

  handleTileClick = (tile: Tile) => {
    const { currentPlayer, phase } = this.gameState;
    if (!currentPlayer) {
      throw new Error(`[Game Director] No player playing.`);
    }

    switch (phase) {
      case 'robber':
        if (tile.hasRobber()) {
          throw new Error(
            `[Game Director] Can't move the robber to the same tile it was, already.`
          );
        }

        // Move the robber to a different tile
        this.gameBoard.moveRobber(tile.tileId);

        // Find if there's anyone to rob in that tile (and has any card to rob)
        const corners = getCorners(
          this.gameBoard.getRobberTile(),
          this.gameBoard.getTiles()
        );
        const canRobAnyone = corners.filter((corner) => {
          const owner = corner.getOwner();
          return (
            !!owner && owner !== currentPlayer && owner.getNumResources() > 0
          );
        });

        this.updateGameState({
          phase: canRobAnyone.length ? 'robbing' : 'construction',
        });
        break;

      case 'initialSetup':
      case 'initialSetup_secondBuild':
      case 'construction':
      case 'dice':
      case 'discard':
      case 'trade':
      case 'robbing':
      case 'devCard_road1':
      case 'devCard_road2':
      case 'devCard_2resources':
      case 'devCard_monopoly':
      case null:
        // DO NOTHING
        break;

      default:
        enforceExhaustive(phase);
    }
  };

  handlePlayerClick = (player: Player) => {
    const { currentPlayer, phase } = this.gameState;
    if (!currentPlayer) {
      throw new Error(`[Game Director] No player playing.`);
    }

    switch (phase) {
      case 'robbing':
        this.robFromPlayer(player, currentPlayer);
        break;
      case 'initialSetup':
      case 'initialSetup_secondBuild':
      case 'construction':
      case 'dice':
      case 'discard':
      case 'trade':
      case 'robber':
      case 'devCard_road1':
      case 'devCard_road2':
      case 'devCard_2resources':
      case 'devCard_monopoly':
      case null:
        // DO NOTHING
        break;

      default:
        enforceExhaustive(phase);
    }
  };

  handleDiscard = (playerId: PlayerId, resources: ResourceCount) => {
    const { phase, players } = this.gameState;
    const player = findPlayer(players, playerId);

    if (phase !== 'discard') {
      throw new Error(
        `[Game Director] Player (${player.getName()}) should not discard out of 'discard' phase. Current phase: '${phase}'.`
      );
    }

    player.removeResources(resources);
    player.setDiscardedInCurrentTurn(true);
    this.updateGameState({ players });

    this.logPlayer(`Discarded ${resourcesToText(resources)}`, player);

    this.moveToDiscardOrRobberPhase();
  };

  handleDevCardsDeckClick = () => {
    const { phase, currentPlayer } = this.gameState;

    if (!currentPlayer) {
      throw new Error(`[Game Director] No player selected to buy dev card.`);
    }

    switch (phase) {
      case 'construction':
        this.buyDevelopmentCard(currentPlayer);
        break;
      case 'initialSetup':
      case 'initialSetup_secondBuild':
      case 'dice':
      case 'discard':
      case 'trade':
      case 'robber':
      case 'robbing':
      case 'devCard_road1':
      case 'devCard_road2':
      case 'devCard_2resources':
      case 'devCard_monopoly':
      case null:
        throw new Error(
          `[Game Director] Cannot get new development cards out of 'construction' phase. Current phase: '${phase}'.`
        );
      default:
        enforceExhaustive(phase);
    }
  };

  handlePlayDevelopmentCard(playerId: PlayerId, cardToPlay: InHandDevCard) {
    const { currentPlayer, turn, players } = this.gameState;
    const player = findPlayer(players, playerId);

    if (!currentPlayer) {
      throw new Error(`[Game Director] No player selected to play a dev card.`);
    }

    if (player !== currentPlayer) {
      throw new Error(
        `[Game Director] Only current player can play a development card.`
      );
    }

    // First, player will play the card
    currentPlayer.playDevelopmentCard(cardToPlay, turn);

    // All the logic to play dev cards here (maybe move it somewhere else?)
    switch (cardToPlay.card) {
      case DevelopmentCard.Knight:
        // Playing a knight essentially triggers the same flow as getting a 7, without the 'discard' phase
        this.updateGameState({ phase: 'robber' });
        break;
      case DevelopmentCard.Monopoly:
        this.updateGameState({ phase: 'devCard_monopoly' });
        break;
      case DevelopmentCard.TwoResources:
        this.updateGameState({ phase: 'devCard_2resources' });
        break;
      case DevelopmentCard.TwoRoads:
        this.updateGameState({ phase: 'devCard_road1' });
        break;
      case DevelopmentCard.VictoryPoint:
        // Not needed to be played. Does nothing else.
        break;
      default:
        enforceExhaustive(cardToPlay.card);
    }
  }

  handlePlayerCornerClick = (
    playerId: string,
    corner: CornerData,
    tile: BaseTile,
    dir: TileCorner
  ) => {
    const { currentPlayer } = this.gameState;
    if (!currentPlayer || currentPlayer.getId() !== playerId) {
      const error = `[Game Director] Player ${playerId} is not on their turn to click a corner.`;
      this.log(error);
    } else {
      this.handleCornerClick(corner, tile, dir);
    }
  };

  handlePlayerEdgeClick = (
    playerId: string,
    edge: EdgeData,
    tile: BaseTile,
    dir: TileEdge
  ) => {
    const { currentPlayer } = this.gameState;
    if (!currentPlayer || currentPlayer.getId() !== playerId) {
      const error = `[Game Director] Player ${playerId} is not on their turn to click an edge.`;
      this.log(error);
    } else {
      this.handleEdgeClick(edge, tile, dir);
    }
  };

  handlePlayerTileClick = (playerId: string, tile: Tile) => {
    const { currentPlayer } = this.gameState;
    if (!currentPlayer || currentPlayer.getId() !== playerId) {
      const error = `[Game Director] Player ${playerId} is not on their turn to click a tile.`;
      this.log(error);
    } else {
      this.handleTileClick(tile);
    }
  };

  handlePlayerDevCardsDeckClick = (playerId: string) => {
    const { currentPlayer } = this.gameState;
    if (!currentPlayer || currentPlayer.getId() !== playerId) {
      const error = `[Game Director] Player ${playerId} is not on their turn to click a tile.`;
      this.log(error);
    } else {
      this.handleDevCardsDeckClick();
    }
  };

  // Build a settlement if we have enough resources (unless we do it for free for some reason, like initial setup)
  private buildSettlementForPlayer(
    player: Player,
    tile: BaseTile,
    dir: TileCorner,
    free = false
  ) {
    if (!free) {
      const settlementCost = this.gameConfig.constructionCosts['settlement'];
      if (!player.hasEnoughResources(settlementCost)) {
        return this.logPlayerAndThrow(
          '💸 Not enough resources for a settlement',
          player
        );
      }

      player.removeResources(settlementCost);
    }
    const tileCornerDir = getTileCornerDirFromHoldingTile(dir);
    // We only build settlements for free on game startup, so we can use the same flag here.
    const corner = this.gameBoard.placeSettlement(
      tile.tileId,
      tileCornerDir!,
      player,
      free
    );
    player.addSettlement();
    const port = corner?.getPort();
    if (port) {
      player.addPort(port);
    }

    this.logPlayer('⛺️ built a settlement', player);
  }

  // Build a road if we have enough resources (unless we do it for free for some reason, like initial setup)
  private buildRoadForPlayer(
    player: Player,
    tile: BaseTile,
    dir: TileEdge,
    free = false
  ) {
    if (!free) {
      const roadCost = this.gameConfig.constructionCosts['road'];
      if (!player.hasEnoughResources(roadCost)) {
        return this.logPlayerAndThrow(
          '💸 Not enough resources for a road',
          player
        );
      }

      player.removeResources(roadCost);
    }
    const tileEdgeDir = getTileEdgeDirFromHoldingTile(dir);
    this.gameBoard.placeRoad(tile.tileId, tileEdgeDir!, player);
    player.addRoad();
    this.logPlayer('🛣️ built a road', player);
  }

  private buildCityForPlayer(player: Player, tile: BaseTile, dir: TileCorner) {
    const cityCost = this.gameConfig.constructionCosts['city'];
    if (!player.hasEnoughResources(cityCost)) {
      throw new Error(
        `Not enough resources for player ${player.getName()} to build a city`
      );
    }

    player.removeResources(cityCost);
    const tileCornerDir = getTileCornerDirFromHoldingTile(dir);
    this.gameBoard.placeCity(tile.tileId, tileCornerDir!, player);
    player.upgradeToCity();
  }

  private buyDevelopmentCard(player: Player) {
    const devCardCost = this.gameConfig.constructionCosts['devCard'];
    if (!player.hasEnoughResources(devCardCost)) {
      this.logPlayer('💔 Not enough resources to get a dev card');
      return;
    }

    const card = pickRandomDevelopmentCard(this.gameState.remainingDevCards);
    if (!card) {
      this.logPlayer('⚠️ There are no development cards left!');
      return;
    }

    player.removeResources(devCardCost);
    player.giveDevelopmentCard(card, this.gameState.turn);
    const remainingDevCards = { ...this.gameState.remainingDevCards };
    remainingDevCards[card]--;
    this.updateGameState({ remainingDevCards });
  }

  // Assign resources from the second build phase to the user
  private giveInitialResources(player: Player, corner: Corner): void {
    const cornerTiles = getCornerTiles(corner, this.gameBoard.getTiles());
    const resources: Partial<ResourceCount> = {};
    cornerTiles
      .filter((tile) => tile.getTileType() === TileType.TILE)
      .forEach((tile) => {
        const resource = tile.getResource();
        if (resource) {
          resources[resource] = (resources[resource] || 0) + 1;
        }
      });
    player.addResources(resources);

    this.logPlayer(`Got ${resourcesToText(resources)}`, player);
  }

  private robFromPlayer = (player: Player, currentPlayer: Player) => {
    const { diceNumber } = this.gameState;

    if (player === currentPlayer) {
      throw new Error(`[Game Director] Can't rob yourself!`);
    }

    const corners = getCorners(
      this.gameBoard.getRobberTile(),
      this.gameBoard.getTiles()
    );
    const canRobThisPlayer = !!corners.find((corner) => corner.isOwner(player));
    if (!canRobThisPlayer) {
      throw new Error(
        `[Game Director] Can't rob this player, because it's not present in robber's tile.`
      );
    }

    const resource = pickPlayerRandomResource(player);

    if (!resource) {
      throw new Error(
        `[Game Director] ${player.getName()} has no resources. Choose a different one.`
      );
    }

    this.logPlayer(
      `Stole 1 ${ResourceNames[resource]} from ${player.getName()}`,
      currentPlayer
    );
    player.removeResources({ [resource]: 1 });
    currentPlayer.addResources({ [resource]: 1 });
    // Note: Knights can be played **before** throwing dices. If that were the case,
    //      the player's dice number would still be 0 and it'll have to throw, not construct.
    this.updateGameState({ phase: diceNumber ? 'construction' : 'dice' });
  };

  /**
   * Dice throwing and resource distribution
   */
  throwDices = () => {
    if (this.gameState.phase !== 'dice') {
      throw new Error(
        `This is not the phase to throw dices. Current phase: ${this.gameState.phase}`
      );
    }

    // Throw the dices (2 random numbers from 1 to 6)
    const dice1 = throwDice();
    const dice2 = throwDice();
    const diceNumber = dice1 + dice2;
    this.log(`🎲 Got number ${diceNumber}`);

    // With 7, go thru 'discard' and 'robber' phases
    if (diceNumber === 7) {
      this.moveToDiscardOrRobberPhase();
      return;
    }

    // Distribute resources among players
    this.distributeResources(diceNumber);

    // Move to next phase (construction) -- trade is not a phase, it's a subsystem that can be initiated.
    this.updateGameState({ diceNumber, phase: 'construction' });
  };

  /* Response from the Resource Selector. It's use will depend on the situation. */
  handleResourcesSelected = (playerId: PlayerId, resources: ResourceCount) => {
    const { phase, currentPlayer, players } = this.gameState;
    const player = findPlayer(players, playerId);

    if (!currentPlayer) {
      throw new Error(
        `[Game Director] No player selected for resource selection.`
      );
    }

    if (player !== currentPlayer) {
      throw new Error(
        `[Game Director] Only current player can play a development card.`
      );
    }

    switch (phase) {
      case 'devCard_2resources':
        // assign resources to player and go back to construction phase
        this.logPlayer(`Got 2 resources: ${JSON.stringify(resources)}`);

        currentPlayer.addResources(resources);
        this.updateGameState({ phase: 'construction' });
        break;

      case 'devCard_monopoly':
        // Accrue all the resources from all the players of the given type, and give them to currentUser
        const resourceType = +Object.keys(resources).find(
          (k) => resources[+k] !== 0
        )! as Resource;
        const numResources = players
          .filter((player) => player !== currentPlayer)
          .reduce<number>(
            (total, player) =>
              (total += player.removeAllResourcesOfType(resourceType)),
            0
          );
        currentPlayer.addResources({ [resourceType]: numResources });
        this.updateGameState({ phase: 'construction' });
        break;
      case 'construction':
      case 'initialSetup':
      case 'initialSetup_secondBuild':
      case 'dice':
      case 'discard':
      case 'trade':
      case 'robber':
      case 'robbing':
      case 'devCard_road1':
      case 'devCard_road2':
      case null:
        // DO NOTHING
        break;
      default:
        enforceExhaustive(phase);
    }
  };

  private moveToDiscardOrRobberPhase() {
    const { players } = this.gameState;
    const playersPendingToDiscard = getPlayersWithResourceSurplus(players);

    if (playersPendingToDiscard.length) {
      // Move into 'discard' phase, for players with too many cards to discard them
      this.updateGameState({ diceNumber: 7, phase: 'discard' });
      playersPendingToDiscard.forEach((player) => {
        const discard = Math.floor(player.getNumResources() / 2);
        this.logPlayer(
          `Has too many resources! Discard ${discard} of them.`,
          player
        );
      });
    } else {
      // reset all players' need to discard, for next round to be ready
      players.forEach((player) => {
        player.setDiscardedInCurrentTurn(false);
      });
      // Move directly into robber phase
      this.updateGameState({ diceNumber: 7, phase: 'robber', players });
    }
  }

  /**
   * Start a trade with another player.
   */
  startTrade() {
    this.updateGameState({ phase: 'trade' });
  }

  finishTrade() {
    this.updateGameState({ phase: 'construction', trade: [] });
  }

  offerTrade(playerId: PlayerId, offer: ResourceCount) {
    if (this.gameState.phase !== 'trade') {
      throw new Error(
        `[Game Director] Trade is only allowed in trade phase. Current phase: ${this.gameState.phase}.`
      );
    }

    const { players } = this.gameState;
    const player = findPlayer(players, playerId);
    if (!player.hasEnoughResources(offer)) {
      throw new Error(
        `[Game Director] Player ${player.getName()} doesn't have enough resources to make that trade offer!`
      );
    }

    const trade: TradeState = [];
    const newOffer: TradeOffer = {
      color: player.getColor(),
      name: player.getName(),
      offer,
      playing: this.gameState.currentPlayer?.getId() === player.getId(),
    };

    let found = false;
    this.gameState.trade.forEach((tradeOffer) => {
      if (tradeOffer.color === player.getColor()) {
        trade.push(newOffer);
        found = true;
      } else {
        trade.push(tradeOffer);
      }
    });

    if (!found) {
      trade.push(newOffer);
    }

    this.updateGameState({ trade });
  }

  retireOffer(playerId: PlayerId) {
    if (this.gameState.phase !== 'trade') {
      throw new Error(
        `[Game Director] Trade is only allowed in trade phase. Current phase: ${this.gameState.phase}.`
      );
    }

    const { players } = this.gameState;
    const player = findPlayer(players, playerId);

    const trade: TradeState = [];
    this.gameState.trade.forEach((tradeOffer) => {
      if (tradeOffer.color !== player.getColor()) {
        trade.push(tradeOffer);
      }
    });

    this.updateGameState({ trade });
  }

  acceptTradeOffer(playerId: PlayerId, fromPlayer?: PlayerColor) {
    const { phase, currentPlayer, trade, players } = this.gameState;

    if (phase !== 'trade') {
      throw new Error(
        `[Game Director] Trade is only allowed in trade phase. Current phase: ${phase}.`
      );
    }

    if (!currentPlayer) {
      throw new Error('[Game Director] No current player to accept a trade.');
    }

    const acceptingPlayer = findPlayer(players, playerId);

    // If accepting player is the current player, mark the trade as accepted
    if (acceptingPlayer === currentPlayer) {
      const acceptedPlayer = players.find(
        (player) => player.getColor() === fromPlayer
      );

      if (!acceptedPlayer) {
        throw new Error(
          `[Game Director] Unable to find player to trade with color: ${fromPlayer}.`
        );
      }

      const trade: TradeState = [];
      this.gameState.trade.forEach((tradeOffer) => {
        if (tradeOffer.color === fromPlayer) {
          trade.push({ ...tradeOffer, accepted: true });
        } else {
          trade.push({ ...tradeOffer, accepted: false });
        }
      });

      this.updateGameState({ trade });
    } else {
      // If accepting player is not the current player, that means it's trying to confirm the trade
      // look for the trade offer from accepting player and mark it as accepted
      const acceptedOffer = trade.find(
        (t) => t.color === acceptingPlayer.getColor() && !!t.accepted
      );
      if (!acceptedOffer) {
        throw new Error(
          `[Game Director] ${acceptingPlayer.getName()} cannot close the deal because their offer is not accepted.`
        );
      }

      const currentPlayerOffer = trade.find(
        (t) => t.color === currentPlayer.getColor()
      );
      if (!currentPlayerOffer) {
        throw new Error(
          `[Game Director] ${currentPlayer.getName()} didn't offer any resources.`
        );
      }

      // transfer resources
      // acceptingPlayer -> currentPlayer
      acceptingPlayer.removeResources(acceptedOffer.offer);
      currentPlayer.addResources(acceptedOffer.offer);
      // currentPlayer -> acceptingPlayer
      currentPlayer.removeResources(currentPlayerOffer.offer);
      acceptingPlayer.addResources(currentPlayerOffer.offer);

      // finish trade
      this.finishTrade();
    }
  }

  handleBankTrade(playerId: PlayerId, from: Resource, to: Resource) {
    const { phase, currentPlayer } = this.gameState;

    if (phase !== 'trade') {
      throw new Error(
        `[Game Director] Trade is only allowed in trade phase. Current phase: ${phase}.`
      );
    }

    if (currentPlayer?.getId() !== playerId) {
      throw new Error(
        `[Game Director] Only current player can trade with the bank.`
      );
    }

    const { players } = this.gameState;
    const player = findPlayer(players, playerId);

    const numResNeeded = numResourcesNeededForBankTrade(from, player);
    if (player.getResources()[from] < numResNeeded) {
      throw new Error(
        `[Game Director] Player ${player.getName()} doesn't have enough resources to make that trade offer!`
      );
    }

    player.removeResources({ [from]: numResNeeded });
    player.addResources({ [to]: 1 });

    this.updateGameState({ players });
    this.logPlayer(
      `Traded with bank: ${ResourceEmoji[from]} → ${ResourceEmoji[to]}`
    );
  }

  private distributeResources(diceNumber: number) {
    const rewards: { [playerId: string]: ResourceCount } = {};
    const tiles = this.gameBoard.getTiles();
    Object.keys(tiles)
      .map((tileId) => tiles[tileId] as Tile)
      .filter((tile) => {
        // Ignore offset tiles or those that didn't match the dice (or have no resource)
        return (
          tile.getTileType() === TileType.TILE &&
          tile.didMatchDice(diceNumber) &&
          !!tile.getResource()
        );
      })
      .forEach((tile) => {
        getCorners(tile, tiles)
          .filter((corner) => !!corner.getOwner())
          .forEach((corner) => {
            const owner = corner.getOwner()!;
            const playerId = owner.getId();
            if (!rewards[playerId]) {
              rewards[playerId] = {};
            }
            const res = tile.getResource()!;
            rewards[playerId][res] =
              (rewards[playerId][res] || 0) + corner.getReward();
          });
      });

    // Give resources to each player
    Object.keys(rewards).forEach((playerId) => {
      const player = findPlayer(this.gameState.players, playerId as PlayerId);
      player?.addResources(rewards[playerId]);
      this.logPlayer(`Got ${resourcesToText(rewards[playerId])}`, player);
    });
  }

  // System to expose messages that should help following the game :)
  // Logs will be accessible thru the LogViewer.
  log(message: string, color?: PlayerColor) {
    this.sendLogCb(message, color);
  }

  // If not given, it'll use the current player :)
  logPlayer(message: string, player?: Player): string {
    const ply = player || this.gameState.currentPlayer;
    const msg = `${ply?.getName()}: ${message}`;
    this.log(msg, ply?.getColor());
    return msg;
  }

  logPlayerAndThrow(message: string, player?: Player) {
    const finalMessage = this.logPlayer(message, player);
    throw new Error(finalMessage);
  }
}

// ~~~ GameDirector Singleton ~~~

let gameDirectorSingleton: GameDirector;

export const getOrCreateGameDirector = (
  onStateChange: StateChangeCb,
  onLogSent: SendLogCb
) => {
  if (!gameDirectorSingleton) {
    gameDirectorSingleton = new GameDirector(onStateChange, onLogSent);
  } else {
    gameDirectorSingleton.reset(onStateChange, onLogSent);
  }
  return gameDirectorSingleton;
};
