export function setQueryParam(key: string, value: string) {
  const newUrl = new URL(window.location.href);
  newUrl.searchParams.set(key, value);
  window.history.replaceState({}, '', newUrl);
}

export function setQueryParams(list: { key: string; value: string }[]) {
  const newUrl = new URL(window.location.href);
  list.forEach((pair) => {
    newUrl.searchParams.set(pair.key, pair.value);
  });
  window.history.replaceState({}, '', newUrl);
}

export function removeQueryParam(key: string) {
  const newUrl = new URL(window.location.href);
  newUrl.searchParams.delete(key);
  window.history.replaceState({}, '', newUrl);
}

export function removeQueryParams(keys: string[]) {
  const newUrl = new URL(window.location.href);
  keys.forEach((key) => newUrl.searchParams.delete(key));
  window.history.replaceState({}, '', newUrl);
}

export function getQueryParam(key: string): string | null {
  return new URLSearchParams(window.location.search).get(key);
}

export function consumeQueryParam(key: string): string | null {
  const val = getQueryParam(key);
  removeQueryParam(key);
  return val;
}

export function clearQueryParams(): void {
  const newUrl = new URL(window.location.href);
  newUrl.search = '';
  window.history.replaceState({}, '', newUrl);
}
