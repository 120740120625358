import styled, { css } from 'styled-components';
import { PlayerColor } from '../../../engine/types';
import { useState } from 'react';

interface Props {
  color: PlayerColor;
  onClose: () => void;
  children: (onClose: () => void) => React.ReactNode;
}

const Wrapper = styled.div`
  ${({ bgColor, dismissing }: { bgColor: string; dismissing: boolean }) =>
    css`
      background-color: ${bgColor};
      opacity: ${dismissing ? 0 : 1};
    `}
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-delay: 0.3s;
  position: absolute;
  inset: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  overflow: hidden;
`;

const Glow = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(
    circle,
    white 0,
    white 50%,
    transparent 80%,
    transparent
  );
`;

const Spin = styled.div`
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  position: absolute;
  width: 2000vh;
  height: 1000vh;
  ${({ bgColor }: { bgColor: string }) =>
    css`
      background: repeating-conic-gradient(
        ${bgColor} 0 15deg,
        transparent 15deg 30deg
      );
    `}
  animation: rotating 10s linear infinite;
`;

const Cover = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(circle, white 0, white 20%, transparent 60%);
  transition: transform 0.3s;
  ${({ dismissing }: { dismissing: boolean }) =>
    dismissing &&
    css`
      transform: scale(3);
    `}
`;

export const RewardScreen = ({ color, onClose, children }: Props) => {
  const [dismissing, setDismissing] = useState(false);

  const dismiss = () => {
    if (dismissing) return;
    setDismissing(true);
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  return (
    <Wrapper bgColor={color} dismissing={dismissing}>
      <Glow />
      <Spin bgColor={color} />
      <Cover dismissing={dismissing} />
      {children(dismiss)}
    </Wrapper>
  );
};

RewardScreen.displayName = 'RewardScreen';
