import { Player } from '../../../engine/player';
import {
  ResourceCount,
  ResourceList,
  GenericResource,
  Resource,
} from '../../../engine/types';

// TODO: Test this function
export function offerCanBeTradedWithBank(offer: ResourceCount, player: Player) {
  // Let's avoid problems and not allow to trade with the bank if more than one resource is offered at the same time
  if (
    Object.keys(offer).filter((res) => offer[res as unknown as Resource] !== 0)
      .length !== 1
  ) {
    return false;
  }

  return !!ResourceList.find((resource) => {
    const amount = offer[resource] || 0;
    return amount >= numResourcesNeededForBankTrade(resource, player);
  });
}

// TODO: Test this function
export function numResourcesNeededForBankTrade(
  resource: Resource,
  player: Player
): number {
  if (player.hasPort(resource)) {
    return 2;
  }

  if (player.hasPort(GenericResource.Generic)) {
    return 3;
  }

  return 4;
}

// TODO: Test this function
export function filterEmptyResources(resources: ResourceCount): ResourceCount {
  return Object.keys(resources).reduce((acc, resource) => {
    if (resources[resource as unknown as Resource] !== 0) {
      acc[resource as unknown as Resource] =
        resources[resource as unknown as Resource];
    }
    return acc;
  }, {} as ResourceCount);
}

export function getFirstResource(resources: ResourceCount): Resource {
  const res = filterEmptyResources(resources);
  return (Object.keys(res) as unknown as Resource[]).find(
    (resource) => res[resource] !== 0
  ) as Resource;
}

export function isEmpty(resources: ResourceCount): boolean {
  return Object.keys(filterEmptyResources(resources)).length === 0;
}
