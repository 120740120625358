import { Player } from '../../engine/player';

export const DiceResult = ({
  player,
  dice,
}: {
  player: Player;
  dice: number;
}) => {
  return (
    <span>
      {player.getName()} 🎲 {dice}
    </span>
  );
};
DiceResult.displayName = 'DiceResult';
