import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { InHandDevCard } from '../engine/player';
import {
  LogEntryData,
  PlayerColor,
  Resource,
  ResourceCount,
} from '../engine/types';
import { PlayerUI } from '../view/player/CatanPlayer';
import { GameState } from './GameState';
import { useRemote, useRemoteContext } from './RemoteProvider';
import { LogViewer } from '../view/logViewer/LogViewer';

const MobileViewport = styled.div`
  height: 100vh;
  width: 100vw;
`;

export const LocalPlayerGame = () => {
  const remote = useRemote();
  const { role } = useRemoteContext();
  const [gameState, setGameState] = useState<GameState>();
  const [logs, setLogs] = useState<LogEntryData[]>([]);

  useEffect(() => {
    remote.onGameStateUpdate((newState) => setGameState(newState));
    remote.onLogsUpdate((newLog) => {
      // TODO: if I keep on receiving duplicate logs, I should filter them out by ID
      setLogs((prevLogs) => [...prevLogs, newLog]);
    });
  }, [remote]);

  useEffect(() => {
    if (!gameState) {
      remote.getSavedGameState().then((state) => {
        if (state) {
          console.log('[PlayerGame] Restored game state');
          setGameState(state?.gameState);
        }
      });
    }

    if (!logs || !logs.length) {
      remote.getSavedLogs().then((logs) => {
        if (logs) {
          console.log('[PlayerGame] Restored logs');
          setLogs(logs);
        }
      });
    }
  }, [role, remote, logs, gameState]);

  if (!gameState) {
    return null;
  }

  const { currentPlayer, turn, phase, players, trade } = gameState;
  const id = remote.getThisPlayer();
  const player = players.find((player) => player.getId() === id);

  if (!player) {
    throw new Error(
      `Error finding player with ID ${id}. The existing ones are: ${players
        .map((player) => player.getId())
        .join(', ')}`
    );
  }

  const playing = currentPlayer?.getId() === player?.getId();

  return (
    <MobileViewport>
      <PlayerUI
        player={player}
        trade={trade}
        playing={playing}
        turn={turn}
        phase={phase}
        onPlayCard={(card: InHandDevCard) =>
          remote.emitPlayDevelopmentCard(card)
        }
        onSelectResources={(resources: ResourceCount) =>
          remote.emitResourcesSelected(resources)
        }
        onThrowDices={() => remote.emitThrowDices()}
        onTrade={() => remote.emitStartTrade()}
        onPass={() => remote.emitNextPlayer()}
        onConfirmTrade={(fromPlayer?: PlayerColor) => {
          remote.emitConfirmTrade(fromPlayer);
        }}
        onCancelTrade={() => {
          if (playing) {
            remote.emitCancelTrade();
          } else {
            remote.emitRetireOffer();
          }
        }}
        onTradeOfferChange={(offer: ResourceCount) => {
          remote.emitOfferChange(offer);
        }}
        onBankTrade={(from: Resource, to: Resource) => {
          remote.emitBankTrade(from, to);
        }}
        onDiscard={(resources: ResourceCount) => {
          remote.emitDiscard(resources);
        }}
      />
      <LogViewer logs={logs} />
    </MobileViewport>
  );
};

LocalPlayerGame.displayName = 'PlayerGame';
