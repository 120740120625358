import React, { useState } from 'react';
import { GameState, getEmptyGameState } from './GameState';
import { GameDirector, getOrCreateGameDirector } from './GameDirector';
import { Board } from '../engine/board';
import { useRemote, useRemoteContext } from './RemoteProvider';
import { GameRole, GameStatus } from './RemoteState';
import { Player } from '../engine/player';
import { PlayerColor } from '../engine/types';

const game: GameState = getEmptyGameState();

const GameContext = React.createContext<GameState>(game);
const BoardContext = React.createContext<Board | undefined>(undefined);
const DirectorContext = React.createContext<GameDirector | undefined>(
  undefined
);

export const useGameContext = () => React.useContext(GameContext);
export const useBoardContext = () => React.useContext(BoardContext);
export const useDirectorContext = () => React.useContext(DirectorContext);

/**
 * Game controller. Will set up a context for all the views
 * to nurture from it.
 * Every time the Game Director informs of a change in Game State,
 * this context provider will get updated and the UI will receive it.
 */

export const GameProvider = ({ children }: React.PropsWithChildren) => {
  // state of the game, spread around as GameContext
  const [gameState, setGameState] = useState(game);
  // game already restored
  const [restored, setRestored] = useState(false);

  // Remote context, to determine state of the game
  const remote = useRemote();
  const { gameStatus, players, role } = useRemoteContext();

  const onStateChange = React.useMemo(
    () => (newState: GameState, newBoard: Board) => {
      setGameState(newState);
      if (role !== GameRole.ADMIN) {
        // Doing in this order to make sure the game state runs after game board updates, so the
        // GameRemote's onGameStateUpdate below catches any further changes to the board as well.
        remote.persistGameBoard(newBoard);
        remote.persistGameState(newState);
      }
    },
    [role, remote]
  );

  const onLogSent = React.useCallback(
    (message: string, color?: PlayerColor) => {
      if (role !== GameRole.ADMIN) {
        remote.persistLog(message, color);
      }
    },
    [remote, role]
  );

  // game director instance, provided to children of Game
  const director = React.useMemo(
    () => getOrCreateGameDirector(onStateChange, onLogSent),
    [onStateChange, onLogSent]
  );
  // board instance, provided to children of Game
  const board = director.getBoard();

  React.useEffect(() => {
    if (!remote || !director || restored) {
      return;
    }

    const init = () => {
      // Initialize players
      director.setPlayers(
        players.map(
          (player) => new Player(player.id, player.name, player.color)
        )
      );
      // Start game
      director.startGame();
    };

    /**
     * If a code is provided, let's try to restore a saved game state.
     * If none is found (or no code is given by a remote connector), fall back to initial empty state.
     */
    remote.getSavedGameState().then((savedState) => {
      if (savedState) {
        director.restore(savedState);
        setGameState(savedState.gameState);
      } else {
        init();
      }
    }, init);

    // Bind player events to game director
    if (role === GameRole.DIRECTOR) {
      remote.bindPlayerEvents(director);
    }

    // For online players: listen to changes in the game state.
    // For the sake of keeping things in sync, pull the latest saved game state (including board) and restore it.
    if (role === GameRole.ONLINE_PLAYER) {
      remote.onGameStateUpdate(() => {
        remote.getSavedGameState().then((savedState) => {
          if (!savedState) {
            return;
          }
          director.restore(savedState);
          setGameState(savedState.gameState);
        });
      });
    }

    // Flag the game as restored/initialized. So we don't run the same logic over and over.
    if (!restored) {
      setRestored(true);
    }
  }, [remote, director, players, role, restored]);

  // TODO: this is quite subtle to live here... But for now, it's the way to hide the game while not started.
  if (gameStatus !== GameStatus.PLAYING) {
    return null;
  }

  return (
    <GameContext.Provider value={gameState}>
      <BoardContext.Provider value={board}>
        <DirectorContext.Provider value={director}>
          {children}
        </DirectorContext.Provider>
      </BoardContext.Provider>
    </GameContext.Provider>
  );
};
