import { ResourceCount } from '../../../engine/types';

// TODO: Test me!
export function diffResources(
  before: Partial<ResourceCount>,
  after: Partial<ResourceCount>
): Partial<ResourceCount> {
  const diff: Partial<ResourceCount> = {};

  for (let r in after) {
    const aft = after[+r] || 0;
    const bef = before[+r] || 0;
    if (aft && aft > bef) {
      diff[+r] = aft - bef;
    }
  }

  return diff;
}
