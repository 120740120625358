import { PlayerId, RemotePlayer } from '../game/RemoteState';
import {
  DevelopmentCard,
  DevelopmentCardNames,
  PlayerColor,
  PortResources,
  Resource,
  ResourceCount,
} from './types';

// WIP
type ConstructionCount = {
  road: number;
  settlement: number;
  city: number;
};

export interface InHandDevCard {
  card: DevelopmentCard;
  turnAcquired: number;
}

export interface PlayedDevCard extends InHandDevCard {
  turnPlayed: number;
}

interface PlayerDevCards {
  played: PlayedDevCard[];
  hand: InHandDevCard[];
}

// DB representation of Player. It contains all its private fields
// to rebuild a Player instance from it.
export interface DbPlayer {
  id: PlayerId;
  name: string;
  color: PlayerColor;
  constructions: ConstructionCount;
  resources: ResourceCount;
  devCards: PlayerDevCards;
  ports: PortResources[];
  victoryPoints: number;
  longestRoadSize: number;
  hasLongestRoad: boolean;
  hasLargestArmy: boolean;
  discardedInCurrentTurn: boolean;
}

export class Player {
  private constructions: ConstructionCount;
  private resources: ResourceCount;
  private devCards: PlayerDevCards;
  private ports: PortResources[];
  private victoryPoints: number;
  private longestRoadSize: number;
  private hasLongestRoad: boolean;
  private hasLargestArmy: boolean;
  private discardedInCurrentTurn: boolean;

  constructor(
    private id: PlayerId,
    private name: string,
    private color: PlayerColor,
    initState?: DbPlayer
  ) {
    this.victoryPoints = initState?.victoryPoints || 0;
    this.longestRoadSize = initState?.longestRoadSize || 0;
    this.hasLongestRoad = initState?.hasLongestRoad || false;
    this.hasLargestArmy = initState?.hasLargestArmy || false;
    this.constructions = initState?.constructions || {
      road: 0,
      settlement: 0,
      city: 0,
    };
    this.resources = initState?.resources || {
      [Resource.Clay]: 0,
      [Resource.Sheep]: 0,
      [Resource.Stone]: 0,
      [Resource.Wheat]: 0,
      [Resource.Wood]: 0,
    };
    this.devCards = initState?.devCards || {
      played: [],
      hand: [],
    };
    this.ports = initState?.ports || [];
    this.discardedInCurrentTurn = initState?.discardedInCurrentTurn || false;
  }

  toRemotePlayer(): RemotePlayer {
    return { id: this.id, name: this.name, color: this.color };
  }

  getId(): PlayerId {
    return this.id;
  }

  getName(): string {
    return this.name;
  }

  getColor(): PlayerColor {
    return this.color;
  }

  setVictoryPoints(points: number): void {
    this.victoryPoints = points;
  }

  getVictoryPoints(): number {
    return this.victoryPoints;
  }

  setLongestRoadSize(size: number): void {
    this.longestRoadSize = size;
  }

  getLongestRoadSize(): number {
    return this.longestRoadSize;
  }

  setLongestRoad(hasIt: boolean): void {
    this.hasLongestRoad = hasIt;
  }

  getLongestRoad(): boolean {
    return this.hasLongestRoad;
  }

  setLargestArmy(hasIt: boolean): void {
    this.hasLargestArmy = hasIt;
  }

  getLargestArmy(): boolean {
    return this.hasLargestArmy;
  }

  getDiscardedInCurrentTurn(): boolean {
    return this.discardedInCurrentTurn;
  }

  setDiscardedInCurrentTurn(done: boolean): void {
    this.discardedInCurrentTurn = done;
  }

  getResources(): ResourceCount {
    return this.resources;
  }

  getNumSettlements(): number {
    return this.constructions.settlement;
  }

  getNumCities(): number {
    return this.constructions.city;
  }

  getNumRoad(): number {
    return this.constructions.road;
  }

  addRoad(): number {
    return this.constructions.road++;
  }

  addSettlement(): number {
    return this.constructions.settlement++;
  }

  upgradeToCity(): void {
    this.constructions.settlement--;
    this.constructions.city++;
  }

  addPort(port: PortResources) {
    if (!this.hasPort(port)) {
      this.ports.push(+port);
    }
  }

  hasPort(port: PortResources) {
    return this.ports.indexOf(+port) !== -1;
  }

  hasEnoughResources(requiredResources: Partial<ResourceCount>): boolean {
    for (let resource in requiredResources) {
      const amountNeeded = requiredResources[resource] || 0;
      if (amountNeeded > this.resources[resource]) {
        return false;
      }
    }
    return true;
  }

  addResources(resources: Partial<ResourceCount>): void {
    for (let resource in resources) {
      const res = resources[resource] || 0;
      this.resources[resource] += res;
    }
  }

  removeResources(resources: Partial<ResourceCount>): void {
    if (!this.hasEnoughResources(resources)) {
      throw new Error(
        `Not enough resources to take from player ${
          this.name
        }. Requested: ${JSON.stringify(resources)}. Available: ${JSON.stringify(
          this.resources
        )}`
      );
    }

    for (let resource in resources) {
      const res = resources[resource] || 0;
      this.resources[resource] = Math.max(this.resources[resource] - res, 0);
    }
  }

  removeAllResourcesOfType(resourceType: Resource): number {
    const numResourcesOfType = this.resources[resourceType];
    this.resources[resourceType] = 0;
    return numResourcesOfType;
  }

  getNumResources(): number {
    let total = 0;
    for (let resource in this.resources) {
      total += this.resources[resource];
    }
    return total;
  }

  giveDevelopmentCard(card: DevelopmentCard, turn: number): void {
    // TODO: remove this! It unveils the card the player got
    console.log(
      `Player ${this.getName()} got a development card (${
        DevelopmentCardNames[card].emoji
      }${DevelopmentCardNames[card].title})!`
    );
    this.devCards.hand.push({ card, turnAcquired: turn });
  }

  playDevelopmentCard(cardToPlay: InHandDevCard, currentTurn: number) {
    if (cardToPlay.turnAcquired === currentTurn) {
      throw new Error(
        `Player ${this.getName()} can't use a development card that just got acquired. Must wait for the next turn.`
      );
    }

    if (this.devCards.played.find((card) => card.turnPlayed === currentTurn)) {
      throw new Error(
        `Player ${this.getName()} can't use MORE THAN 1 development card per turn.`
      );
    }

    const cardInHandToPlay = this.devCards.hand.find(
      (cardInHand) =>
        cardInHand.card === cardToPlay.card &&
        cardInHand.turnAcquired === cardToPlay.turnAcquired
    );
    this.devCards.hand = this.devCards.hand.filter(
      (cardInHand) => cardInHand !== cardInHandToPlay
    );
    this.devCards.played.push({ ...cardToPlay, turnPlayed: currentTurn });
  }

  getPlayedKnights(): PlayedDevCard[] {
    return this.devCards.played.filter(
      (card) => card.card === DevelopmentCard.Knight
    );
  }

  getNumPlayedKnights(): number {
    return this.getPlayedKnights().length;
  }

  getLastPlayedKnight(): PlayedDevCard | null {
    const knights = this.getPlayedKnights();
    let lastPlayed: PlayedDevCard | null = knights[0] || null;

    for (let knight of knights) {
      if (lastPlayed == null || lastPlayed.turnPlayed < knight.turnPlayed) {
        lastPlayed = knight;
      }
    }

    return lastPlayed;
  }

  getInHandDevelopmentCards(): InHandDevCard[] {
    return this.devCards.hand;
  }

  getPlayedDevelopmentCards(): PlayedDevCard[] {
    return this.devCards.played;
  }
}
