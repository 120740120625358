import { CatanBoard } from '../view/CatanBoard';
import styled from 'styled-components';
import { GameProvider } from './GameProvider';
import { OnlinePlayerUI } from './OnlinePlayerUI';
import { useRemote } from './RemoteProvider';

const BoardViewport = styled.div``;

const PlayerViewport = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  max-width: 400px;
  max-height: 600px;
  right: 0;
  bottom: 0;
`;

export const OnlinePlayerGame = () => {
  const remote = useRemote();
  const id = remote.getThisPlayer();

  if (!id) {
    return null;
  }

  return (
    <GameProvider>
      <BoardViewport>
        <CatanBoard playerId={id} />
      </BoardViewport>
      <PlayerViewport>
        <OnlinePlayerUI playerId={id} />
      </PlayerViewport>
    </GameProvider>
  );
};

OnlinePlayerGame.displayName = 'PlayerGame';
