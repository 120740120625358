import { LogViewer } from '../logViewer/LogViewer';
import { PlayerUI } from '../player/CatanPlayer';
import { TradeState } from '../../game/GameState';
import {
  DevelopmentCard,
  LogEntryData,
  PlayerColor,
  Resource,
} from '../../engine/types';
import { Player } from '../../engine/player';
import { PlayerId } from '../../game/RemoteState';
import styled from 'styled-components';
import { useDoubleTap } from 'use-double-tap';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

export const FakePlayerView = ({ onDismiss }: { onDismiss: () => void }) => {
  const logs: LogEntryData[] = [
    {
      date: new Date(),
      message: 'Testing a couple of log entries',
    },
    {
      date: new Date(),
      message: 'Another log now from a player',
      color: 'red',
    },
  ];
  const trade: TradeState = [
    {
      name: 'Player 1',
      color: 'red' as PlayerColor,
      offer: {
        [Resource.Wood]: 1,
        [Resource.Clay]: 1,
      },
    },
    {
      name: 'Player 2',
      color: 'blue' as PlayerColor,
      offer: {
        [Resource.Wood]: 1,
        [Resource.Clay]: 1,
      },
    },
    {
      name: 'Player 3',
      color: 'orange' as PlayerColor,
      offer: {
        [Resource.Wood]: 1,
        [Resource.Clay]: 1,
      },
    },
    {
      name: 'Player 4',
      color: 'green' as PlayerColor,
      offer: {
        [Resource.Wood]: 1,
        [Resource.Clay]: 1,
      },
    },
  ];
  const player = new Player(
    '111' as PlayerId,
    'FakePlayer',
    'red' as PlayerColor
  );
  player.addResources({
    [Resource.Wood]: 3,
    [Resource.Clay]: 5,
    [Resource.Sheep]: 1,
  });
  player.giveDevelopmentCard(DevelopmentCard.Knight, 1);
  player.giveDevelopmentCard(DevelopmentCard.Monopoly, 2);
  player.giveDevelopmentCard(DevelopmentCard.TwoResources, 2);
  player.giveDevelopmentCard(DevelopmentCard.TwoRoads, 3);
  player.giveDevelopmentCard(DevelopmentCard.Knight, 3);
  player.giveDevelopmentCard(DevelopmentCard.Knight, 3);
  player.giveDevelopmentCard(DevelopmentCard.VictoryPoint, 4);
  player.playDevelopmentCard(player.getInHandDevelopmentCards()[0], 3);
  const doubleTap = useDoubleTap(onDismiss);

  return (
    <Overlay onDoubleClick={onDismiss} {...doubleTap}>
      <PlayerUI
        player={player}
        trade={trade}
        playing={true}
        turn={4}
        phase={'construction'}
        onPlayCard={() => {}}
        onSelectResources={() => {}}
        onThrowDices={() => {}}
        onTrade={() => {}}
        onPass={() => {}}
        onConfirmTrade={() => {}}
        onCancelTrade={() => {}}
        onTradeOfferChange={() => {}}
        onDiscard={() => {}}
        onBankTrade={() => {}}
      />
      <LogViewer logs={logs} />
    </Overlay>
  );
};
