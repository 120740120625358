import styled, { css } from 'styled-components';
import { LogEntryData } from '../../engine/types';

const Entry = styled.div`
  font-family: sans-serif;
  font-weight: 500;
  margin-top: 8px;
  color: white;
`;

const Time = styled.span`
  font-size: 10px;
  font-family: sans-serif;
  ${({ color }: { color?: string }) =>
    css`
      color: ${color || 'gold'};
    `}
`;

interface Props {
  entry: LogEntryData;
}

export function LogEntry({ entry }: Props) {
  return (
    <Entry>
      <Time color={entry.color}>
        {entry.date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })}
      </Time>
      &nbsp;&nbsp;{entry.message}
    </Entry>
  );
}
