import { ActionButton } from '@adobe/react-spectrum';
import styled from 'styled-components';

const Actions = styled.div`
  display: grid;
  margin: 0 16px;
  gap: 8px;
  grid-template-columns: 1fr auto auto;
`;

interface OtherPlayerActionsProps {
  canMakeOffer: boolean;
  offerSent: boolean;
  onMakeOffer: () => void;
  canConfirmTrade: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const OtherPlayerActions = ({
  canMakeOffer,
  offerSent,
  onMakeOffer,
  canConfirmTrade,
  onConfirm,
  onCancel,
}: OtherPlayerActionsProps) => {
  return (
    <Actions>
      <ActionButton isDisabled={!canMakeOffer} onPress={onMakeOffer}>
        {!offerSent ? '🙂 ↔ 🙂🙂🙂' : 'Sent'}
      </ActionButton>
      <ActionButton isDisabled={!canConfirmTrade} onPress={onConfirm}>
        🤝 Accept
      </ActionButton>
      <ActionButton onPress={onCancel}>❌</ActionButton>
    </Actions>
  );
};

OtherPlayerActions.displayName = 'OtherPlayerActions';
