import { DbPlayer, Player } from './player';
import { PortResources } from './types';

enum Reward {
  Settlement = 1,
  City = 2,
}

export interface Occupation {
  owner: Player;
  reward: Reward;
}

interface DbOccupation {
  owner: DbPlayer;
  reward: Reward;
}

export interface DbCorner {
  occupation?: DbOccupation;
  port?: PortResources;
}

export class Corner {
  constructor(private port?: PortResources, private occupation?: Occupation) {}

  setPort(resource: PortResources) {
    this.port = resource;
  }

  getPort(): PortResources | null {
    return this.port || null;
  }

  placeSettlement(owner: Player) {
    this.occupation = {
      owner,
      reward: Reward.Settlement,
    };
  }

  placeCity(owner: Player) {
    if (!this.occupation || this.occupation.owner !== owner) {
      throw new Error(
        'A city cannot be placed if a player did not own a settlement before'
      );
    }

    this.occupation.reward = Reward.City;
  }

  getOwner(): Player | null {
    return this.occupation?.owner || null;
  }

  isOwner(player: Player): boolean {
    return (
      !!this.occupation &&
      !!player &&
      (player === this.occupation.owner ||
        player.getId() === this.occupation.owner.getId())
    );
  }

  getReward(): Reward {
    return this.occupation?.reward || (0 as Reward);
  }

  hasSettlement(): boolean {
    return this.occupation?.reward === Reward.Settlement;
  }

  hasCity(): boolean {
    return this.occupation?.reward === Reward.City;
  }
}
