import { GridGenerator, HexUtils } from 'react-hexgrid';
import { BasicGameConfig } from '../game/config';
import { DbBoard, hydrateTiles, initTiles, Tiles } from './boardHelpers';
import { Player } from './player';
import { Tile, TileType } from './tile';
import {
  getCorner,
  getEdge,
  TileCornerDir,
  TileEdgeDir,
  assertPlaceRoad,
  assertPlaceSettlement,
  assertPlaceCity,
} from './tileHelpers';
import { Hex } from './types';

/**
 * This will be the class for a Catan Board,
 * also the main entry point for most actions against the game board.
 *
 * It will:
 * - Store the basic board hexagons, generated using GridGenerator
 * - Store the tiles info, a dictionary of Tiles, keyed by each hex ID
 * - Will API all the logic to interact with the board, such as
 * -- initialize the game from a given configuration
 * -- board.addSettlement(tile (coords?), corner, player)
 * -- board.addRoad(tile (coords?), edge, player)
 * -- (all the associated checking methods)
 */

export class Board {
  private hexagons: Hex[];
  private tiles: Tiles;
  // Check https://www.redblobgames.com/grids/parts/#hexagon-relationships

  constructor(public size: number, config?: BasicGameConfig) {
    this.hexagons = GridGenerator.hexagon(size + 1); // +1 for the overflow ring
    this.tiles = initTiles(this.hexagons, size, config);
  }

  rehydrate(dbBoard: DbBoard, players: Player[]) {
    this.hexagons = dbBoard.hexagons;
    this.tiles = hydrateTiles(dbBoard.tiles, players);
  }

  getHexes() {
    return this.hexagons;
  }

  getTiles() {
    return this.tiles;
  }

  getTile(hex: Hex) {
    return this.tiles[HexUtils.getID(hex)];
  }

  /**
   *
   * @param tileId
   * @param dir
   * @param player
   * @param onGameStartup -> Bypass the need of a road reaching the corner during initial game startup
   */
  placeSettlement(
    tileId: string,
    dir: TileCornerDir,
    player: Player,
    onGameStartup = false
  ) {
    assertPlaceSettlement(
      this.tiles[tileId],
      dir,
      this.tiles,
      player,
      onGameStartup
    );
    const corner = getCorner(this.tiles[tileId], dir, this.tiles);
    corner?.placeSettlement(player);
    return corner;
  }

  placeCity(tileId: string, dir: TileCornerDir, player: Player) {
    assertPlaceCity(this.tiles[tileId], dir, this.tiles, player);
    const corner = getCorner(this.tiles[tileId], dir, this.tiles);
    corner?.placeCity(player);
    return corner;
  }

  placeRoad(tileId: string, dir: TileEdgeDir, player: Player) {
    assertPlaceRoad(this.tiles[tileId], dir, this.tiles, player);
    const edge = getEdge(this.tiles[tileId], dir, this.tiles);
    edge.placeRoad(player);
  }

  getRobberTile(): Tile {
    const robberTiles = Object.keys(this.tiles)
      .map((id) => this.tiles[id] as Tile)
      .filter(
        (tile) => tile.getTileType() === TileType.TILE && tile.hasRobber()
      );
    return robberTiles[0];
  }

  moveRobber(tileId: string) {
    this.getRobberTile().removeRobber();
    (this.tiles[tileId] as Tile).setRobber();
  }
}

export function rehydrateGameBoard(dbBoard: DbBoard, players: Player[]): Board {
  const board = new Board(dbBoard.size);
  board.rehydrate(dbBoard, players);
  return board;
}
