import styled from 'styled-components';
import { PlayerColor } from '../../../engine/types';
import { BuildingsList } from './BuildingsList';

interface Props {
  settlements: number;
  cities: number;
  roads: number;
  maxSettlements: number;
  maxCities: number;
  maxRoads: number;
  color: PlayerColor;
}

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;

export const Constructions = ({
  color,
  roads,
  maxRoads,
  settlements,
  maxSettlements,
  cities,
  maxCities,
}: Props) => {
  return (
    <Wrapper>
      <BuildingsList type='road' color={color} used={roads} max={maxRoads} />
      <BuildingsList
        type='settlement'
        color={color}
        used={settlements}
        max={maxSettlements}
      />
      <BuildingsList type='city' color={color} used={cities} max={maxCities} />
    </Wrapper>
  );
};

Constructions.displayName = 'Constructions';
