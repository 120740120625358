import styled, { css } from 'styled-components';
import { PlayerColor } from '../../../engine/types';

interface SettlementProps {
  color: PlayerColor;
  available: boolean;
}

const StyledSvg = styled.svg`
  width: 20px;
`;

const StyledCircle = styled.circle`
  stroke: black;
  stroke-width: 5%;

  ${({ color, available }: SettlementProps) =>
    css`
      fill: ${available ? color : 'transparent'};
      stroke-dasharray: ${available ? 0 : 5}%;
    `}
`;

export const Settlement = (props: SettlementProps) => {
  return (
    <StyledSvg viewBox='0 0 1 1'>
      <StyledCircle r='45%' cx='50%' cy='50%' {...props} />
    </StyledSvg>
  );
};

Settlement.displayName = 'Settlement';
