import { PropsWithChildren } from 'react';
import { Board } from '../engine/board';
import { Player } from '../engine/player';
import {
  DevelopmentCard,
  DevCardCount,
  Resource,
  PlayerColor,
  ResourceCount,
  ResourceEmoji,
} from '../engine/types';
import { computeRoadLength } from './LongestRoad';
import { useRemoteContext } from './RemoteProvider';
import { GameCode, GameRole, GameStatus, PlayerId } from './RemoteState';

export function pickFirstPlayer(players: Player[]): Player {
  return players[Math.floor(Math.random() * players.length)];
}

export function pickNextPlayer(
  players: Player[],
  current?: Player,
  revert = false
): Player {
  if (!current) {
    return pickFirstPlayer(players);
  }
  return players[
    (players.indexOf(current) + (revert ? players.length - 1 : 1)) %
      players.length
  ];
}

export function findPlayer(players: Player[], id: PlayerId) {
  const player = players.find((player) => player.getId() === id);

  if (!player) {
    throw new Error(`[Game Director] Player with ID ${id} not found.`);
  }

  return player;
}

export function findPlayerByColor(players: Player[], color: PlayerColor) {
  const player = players.find((player) => player.getColor() === color);

  if (!player) {
    throw new Error(`[Game Director] Player with color ${color} not found.`);
  }

  return player;
}

export function throwDice(): number {
  return getRandomInRange(6, 1);
}

function getRandomInRange(max: number, min = 0): number {
  return Math.floor(Math.random() * max + min);
}

// 1. Aggregate all of player resources to determine the number between 0..#res to randomly select.
// 2. get a number
// 3. return the resource in such position
// eg [1,1,3,3,3,4] -> randomly got a "2", then I'll return `3` (Stone).
export function pickPlayerRandomResource(player: Player): Resource | null {
  return pickRandomCardFromDeck(player.getResources());
}

// Returns a list of players that own more than 7 resources in their hand.
// We use this method to determine if any of them still need to discard resources after rolling a 7.
// Players that already discarded on this turn are exempt now.
export function getPlayersWithResourceSurplus(players: Player[]): Player[] {
  return players.filter(
    (player) =>
      player.getNumResources() > 7 && !player.getDiscardedInCurrentTurn()
  );
}

export function pickRandomDevelopmentCard(
  deck: DevCardCount
): DevelopmentCard | null {
  return pickRandomCardFromDeck(deck);
}

export function countNumCards<T extends number>(deck: {
  [key in T]: number;
}): number {
  let total = 0;
  for (let cardType in deck) {
    total += deck[cardType];
  }
  return total;
}

// Generic method to pick a random letter, given a deck in the format of CardCount
// CardCount = {[cardType]: numCardsOfType}
function pickRandomCardFromDeck<T extends number>(deck: {
  [key in T]: number;
}): T | null {
  const deckCards = Object.keys(deck)
    .map(Number)
    .filter((k) => deck[k as T] > 0) as T[];
  const totalCardsInDeck = deckCards.reduce(
    (total, key) => (total += deck[key]),
    0
  );

  if (totalCardsInDeck === 0) {
    return null;
  }

  let randomNumber = getRandomInRange(totalCardsInDeck);

  for (let cardType of deckCards) {
    randomNumber -= deck[cardType];
    if (randomNumber < 0) {
      return cardType;
    }
  }

  return null;
}

type RoadLengths = Map<Player, number>;

function computeRoadLengths(players: Player[], gameBoard: Board): RoadLengths {
  const roadLengths = new Map<Player, number>();
  players.forEach((player) => {
    roadLengths.set(player, computeRoadLength(player, gameBoard));
  });
  return roadLengths;
}

function getLongestRoadPlayer(lengths: RoadLengths): Player | undefined {
  let winner: Player | undefined;

  lengths.forEach((length, player) => {
    if (length >= 5 && (!winner || length > lengths.get(winner)!)) {
      winner = player;
    }
  });

  return winner;
}

function getLargestArmyPlayer(
  players: Player[],
  startingPlayer: Player
): Player | null {
  let winner: Player | null = null;
  let maxKnights = 0;

  // Note: In case this check returns false because both players played their most recent
  //        knight in the same turn, as we're iterating over all the players in order of
  //        play
  const playerWins = (player: Player, knights: number) => {
    if (!winner) {
      return true;
    }

    if (knights > maxKnights) {
      return true;
    }

    const playerLastPlayed = player.getLastPlayedKnight()!.turnPlayed;
    const winnerLastPlayed = winner.getLastPlayedKnight()!.turnPlayed;

    if (playerLastPlayed < winnerLastPlayed) {
      return true;
    }

    if (
      playerLastPlayed === winnerLastPlayed &&
      playerComesFirstInRound(player, winner, players, startingPlayer) ===
        player
    ) {
      return true;
    }

    return false;
  };

  players.forEach((player) => {
    const knights = player.getNumPlayedKnights();

    if (knights < 3) {
      return;
    }

    if (playerWins(player, knights)) {
      maxKnights = knights;
      winner = player;
    }
  });

  return winner;
}

// Algorithm to determine who's turn is first in each round, in a circular list of players
// 3 vs 1 (starting pl: 2) -> 3 comes first
//      v
// [0,1,2,3]
// 3 + 2 % 4 = 1 (second player)
// 1 + 2 % 4 = 3 (forth player)
// -> 3 comes first
function playerComesFirstInRound(
  player1: Player,
  player2: Player,
  players: Player[],
  startingPlayer: Player
): Player {
  const startIndex = players.indexOf(startingPlayer);
  const player1Index = players.indexOf(player1);
  const player2Index = players.indexOf(player2);
  return (player1Index + startIndex) % players.length <
    (player2Index + startIndex) % players.length
    ? player1
    : player2;
}

export function updateVictoryPoints(
  players: Player[],
  startingPlayer: Player,
  gameBoard: Board
): void {
  const roadLengths = computeRoadLengths(players, gameBoard);
  const longestRoadPlayer = getLongestRoadPlayer(roadLengths);
  const largestArmyPlayer = getLargestArmyPlayer(players, startingPlayer);

  return players.forEach((player) => {
    const hasLongestRoad = longestRoadPlayer?.getId() === player?.getId();
    const hasLargestArmy = largestArmyPlayer?.getId() === player?.getId();
    const points = computeVictoryPoints(player, hasLongestRoad, hasLargestArmy);
    player.setVictoryPoints(points);
    player.setLongestRoadSize(roadLengths.get(player) || 0);
    player.setLongestRoad(hasLongestRoad);
    player.setLargestArmy(hasLargestArmy);
  });
}

function computeVictoryPoints(
  player: Player,
  hasLongestRoad: boolean,
  hasLargestArmy: boolean
): number {
  let victoryPoints = 0;

  // Count cities
  victoryPoints += player.getNumCities() * 2;
  // Count settlements
  victoryPoints += player.getNumSettlements();
  // Count longest road (if this player's)
  victoryPoints += hasLongestRoad ? 2 : 0;
  // Count largest army (if this player's)
  victoryPoints += hasLargestArmy ? 2 : 0;
  // Count extra victory points from development cards
  victoryPoints += player.getPlayedDevelopmentCards().reduce((devCard) => {
    return devCard === DevelopmentCard.VictoryPoint ? 1 : 0;
  }, 0);
  // TODO: Should dev cards victory points be counted even when not used

  return victoryPoints;
}

// intentionally removed l, i, o and number 0 to prevent mistakes
// const CHARS = '123456789abcdefghjkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ';
const CHARS = '123456789ABCDEFGHJKLMNPQRSTUVWXYZ';

export function generateGameKey(): GameCode {
  const KEY_LEN = 4;

  let ans = '';
  for (let i = KEY_LEN; i > 0; i--) {
    ans += CHARS[Math.floor(Math.random() * CHARS.length)];
  }
  return ans as GameCode;
}

export function generatePlayerId(): PlayerId {
  const KEY_LEN = 4;

  let ans = '';
  for (let i = KEY_LEN; i > 0; i--) {
    ans += CHARS[Math.floor(Math.random() * CHARS.length)];
  }
  return ans as PlayerId;
}

export function dedup(list: any[], key: string) {
  const occurences: { [k: string]: boolean } = {};
  return list.filter((el) => {
    const repeated = !!occurences[el[key]];
    occurences[el[key]] = true;
    return !repeated;
  });
}

export function resourcesToText(resources: Partial<ResourceCount>): string {
  const res: string[] = [];

  Object.keys(resources).forEach((r) => {
    const amount = resources[+r];
    if (!!amount && amount > 0) {
      res.push(`${ResourceEmoji[+r]}: ${amount}`);
    }
  });

  return res.join(' ');
}

export function isPlayer(role: GameRole) {
  return role === GameRole.PLAYER || role === GameRole.ONLINE_PLAYER;
}

export const OnlyLocalPlayer = ({ children }: PropsWithChildren) => {
  const { role } = useRemoteContext();
  return role === GameRole.PLAYER ? (children as JSX.Element) || null : null;
};
OnlyLocalPlayer.displayName = 'OnlyLocalPlayer';

export const OnlyDirector = ({ children }: PropsWithChildren) => {
  const { role } = useRemoteContext();
  return role === GameRole.DIRECTOR || role === GameRole.ADMIN
    ? (children as JSX.Element) || null
    : null;
};
OnlyDirector.displayName = 'OnlyDirector';

export const OnlyOnlinePlayer = ({ children }: PropsWithChildren) => {
  const { role } = useRemoteContext();
  return role === GameRole.ONLINE_PLAYER
    ? (children as JSX.Element) || null
    : null;
};
OnlyOnlinePlayer.displayName = 'OnlyOnlinePlayer';

// Accepts both player roles: LocalPlayer or OnlinePlayer
export const OnlyPlayer = ({ children }: PropsWithChildren) => {
  const { role } = useRemoteContext();
  return role === GameRole.PLAYER || role === GameRole.ONLINE_PLAYER
    ? (children as JSX.Element) || null
    : null;
};
OnlyPlayer.displayName = 'OnlyPlayer';

// Controlling UIs depending on the game state

export const BeforePlaying = ({ children }: PropsWithChildren) => {
  const { gameStatus } = useRemoteContext();
  return gameStatus === GameStatus.NOT_STARTED ||
    gameStatus === GameStatus.WAITING_PLAYERS
    ? (children as JSX.Element) || null
    : null;
};
BeforePlaying.displayName = 'BeforePlaying';

export const WhilePlaying = ({ children }: PropsWithChildren) => {
  const { gameStatus } = useRemoteContext();
  return gameStatus === GameStatus.PLAYING
    ? (children as JSX.Element) || null
    : null;
};
WhilePlaying.displayName = 'WhilePlaying';

export const AfterPlaying = ({ children }: PropsWithChildren) => {
  const { gameStatus } = useRemoteContext();
  return gameStatus === GameStatus.FINISHED
    ? (children as JSX.Element) || null
    : null;
};
AfterPlaying.displayName = 'AfterPlaying';
