import './styles.css';
import { LandingPage } from './pregame/LandingPage';
import { RemoteProvider } from './game/RemoteProvider';
import { LocalPlayerGame } from './game/LocalPlayerGame';
import {
  AfterPlaying,
  BeforePlaying,
  OnlyDirector,
  OnlyLocalPlayer,
  OnlyOnlinePlayer,
  WhilePlaying,
} from './game/helpers';
import { defaultTheme, Provider as ThemeProvider } from '@adobe/react-spectrum';
import { OnlinePlayerGame } from './game/OnlinePlayerGame';
import { DirectorGame } from './game/DirectorGame';

export default function App() {
  return (
    <div className='App'>
      {/* TODO: Forcing colorScheme: Light until we properly support dark mode */}
      <ThemeProvider theme={defaultTheme} colorScheme='light'>
        <RemoteProvider>
          <BeforePlaying>
            <LandingPage />
          </BeforePlaying>

          <WhilePlaying>
            <OnlyLocalPlayer>
              <LocalPlayerGame />
            </OnlyLocalPlayer>

            <OnlyOnlinePlayer>
              <OnlinePlayerGame />
            </OnlyOnlinePlayer>

            <OnlyDirector>
              <DirectorGame />
            </OnlyDirector>
          </WhilePlaying>

          <AfterPlaying>Game Over! Winner... WIP 👼</AfterPlaying>
        </RemoteProvider>
      </ThemeProvider>
    </div>
  );
}
