import styled from 'styled-components';
import { InHandDevCard, PlayedDevCard } from '../../../engine/player';
import { DevCardList } from './DevCardList';
import { DevelopmentCard } from '../../../engine/types';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const PlayedCards = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  played: PlayedDevCard[];
  hand: InHandDevCard[];
  onCardSelect: (card: InHandDevCard) => void;
  turn: number;
}

export const DevelopmentCards = ({
  played,
  hand,
  onCardSelect,
  turn,
}: Props) => {
  const alreadyPlayed = !!played.find((card) => card.turnPlayed === turn);
  const numKnights = played.filter(
    (card) => card.card === DevelopmentCard.Knight
  ).length;
  const numVictoryPoints = played.filter(
    (card) => card.card === DevelopmentCard.VictoryPoint
  ).length;

  return (
    <Wrapper>
      <PlayedCards>
        <div>🐴 {numKnights}</div>
        <div>🏆 {numVictoryPoints}</div>
      </PlayedCards>
      <DevCardList
        cards={hand}
        onCardSelect={onCardSelect}
        turn={turn}
        alreadyPlayed={alreadyPlayed}
      />
      {/* <DevCardList cards={played} /> */}
    </Wrapper>
  );
};

DevelopmentCards.displayName = 'DevelopmentCards';
