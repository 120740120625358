import { useEffect, useState } from 'react';

type Orientation = 'portrait' | 'landscape';

export function getOrientation(): Orientation {
  if (!window.screen?.orientation?.type) {
    return 'landscape'; // default
  }
  return window.screen.orientation.type.split('-')[0] as Orientation;
}

export function useScreenOrientation() {
  const [orientation, setOrientation] = useState(getOrientation());

  useEffect(() => {
    const handler = () => setOrientation(getOrientation());
    window.addEventListener('orientationchange', handler);
    return () => window.removeEventListener('orientationchange', handler);
  }, []);

  return orientation;
}
