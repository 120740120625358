import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  z-index: 1;
  flex-wrap: wrap;
  max-width: 50vw;
`;

export const Card = styled.div`
  width: 80px;
  height: 128px;
  transition: opacity 0.3s, transform 0.4s;
  cursor: pointer;
  font-size: 2.5rem;
  ${({ tapped }: { tapped: boolean }) => {
    return css`
      opacity: ${tapped ? 0 : 1};
      transform: ${tapped ? 'translateY(-20px)' : 'translateY(0)'};
    `;
  }}
`;
