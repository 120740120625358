import styled from 'styled-components';

interface Props extends React.PropsWithChildren {
  disabled?: boolean;
  onClick: () => void;
}

const StyledButton = styled.button`
  background: white;
  border-radius: 3px;
  border: 2px solid black;
  color: black;
  margin: 0 1em;
  padding: 0.25em 1em;
  cursor: pointer;

  &:hover {
    background: lightgrey;
  }

  &[disabled] {
    opacity: 0.3;
  }
`;

export function DebugButton({ onClick, disabled, children }: Props) {
  return (
    <StyledButton disabled={disabled} onClick={onClick}>
      {children}
    </StyledButton>
  );
}
