import { PlayerColor } from '../../../engine/types';
import { City } from './City';
import { Road } from './Road';
import { Settlement } from './Settlement';

export const BuildingsList = ({
  color,
  used,
  max,
  type,
}: {
  color: PlayerColor;
  used: number;
  max: number;
  type: 'road' | 'settlement' | 'city';
}) => {
  const BuildingsList: React.ReactNode[] = [];
  let Building: typeof Road | typeof Settlement | typeof City = Road;

  switch (type) {
    case 'road':
      Building = Road;
      break;
    case 'settlement':
      Building = Settlement;
      break;
    case 'city':
      Building = City;
      break;
    default:
      enforceExhaustive(type);
  }

  for (let i = 0; i < max; i++) {
    BuildingsList.push(
      <Building
        key={`player-ui-${type}-${color}-${i}`}
        color={color}
        available={i < max - used}
      />
    );
  }

  return <div>{BuildingsList}</div>;
};

BuildingsList.displayName = 'BuildingsList';
