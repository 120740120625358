import styled, { css } from 'styled-components';
import { Resource, ResourceEmoji, ResourceImage } from '../../../engine/types';

const Card = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  ${({ res }: { res: Resource }) => {
    return css`
      background-image: url(${ResourceImage[res]});
      background-size: cover;
    `;
  }}
`;

const EmojiWrapper = styled.div`
  background-color: white;
  width: 70%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
`;

export const ResourceCard = ({ resource }: { resource: Resource }) => {
  return (
    <Card res={resource}>
      <EmojiWrapper>{ResourceEmoji[resource]}</EmojiWrapper>
    </Card>
  );
};
