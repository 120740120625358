import styled, { css } from 'styled-components';
import { PlayerColor } from '../../../engine/types';

interface RoadProps {
  color: PlayerColor;
  available: boolean;
}

const StyledSvg = styled.svg`
  width: 30px;
  margin-right: -10px;
`;

const StyledRect = styled.rect`
  stroke: black;
  stroke-width: 3%;
  transform-box: fill-box;
  transform: rotate(332deg);

  ${({ color, available }: RoadProps) =>
    css`
      fill: ${available ? color : 'transparent'};
      stroke-dasharray: ${available ? 0 : 5}%;
    `}
`;

export const Road = (props: RoadProps) => {
  return (
    <StyledSvg viewBox='0 0 1.5 1'>
      <StyledRect width='1.45' height='0.2' x='0' y='0.76' {...props} />
    </StyledSvg>
  );
};

Road.displayName = 'Road';
