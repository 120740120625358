import styled from 'styled-components';
import { RemotePlayer } from '../game/RemoteState';

interface Props {
  players: RemotePlayer[];
}

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 8px 0 0;
  display: grid;
  grid-template-columns: 50fr 50fr;
  gap: 8px 16px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
`;

const Dot = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: inline-block;
  margin-right: 8px;
`;

export const PlayerList = ({ players }: Props) => {
  return (
    <div>
      <div>Waiting for players...</div>
      {!!players.length ? (
        <List>
          {players.map((player) => (
            <ListItem key={`landing-page-player-${player.color}`}>
              <Dot color={player.color} />
              {player.name}
            </ListItem>
          ))}
        </List>
      ) : null}
    </div>
  );
};
