import styled, { css } from 'styled-components';
import { InHandDevCard } from '../../../engine/player';
import { useState } from 'react';
import { DevCard, NoDevCards } from './DevelopmentCard';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1;
`;

const ActionableCard = styled.div<{
  clickable?: boolean;
  playable?: boolean;
  selected?: boolean;
}>`
  width: 50px;
  height: 80px;
  border-radius: 4px;
  transition: transform 0.2s, box-shadow 0.2s;

  ${({ clickable, playable }) => css`
    cursor: ${clickable && playable ? 'pointer' : 'default'};
  `}

  ${({ playable, selected }) =>
    playable &&
    selected &&
    css`
      transform: scale(2.5);
      box-shadow: 0 0 10px -2px #333;
      z-index: 2;

      &:after {
        content: 'Click to play';
        font-size: 0.25rem;
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 100%;
        text-align: center;
      }
    `}
`;

interface Props {
  cards: InHandDevCard[];
  onCardSelect?: (card: InHandDevCard) => void;
  turn?: number;
  alreadyPlayed?: boolean;
}

export const DevCardList = ({
  cards,
  onCardSelect,
  turn,
  alreadyPlayed,
}: Props) => {
  const [selected, setSelected] = useState<InHandDevCard | null>(null);

  const handleSelect = (card: InHandDevCard) => {
    const clickable = !!onCardSelect;
    const playable = turn !== card.turnAcquired && !alreadyPlayed;

    if (!clickable || !playable) {
      setSelected(null);
      return;
    }

    if (selected === card) {
      onCardSelect?.(card);
    } else {
      setSelected(card);
    }
  };

  const getKey = (card: InHandDevCard, index: number) =>
    `devcard-${!!onCardSelect ? 'inhand' : 'played'}-${card.card}-${index}`;

  const isPlayable = (card: InHandDevCard) =>
    turn !== card.turnAcquired && !alreadyPlayed;

  return (
    <Wrapper>
      {selected && <Backdrop onClick={() => setSelected(null)} />}
      {cards.length ? (
        cards.map((card, index) => (
          <ActionableCard
            key={getKey(card, index)}
            clickable={!!onCardSelect}
            playable={isPlayable(card)}
            selected={selected === card}
            onClick={() => handleSelect(card)}
          >
            <DevCard card={card.card} disabled={!isPlayable(card)} />
          </ActionableCard>
        ))
      ) : (
        <NoDevCards />
      )}
    </Wrapper>
  );
};

DevCardList.displayName = 'DevCardList';
