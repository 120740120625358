import { DbPlayer, Player } from '../engine/player';
import { DevCardCount, PlayerColor, ResourceCount } from '../engine/types';

export type GamePhase =
  | null
  | 'dice'
  // | 'trade' // Considering to leave 'trade' out of the phases, and make it a subsystem within 'construction'
  | 'construction'
  | 'trade'
  | 'discard' // move to this phase when a 7 is drawn. Won't leave this phase until all players with >7 cards have discarded half of them.
  | 'robber' // phase where the robber has to be moved to a new tile, as we got a 7 (or we played a knight)
  | 'robbing' // phase where the current player will steal a card from a player who's neighbor of the robber. This phase goes after 'robber'.
  | 'initialSetup'
  | 'initialSetup_secondBuild'
  | 'devCard_road1'
  | 'devCard_road2'
  | 'devCard_2resources'
  | 'devCard_monopoly';

interface BaseGameState {
  phase: GamePhase;
  diceNumber?: number;
  turn: number;
  remainingDevCards: DevCardCount;
  trade: TradeState;
}

export interface TradeOffer {
  name: string;
  color: PlayerColor;
  offer: ResourceCount;
  playing?: boolean;
  accepted?: boolean;
}

export type TradeState = TradeOffer[];

/**
 * Instance holding the entire state of the game in a given moment.
 * It's basically a typed object (like pretty much any state...)
 * It serves multiple purposes:
 * - It's the main source of truth of a game in every moment
 * - It pairs up with GameDirector to drive the game
 * - It serves as the context for rendering the UI thru Game.tsx
 */
export interface GameState extends BaseGameState {
  players: Player[];
  startingPlayer?: Player;
  currentPlayer?: Player;
}

export function getEmptyGameState(): GameState {
  return {
    players: [],
    startingPlayer: undefined,
    currentPlayer: undefined,
    phase: null,
    diceNumber: undefined,
    turn: 0,
    remainingDevCards: {},
    trade: [],
  };
}

export interface DbGameState extends BaseGameState {
  players: DbPlayer[];
  startingPlayer?: DbPlayer;
  currentPlayer?: DbPlayer;
}

export function rehydrateGameState(dbGameState: DbGameState): GameState {
  const players = dbGameState.players.map(
    (player) => new Player(player.id, player.name, player.color, player)
  );

  const startingPlayer = players.find(
    (player) => player.getColor() === dbGameState.startingPlayer?.color
  );

  const currentPlayer = players.find(
    (player) => player.getColor() === dbGameState.currentPlayer?.color
  );

  return {
    ...dbGameState,
    players,
    startingPlayer,
    currentPlayer,
  };
}
