import { useState } from 'react';
import { GameRemote } from '../game/GameRemote';
import { GameCode } from '../game/RemoteState';
import { ActionButton, TextField } from '@adobe/react-spectrum';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 160px;

  & input {
    font-size: 32px;
    text-align: center;
    height: fit-content;
  }
`;

export const JoinGame = ({ remote }: { remote: GameRemote }) => {
  const [code, setCode] = useState<string>('');

  const joinGame = () => {
    if (!code) {
      throw new Error('Unable to join without code');
    }

    remote.joinGame(code as GameCode);
  };

  return (
    <Wrapper>
      <TextField
        width='100%'
        aria-label='Code'
        value={code}
        onChange={(text) => setCode(text.toUpperCase())}
      />
      <ActionButton width='100%' marginTop='16px' onPress={joinGame}>
        Join Game
      </ActionButton>
    </Wrapper>
  );
};
