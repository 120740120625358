import styled from 'styled-components';
import { useGameContext } from '../../game/GameProvider';
import { countNumCards } from '../../game/helpers';

const Deck = styled.button`
  width: 5rem;
  height: 7rem;
  border: none;
  border-radius: 4px;
  background-color: beige;
  position: fixed;
  right: 1rem;
  bottom: 6rem;
  box-shadow: gray 0 0 0 1px, beige 1px 1px 0 1px, gray 2px 2px 0 1px,
    beige 3px 3px 0 1px, gray 4px 4px 0 1px;
  cursor: pointer;
  font-size: 1.5rem;
`;

interface Props {
  onClick: () => void;
}

export const DevCardsDeck = ({ onClick }: Props) => {
  const { remainingDevCards } = useGameContext();
  const numCards = countNumCards(remainingDevCards);
  return numCards ? (
    <Deck onClick={onClick}>
      <div>🎴</div>
      <div>{numCards}</div>
    </Deck>
  ) : null;
};

DevCardsDeck.displayName = 'DevCardsDeck';
