type Probabilities = { [sum: number]: number };

export function getDiceProbabilities(): Probabilities {
  const probabilities: Probabilities = {};

  for (let i = 1; i <= 6; i++) {
    for (let j = 1; j <= 6; j++) {
      const sum = i + j;
      if (probabilities[sum]) {
        probabilities[sum]++;
      } else {
        probabilities[sum] = 1;
      }
    }
  }

  return probabilities;
}
