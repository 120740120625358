import desert from '../view/images/desert.png';
import clay from '../view/images/clay.png';
import sheep from '../view/images/sheep.png';
import stone from '../view/images/stone.png';
import wheat from '../view/images/wheat.png';
import wood from '../view/images/wood.png';

export interface Hex {
  q: number;
  r: number;
  s: number;
}

export enum Resource {
  Desert = 0,
  Wood,
  Clay,
  Stone,
  Wheat,
  Sheep,
}

export enum GenericResource {
  Generic = 6,
}

export type PortResources = Omit<Resource, 'Desert'> | GenericResource;

export const ResourceNames: { [resource: number]: string } = {
  [Resource.Wood]: 'Wood',
  [Resource.Clay]: 'Clay',
  [Resource.Stone]: 'Stone',
  [Resource.Wheat]: 'Wheat',
  [Resource.Sheep]: 'Sheep',
  [Resource.Desert]: 'Desert',
};

export const ResourceKeys: { [resource: number]: string } = {
  [Resource.Wood]: 'wood',
  [Resource.Clay]: 'clay',
  [Resource.Stone]: 'stone',
  [Resource.Wheat]: 'wheat',
  [Resource.Sheep]: 'sheep',
  [Resource.Desert]: 'desert',
};

export const ResourceEmoji: { [resource: number]: string } = {
  [Resource.Wood]: '🪵',
  [Resource.Clay]: '🧱',
  [Resource.Stone]: '🪨',
  [Resource.Wheat]: '🌾',
  [Resource.Sheep]: '🐑',
  [Resource.Desert]: '🏜',
};

export const ResourceImage: { [index: number]: string } = {
  [Resource.Desert]: desert,
  [Resource.Clay]: clay,
  [Resource.Sheep]: sheep,
  [Resource.Wheat]: wheat,
  [Resource.Wood]: wood,
  [Resource.Stone]: stone,
};

export const ResourceList: Resource[] = [
  Resource.Wood,
  Resource.Clay,
  Resource.Sheep,
  Resource.Wheat,
  Resource.Stone,
];

export type ResourceCount = { [res: number]: number };

export type ConstructionType = 'road' | 'settlement' | 'city' | 'devCard';

export interface ConstructionCosts {
  road: ResourceCount;
  settlement: ResourceCount;
  city: ResourceCount;
  devCard: ResourceCount;
}

// Development Card types

export enum DevelopmentCard {
  Knight = 1,
  VictoryPoint = 2,
  Monopoly = 3,
  TwoRoads = 4,
  TwoResources = 5,
}

export const DevelopmentCardNames: {
  [devCard: number]: { title: string; description: string; emoji: string };
} = {
  [DevelopmentCard.Knight]: {
    title: 'Knight',
    description: 'Play a Knight',
    emoji: '🐴',
  },
  [DevelopmentCard.VictoryPoint]: {
    title: '1 Victory Point',
    description: 'Victory Point',
    emoji: '🏆',
  },
  [DevelopmentCard.Monopoly]: {
    title: 'Monopoly',
    description: 'Monopoly',
    emoji: '🏴‍☠️',
  },
  [DevelopmentCard.TwoRoads]: {
    title: 'Build 2 roads',
    description: '',
    emoji: '🏞',
  },
  [DevelopmentCard.TwoResources]: {
    title: 'Get 2 resources',
    description: '',
    emoji: '💰',
  },
};

export type DevCardCount = { [res: number]: number };

declare enum PlayerColorTag {
  type = 'PLAYER_COLOR',
}
export type PlayerColor = string & PlayerColorTag;

export type PlayerColorEntry = { name: string; code: PlayerColor };

export const PlayerColors: PlayerColorEntry[] = [
  { name: 'red', code: 'red' as PlayerColor },
  { name: 'orange', code: 'orange' as PlayerColor },
  { name: 'blue', code: 'blue' as PlayerColor },
  { name: 'white', code: 'white' as PlayerColor },
  { name: 'purple', code: 'purple' as PlayerColor },
  { name: 'green', code: 'green' as PlayerColor },
];

export type DbLogEntryData = {
  created_at: string;
  message: string;
  color?: string;
};

export type LogEntryData = { date: Date; message: string; color?: string };
