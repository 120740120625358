import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Resource } from '../../../engine/types';
import { ResourceCard } from './ResourceCard';

interface Props {
  resource: Resource;
  amount: number;
  selectable?: boolean;
  onSelectionChange?: (amount: number) => void;
  selectionType?: 'select' | 'discard';
}

const Stack = styled.div`
  display: grid;
`;

interface CardProps {
  selectable?: boolean;
  selected?: boolean;
  selectionType?: 'select' | 'discard';
}

const Card = styled.div`
  min-width: 50px;
  max-width: 50px;
  height: 80px;
  opacity: 1;
  grid-row: 1;
  grid-column: 1;
  transition: transform 0.2s;
  transform: translateY(0);

  ${({ selectable }: CardProps) =>
    selectable &&
    css`
      cursor: pointer;
    `}

  ${({ selected, selectionType }: CardProps) =>
    selected &&
    css`
      transform: translateY(-120px) rotate(10deg);
      filter: ${selectionType === 'discard' ? 'grayscale(100%)' : 'none'};
    `}
`;

const NoCard = styled(Card)`
  opacity: 0.2;
`;

export const ResourceStack = ({
  resource,
  amount,
  selectable,
  onSelectionChange,
  selectionType,
}: Props) => {
  const [numSelected, setNumSelected] = useState(0);

  useEffect(() => {
    setNumSelected(0);
  }, [amount]);

  const change = (amount: number) => {
    const newAmount = numSelected + amount;
    setNumSelected(newAmount);
    onSelectionChange?.(newAmount);
  };

  const handleClick = (selected: boolean) => {
    if (selected) {
      change(-1);
    } else if (selectable) {
      change(1);
    }
  };

  if (amount === 0) {
    return (
      <NoCard>
        <ResourceCard resource={resource} />
      </NoCard>
    );
  }

  const cards = [];
  if (amount - numSelected === 0) {
    cards.push(
      <NoCard key={`player-resource-card-${resource}-empty`}>
        <ResourceCard resource={resource} />
      </NoCard>
    );
  }

  for (let i = 0; i < amount; i++) {
    cards.push(
      <Card
        style={{ marginTop: `${5 * i}px` }}
        selectable={selectable}
        selected={i < numSelected}
        key={`player-resource-card-${resource}-${i}`}
        onClick={() => handleClick(i < numSelected)}
        selectionType={selectionType}
      >
        <ResourceCard resource={resource} />
      </Card>
    );
  }

  return <Stack>{cards}</Stack>;
};
