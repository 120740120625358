import { useState, useEffect } from 'react';
import { InHandDevCard } from '../engine/player';
import {
  LogEntryData,
  ResourceCount,
  PlayerColor,
  Resource,
} from '../engine/types';
import { LogViewer } from '../view/logViewer/LogViewer';
import { PlayerUI } from '../view/player/CatanPlayer';
import { useDirectorContext, useGameContext } from './GameProvider';
import { useRemote } from './RemoteProvider';
import { PlayerId } from './RemoteState';

interface Props {
  playerId: PlayerId;
}

export const OnlinePlayerUI = ({ playerId }: Props) => {
  const remote = useRemote();
  const director = useDirectorContext();
  const [logs, setLogs] = useState<LogEntryData[]>([]);
  const { currentPlayer, turn, phase, trade, players } = useGameContext();
  const player = players.find((player) => player.getId() === playerId);

  useEffect(() => {
    remote.onLogsUpdate((newLog) => {
      // TODO: if I keep on receiving duplicate logs, I should filter them out by ID
      setLogs((prevLogs) => [...prevLogs, newLog]);
    });
  }, [remote]);

  useEffect(() => {
    if (!logs || !logs.length) {
      remote.getSavedLogs().then((logs) => {
        if (logs) {
          console.log('[PlayerGame] Restored logs');
          setLogs(logs);
        }
      });
    }
  }, [remote, logs]);

  if (!player || !director) {
    return null;
  }

  const playing = player === currentPlayer;

  return (
    <>
      <PlayerUI
        player={player}
        trade={trade}
        playing={playing}
        turn={turn}
        phase={phase}
        onPlayCard={(card: InHandDevCard) =>
          director.handlePlayDevelopmentCard(playerId, card)
        }
        onSelectResources={(resources: ResourceCount) =>
          director.handleResourcesSelected(playerId, resources)
        }
        onThrowDices={() => director.throwDices()}
        onTrade={() => director.startTrade()}
        onPass={() => director.nextPlayer()}
        onConfirmTrade={(fromPlayer?: PlayerColor) =>
          director.acceptTradeOffer(playerId, fromPlayer)
        }
        onCancelTrade={() => director.finishTrade()}
        onTradeOfferChange={(offer) => director.offerTrade(playerId, offer)}
        onDiscard={(resources: ResourceCount) =>
          director.handleDiscard(playerId, resources)
        }
        onBankTrade={(from: Resource, to: Resource) =>
          director.handleBankTrade(playerId, from, to)
        }
      />
      <LogViewer logs={logs} />
    </>
  );
};

OnlinePlayerUI.displayName = 'OnlinePlayerUI';
