import styled, { css } from 'styled-components';
import { PlayerColor } from '../../engine/types';
import { useGameContext } from '../../game/GameProvider';

const FullScreenOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #ffffffcc;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DiceRoller = styled.div`
  min-width: 25vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 48px;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 16px -6px grey;
  // This ensures the fixed position of the dice doesn't move things around
  min-height: 182px;

  ${({ color }: { color: PlayerColor }) =>
    css`
      border: 3px solid ${color};
    `};
`;

const PlayerName = styled.div`
  position: absolute;
  left: 16px;
  top: 16px;
  font-size: 24px;
`;

const RollHint = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

interface Props {
  onRoll: () => void;
}

export const DiceRollingOverlay = ({ onRoll }: Props) => {
  const { currentPlayer } = useGameContext();

  return (
    <FullScreenOverlay>
      <DiceRoller onClick={onRoll} color={currentPlayer!.getColor()}>
        <PlayerName>{currentPlayer!.getName()}'s turn</PlayerName>
        <RollHint>Tap to roll</RollHint>
      </DiceRoller>
    </FullScreenOverlay>
  );
};

DiceRollingOverlay.displayName = 'DiceRollingOverlay';
