import styled from 'styled-components';
import { TradeOffer } from '../../../game/GameState';
import { ResourceEmoji, ResourceList } from '../../../engine/types';

const Offer = styled.div<{ selected: boolean; color: string }>`
  padding: 8px;
  border: 1px solid ${({ color }) => color};
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? '#fff' : '#ccc')};
  transition: background-color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ selected }) => selected && 'box-shadow: 0 0 0 2px white;'}
`;

const Dot = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: inline-block;
  margin-right: 8px;
`;

const Player = styled.div`
  display: flex;
  align-items: center;
`;

const Resources = styled.div`
  display: flex;
  gap: 8px;
`;

interface Props {
  offer: TradeOffer;
  selected?: boolean;
  onSelect?: () => void;
}

export const PlayerOffer = ({ offer, onSelect, selected }: Props) => {
  const emojiList: JSX.Element[] = [];
  ResourceList.forEach((resource) => {
    for (let i = 0; i < offer.offer[resource]; i++) {
      emojiList.push(
        <span key={`player-${offer.color}-${resource}-${i}`}>
          {ResourceEmoji[resource]}
        </span>
      );
    }
  });

  return (
    <Offer onClick={onSelect} selected={!!selected} color={offer.color}>
      <Player>
        <Dot color={offer.color} /> {offer.name}
      </Player>
      <Resources>{emojiList}</Resources>
    </Offer>
  );
};

PlayerOffer.displayName = 'PlayerOffer';
