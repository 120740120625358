import { useState } from 'react';
import styled from 'styled-components';
import { Player } from '../../../engine/player';
import { PlayerColor, Resource, ResourceCount } from '../../../engine/types';
import { TradeState } from '../../../game/GameState';
import { Resources } from '../resources/Resources';
import { PlayerOffer } from './PlayerOffer';
import { PlayerOffers } from './PlayerOffers';
import { ResourcesSelector } from '../../resourcesSelector/ResourcesSelector';
import { getFirstResource, isEmpty, offerCanBeTradedWithBank } from './helpers';
import { CurrentPlayerActions } from './CurrentPlayerActions';
import { OtherPlayerActions } from './OtherPlayerActions';

const Wrapper = styled.div`
  position: absolute;
  inset: 0;
  z-index: 2;
  display: flex;
  background-color: rgba(10, 50, 50, 0.95);
  backdrop-filter: blur(2px);
  padding-top: 45px;
  flex-direction: column;
  gap: 16px;
`;

const Half = styled.div`
  flex: 1 1;
  margin: 0 16px;
`;

const HalfBottom = styled(Half)`
  display: grid;
  align-items: end;
  margin-bottom: 16px;
`;

interface Props {
  player: Player;
  trade: TradeState;
  playing: boolean;
  onConfirm: (withPlayer?: PlayerColor) => void;
  onCancel: () => void;
  onOfferChange: (offer: ResourceCount) => void;
  onBankTrade: (from: Resource, to: Resource) => void;
}

export const Trade = ({
  player,
  trade,
  playing,
  onConfirm,
  onCancel,
  onOfferChange,
  onBankTrade,
}: Props) => {
  const [offer, setOffer] = useState<ResourceCount>({});
  const [offerMade, setOfferMade] = useState<ResourceCount>({});
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerColor>();
  const [tradingWithBank, setTradingWithBank] = useState(false);

  const handleChange = (selection: ResourceCount) => {
    console.log('[Trade] selection change', player, selection);
    setOffer(selection);
  };

  const handleMakeOffer = () => {
    setTradingWithBank(false);
    onOfferChange(offer);
    setOfferMade(offer);
  };

  const handleCancelOffer = () => {
    setOffer({});
    setOfferMade({});
    onCancel();
  };

  const handleSelectOffer = (fromPlayer: PlayerColor) => {
    setSelectedPlayer(fromPlayer);
  };

  const startTradeWithBank = () => {
    setTradingWithBank(true);
  };

  const handleTradeWithBank = (desiredResource: ResourceCount) => {
    // pick just the first resource found. There should be just one, anyway
    onBankTrade(getFirstResource(offer), getFirstResource(desiredResource));
    setOffer({});
    setTradingWithBank(false);
  };

  const offers = trade.filter((trader) => trader.color !== player.getColor());
  const turnPlayerOffer = trade.find((trader) => trader.playing);
  const offerChanged = !isEmpty(offer) && offer !== offerMade;
  const myOfferIsAccepted = trade.find(
    (trader) => trader.color === player.getColor() && trader.accepted
  );
  const canConfirmTrade =
    (playing && !!selectedPlayer) || (!playing && !!myOfferIsAccepted);
  const canTradeWithBank = playing && offerCanBeTradedWithBank(offer, player);

  return (
    <Wrapper>
      <Half>
        {playing && tradingWithBank && (
          <ResourcesSelector phase={'trade'} onSelect={handleTradeWithBank} />
        )}
        {playing && (
          <PlayerOffers
            offers={offers}
            onSelect={handleSelectOffer}
            selected={selectedPlayer}
          />
        )}
        {!playing && turnPlayerOffer && <PlayerOffer offer={turnPlayerOffer} />}
      </Half>
      {playing && (
        <CurrentPlayerActions
          canMakeOffer={offerChanged}
          offerSent={!isEmpty(offer) && !offerChanged}
          onMakeOffer={handleMakeOffer}
          canTradeWithBank={canTradeWithBank}
          onTradeWithBank={startTradeWithBank}
          canConfirmTrade={canConfirmTrade}
          onConfirm={() => canConfirmTrade && onConfirm(selectedPlayer)}
          onCancel={handleCancelOffer}
        />
      )}
      {!playing && (
        <OtherPlayerActions
          canMakeOffer={offerChanged}
          offerSent={!isEmpty(offer) && !offerChanged}
          onMakeOffer={handleMakeOffer}
          canConfirmTrade={canConfirmTrade}
          onConfirm={() => canConfirmTrade && onConfirm()}
          onCancel={handleCancelOffer}
        />
      )}
      <HalfBottom>
        <Resources
          resources={player.getResources()}
          selectable
          onSelectionChange={handleChange}
        />
      </HalfBottom>
    </Wrapper>
  );
};

Trade.displayName = 'Trade';
