import styled from 'styled-components';
import { GamePhase } from '../../game/GameState';

interface Props {
  enabled: boolean;
  phase: GamePhase;
  onThrowDices: () => void;
  onTrade: () => void;
  onPass: () => void;
}

const Actions = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-evenly;
`;

const Button = styled.button`
  width: 100%;
  height: 50px;
  font-size: 1.2rem;
`;

export const GameActions = ({
  enabled,
  phase,
  onThrowDices,
  onTrade,
  onPass,
}: Props) => {
  return (
    <Actions>
      <Button disabled={!enabled || phase !== 'dice'} onClick={onThrowDices}>
        🎲 Roll
      </Button>
      <Button disabled={!enabled || phase !== 'construction'} onClick={onTrade}>
        🤝 Trade
      </Button>
      <Button disabled={!enabled || phase !== 'construction'} onClick={onPass}>
        ⏭️ Pass
      </Button>
    </Actions>
  );
};

GameActions.displayName = 'GameActions';
