import styled, { css } from 'styled-components';
import { DevelopmentCard, DevelopmentCardNames } from '../../../engine/types';

const Card = styled.div<{
  disabled?: boolean;
}>`
  width: 100%;
  height: 100%;
  border: 1px solid;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  border-radius: 4px;
  background-color: beige;
  opacity: 1;
  border-style: solid;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
      border-style: dashed;
    `}
`;

const CardPicture = styled.div``;

const CardDescription = styled.div`
  font-size: 0.5rem;
`;

interface Props {
  card: DevelopmentCard;
  disabled?: boolean;
}

export const DevCard = ({ card, disabled }: Props) => {
  return (
    <Card disabled={disabled}>
      <CardPicture>{DevelopmentCardNames[card].emoji}</CardPicture>
      <CardDescription>{DevelopmentCardNames[card].title}</CardDescription>
    </Card>
  );
};

DevCard.displayName = 'DevCard';

const NoCards = styled(Card)`
  opacity: 0.2;
  border-style: dashed;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 50px;
  height: 80px;
`;

export const NoDevCards = () => (
  <NoCards>
    <div>🐴</div>
    <div>🏴‍☠️</div>
    <div>🏆</div>
    <div>🏞</div>
  </NoCards>
);

NoDevCards.displayName = 'NoDevCards';
