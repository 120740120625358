export const Robber = ({ placeholder }: { placeholder?: boolean }) => {
  return (
    <svg
      x='-30px'
      y='-30px'
      opacity={placeholder ? 0.4 : 1}
      filter={placeholder ? 'grayscale(1)' : undefined}
    >
      <path
        d='M5.47791 31.0704C7.46097 34.432 11.1176 36.6889 15.3034 36.6889C21.6007 36.6889 26.7056 31.584 26.7056 25.2868C26.7056 18.9895 21.6007 13.8846 15.3034 13.8846C14.9728 13.8846 14.6465 13.9018 14.3232 13.9294C9.56972 18.3419 6.34467 24.3549 5.47791 31.0704ZM16.7226 21.8919C18.5137 21.8919 19.9659 23.344 19.9659 25.1351C19.9659 26.9262 18.5137 28.3784 16.7226 28.3784C14.9315 28.3784 13.4793 26.9262 13.4793 25.1351C13.4793 23.344 14.9315 21.8919 16.7226 21.8919Z'
        fill='#FFCE00'
      />
      <path
        d='M54.2917 19.1318C52.2636 15.9767 48.7252 13.8846 44.6956 13.8846C38.3984 13.8846 33.2935 18.9895 33.2935 25.2868C33.2935 31.584 38.3984 36.6889 44.6956 36.6889C48.6597 36.6889 52.1497 34.6653 54.193 31.5958C54.6762 29.5203 54.9323 27.3576 54.9323 25.1351C54.9323 23.0748 54.7091 21.0672 54.2917 19.1318ZM43.4796 28.3784C41.6885 28.3784 40.2363 26.9262 40.2363 25.1351C40.2363 23.344 41.6885 21.8919 43.4796 21.8919C45.2707 21.8919 46.7229 23.344 46.7229 25.1351C46.7229 26.9262 45.2707 28.3784 43.4796 28.3784Z'
        fill='#FFCE00'
      />
      <path
        d='M54.9324 25.135C54.9324 27.3575 54.6762 29.5201 54.1931 31.5956C55.396 29.7887 56.0979 27.62 56.0979 25.2866C56.0979 24.6834 56.0503 24.0913 55.9599 23.5134C55.6749 22.3727 55.3177 21.2603 54.8912 20.1823C54.7096 19.8205 54.5093 19.47 54.2918 19.1315C54.7092 21.0671 54.9324 23.0747 54.9324 25.135Z'
        fill='#FFB100'
      />
      <path
        d='M5.47801 31.0703C6.34478 24.3548 9.56982 18.3418 14.3233 13.9293C10.2842 14.2735 6.84749 16.7218 5.11213 20.1748C4.6828 21.2589 4.3234 22.3779 4.03718 23.5256C3.94819 24.0996 3.90137 24.6877 3.90137 25.2869C3.90137 27.3983 4.47766 29.3744 5.47801 31.0703Z'
        fill='#FFE454'
      />
      <path
        d='M30 0C13.458 0 0 13.458 0 30C0 46.542 13.458 60 30 60C46.542 60 60 46.542 60 30C60 13.458 46.542 0 30 0ZM5.11203 20.1747C6.84739 16.7218 10.2841 14.2735 14.3232 13.9293C14.6465 13.9017 14.9728 13.8845 15.3035 13.8845C21.6007 13.8845 26.7056 18.9894 26.7056 25.2867C26.7056 31.5839 21.6007 36.6889 15.3035 36.6889C11.1178 36.6889 7.46098 34.432 5.47791 31.0703C4.47756 29.3745 3.90127 27.3983 3.90127 25.2867C3.90127 24.6875 3.94789 24.0996 4.03708 23.5254C4.3233 22.3779 4.6827 21.2587 5.11203 20.1747ZM55.9599 23.5134C56.0501 24.0913 56.0979 24.6834 56.0979 25.2867C56.0979 27.62 55.3957 29.7888 54.1931 31.5957C52.15 34.6653 48.6598 36.6889 44.6957 36.6889C38.3985 36.6889 33.2936 31.5839 33.2936 25.2867C33.2936 18.9894 38.3985 13.8845 44.6957 13.8845C48.7253 13.8845 52.2637 15.9764 54.2918 19.1317C54.5093 19.4703 54.7096 19.8205 54.8912 20.1826C55.3177 21.2604 55.6749 22.3728 55.9599 23.5134Z'
        fill='black'
        stroke={placeholder ? 'red' : undefined}
        strokeWidth={placeholder ? 2 : undefined}
        strokeDasharray={placeholder ? 2 : undefined}
      />
      <path
        d='M16.7226 28.3784C18.5138 28.3784 19.9659 26.9263 19.9659 25.1351C19.9659 23.3439 18.5138 21.8918 16.7226 21.8918C14.9314 21.8918 13.4793 23.3439 13.4793 25.1351C13.4793 26.9263 14.9314 28.3784 16.7226 28.3784Z'
        fill='black'
      />
      <path
        d='M43.4798 28.3784C45.271 28.3784 46.7231 26.9263 46.7231 25.1351C46.7231 23.3439 45.271 21.8918 43.4798 21.8918C41.6886 21.8918 40.2365 23.3439 40.2365 25.1351C40.2365 26.9263 41.6886 28.3784 43.4798 28.3784Z'
        fill='black'
      />
    </svg>
  );
};

Robber.displayName = 'Robber';
