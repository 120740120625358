import { Fragment } from 'react';
import { Board } from '../../engine/board';
import { Hex } from '../../engine/types';
import { Corner } from './CatanCorner';
import { TileCorner } from '../../engine/tileHelpers';
import { HexUtils } from 'react-hexgrid';
import { BaseTile, TileType } from '../../engine/tile';
import { Corner as CornerData } from '../../engine/corner';
import { useLayoutContext } from 'react-hexgrid/lib/Layout';

function shouldRenderCorner(
  tile: BaseTile,
  hex: Hex,
  corner: TileCorner
): boolean {
  return (
    tile.getTileType() === TileType.TILE ||
    (hex.r > 0 && corner === TileCorner.N) ||
    (hex.r < 0 && corner === TileCorner.S)
  );
}

interface Props {
  hexagons: Hex[];
  board: Board;
  onClick: (corner: CornerData, tile: BaseTile, dir: TileCorner) => void;
  playing?: boolean;
}

export const Corners = ({ board, hexagons, onClick, playing }: Props) => {
  const { layout } = useLayoutContext();

  return (
    <>
      {hexagons
        // Get every hex coords and tile data
        .map((hex) => ({
          hexCoords: HexUtils.hexToPixel(hex, layout),
          tile: board.getTile(hex),
          renderN: shouldRenderCorner(board.getTile(hex), hex, TileCorner.N),
          renderS: shouldRenderCorner(board.getTile(hex), hex, TileCorner.S),
        }))
        // Render
        .map(({ hexCoords, tile, renderN, renderS }, i: number) => (
          <Fragment key={`cornerset-${i}`}>
            {renderN && (
              <Corner
                key={`corner-${i}-N`}
                corner={tile.getCorners()[TileCorner.N]}
                tile={tile}
                tiles={board.getTiles()}
                dir={TileCorner.N}
                coords={{
                  x: hexCoords.x,
                  y: hexCoords.y - layout.size.y,
                }}
                onClick={onClick}
                playing={playing}
              />
            )}
            {renderS && (
              <Corner
                key={`corner-${i}-S`}
                corner={tile.getCorners()[TileCorner.S]}
                tile={tile}
                tiles={board.getTiles()}
                dir={TileCorner.S}
                coords={{
                  x: hexCoords.x,
                  y: hexCoords.y + layout.size.y,
                }}
                onClick={onClick}
                playing={playing}
              />
            )}
          </Fragment>
        ))}
    </>
  );
};

Corners.displayName = 'Corners';
