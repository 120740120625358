import {
  createClient,
  SupabaseClient,
  RealtimeChannel,
  RealtimePresenceJoinPayload,
  RealtimePresenceLeavePayload,
} from '@supabase/supabase-js';
import { Board, rehydrateGameBoard } from '../engine/board';
import { DbBoard } from '../engine/boardHelpers';
import { InHandDevCard } from '../engine/player';
import {
  DbLogEntryData,
  LogEntryData,
  PlayerColor,
  PlayerColors,
  Resource,
  ResourceCount,
} from '../engine/types';
import {
  clearQueryParams,
  removeQueryParams,
  setQueryParam,
  setQueryParams,
} from '../queryParamHelpers';
import { GameDirector } from './GameDirector';
import { DbGameState, GameState, rehydrateGameState } from './GameState';
import { dedup, generateGameKey, generatePlayerId, isPlayer } from './helpers';
import {
  GameCode,
  GameRole,
  GameStatus,
  GameType,
  PlayerId,
  RemotePlayer,
  RemoteState,
} from './RemoteState';
import diff from 'microdiff';

/**
 * This class will hold all the communication. Current state of the "meta-game" data lives in GameState.
 */

interface RoomMember {
  role: GameRole;
  id: PlayerId;
  name: string;
  color: PlayerColor;
}

type StateChangeCb = (newState: RemoteState) => void;

// Define here all the valid events and the expected payload they'll receive.
// Note: `playerId: PlayerId` is passed to the payload by default.
interface PlayerEvents {
  'player-play-devcard': { card: InHandDevCard };
  'player-resources-selected': { resources: ResourceCount };
  'player-throw-dices': void;
  'player-start-trade': void;
  'player-next-player': void;
  'player-make-offer': { offer: ResourceCount };
  'player-retire-offer': void;
  'player-confirm-trade': { withPlayer?: PlayerColor };
  'player-cancel-trade': void;
  'player-discard': { resources: ResourceCount };
  'player-trade-bank': { from: Resource; to: Resource };
}

export class GameRemote {
  private supabase: SupabaseClient;
  private channel?: RealtimeChannel;
  private remoteState: RemoteState;
  private stateChangeCb: StateChangeCb = () => {};
  private gameStateChangeCb: (newState: GameState) => void = () => {};
  private logReceivedCb: (log: LogEntryData) => void = () => {};
  private boundEvents: { [key: string]: boolean } = {};
  private director: GameDirector | undefined;

  constructor(initialState: RemoteState, onStateChange: StateChangeCb) {
    this.remoteState = initialState;
    this.stateChangeCb = onStateChange;

    // initialize the supabase instance
    // TODO(sheniff): Create a netlify/function to provide this data, instead of posting it here, openly.
    //                  When we do that, remember to refresh the JWT secret, so this one is invalid.
    // TODO: Generate types with https://supabase.com/docs/reference/javascript/typescript-support
    // TODO: Try to run this locally with https://supabase.com/docs/guides/cli/local-development
    this.supabase = createClient(
      // 'https://rdvuyflotjajozgmfpft.supabase.co',
      // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJkdnV5ZmxvdGpham96Z21mcGZ0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY1ODg0OTIsImV4cCI6MTk5MjE2NDQ5Mn0.wmy2qtqzHpKyMsKQEcCgRFJpcSP7H54ddzcwcbiG88o'
      'https://swbpqbvmercbsyxjfoet.supabase.co',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InN3YnBxYnZtZXJjYnN5eGpmb2V0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzY4MDI2MzIsImV4cCI6MTk5MjM3ODYzMn0.tV2cefgf3WKbfk0YdoOXGIj9ULXUn_6o00lJQ1fl2DE'
    );
  }

  reset(initialState: RemoteState, onStateChange: StateChangeCb) {
    this.remoteState = initialState;
    this.stateChangeCb = onStateChange;
  }

  /**
   * Call this method every time we make changes to the state.
   * To ensure we always propagate it.
   */
  updateRemoteState(changes: Partial<RemoteState>) {
    this.remoteState = { ...this.remoteState, ...changes };
    console.log('Remote State Changed:', changes, this.remoteState);
    this.stateChangeCb(this.remoteState);
  }

  async initGame(type: GameType) {
    const code = generateGameKey();
    const gameStatus = GameStatus.WAITING_PLAYERS;
    const role = GameRole.DIRECTOR;

    // TODO: test any kind of failure...
    const { data, error } = await this.supabase
      .from('games')
      .insert([{ game_key: code, game_status: gameStatus, type }]);

    console.log('init game', data, error);

    if (error) {
      alert(error.message);
      console.log(error);
    }

    // For online game, the player that inits the game will also join,
    // then the channel and query params will be set up.
    if (type === GameType.ONLINE) {
      this.joinGame(code);
    } else {
      this.updateRemoteState({ code, gameStatus, role });
      this.startUpChannel(code, role);
      // persist in URL, for refresh
      setQueryParam('game', code);
      setQueryParam('role', `${role}`);
    }
  }

  resetGame() {
    this.updateRemoteState({
      code: undefined,
      gameStatus: GameStatus.NOT_STARTED,
      role: undefined,
    });
    this.channel?.unsubscribe();
    clearQueryParams();
  }

  async restoreGame(code: GameCode, role: GameRole, id?: PlayerId) {
    if (role === GameRole.ADMIN) {
      throw Error('Cannot restore game as ADMIN');
    }

    if (this.remoteState.code === code) {
      console.log(`Game already restored for code: "${code}"`);
      return;
    }

    const { data, error } = await this.supabase
      .from('games')
      .select('game_status,game_state,players')
      .eq('game_key', code);

    console.log('restore game', data, error);

    if (error) {
      alert(error.message);
      console.log(error);
      return;
    }

    if (data.length) {
      const gameState: DbGameState = data[0].game_state;
      const gameStatus: GameStatus = data[0].game_status;
      let players: RemotePlayer[] = data[0].players;

      if (gameStatus === GameStatus.PLAYING && !!gameState?.players) {
        // Rehydrate players in the remote state object
        players =
          gameState.players.map((player) => ({
            id: player.id,
            name: player.name,
            color: player.color,
          })) || [];
      }

      this.updateRemoteState({
        code,
        gameStatus,
        role,
        players,
      });
      this.startUpChannel(
        code,
        role,
        id,
        players.find((player) => player.id === id)
      );
    }
  }

  /* 
    Restores the game state from the DB. Called by Game object (director) when the 
    gameStateChangeCb has already been set.
  */
  async getSavedGameState(): Promise<
    { gameState: GameState; board: Board } | undefined
  > {
    const code = this.remoteState.code;
    if (!code) {
      throw Error('Cannot restore game without a game code');
    }

    const { data: gameData, error: gameError } = await this.supabase
      .from('games')
      .select('game_status,game_state')
      .eq('game_key', code);

    console.log('restore game state', gameData, gameError);

    if (gameError) {
      alert(gameError.message);
      console.log(gameError);
      return;
    }

    const { data: boardData, error: boardError } = await this.supabase
      .from('boards')
      .select('board')
      .eq('game_key', code);

    console.log('restore game board', boardData, boardError);

    if (boardError) {
      alert(boardError.message);
      console.log(boardError);
      return;
    }

    const dbGameState: DbGameState = gameData?.[0]?.game_state;
    const dbGameBoard: DbBoard = boardData?.[0]?.board;

    if (dbGameState && dbGameBoard) {
      const gameState = rehydrateGameState(dbGameState);
      return {
        gameState,
        board: rehydrateGameBoard(dbGameBoard, gameState.players),
      };
    }

    return;
  }

  async getSavedLogs(): Promise<LogEntryData[]> {
    const code = this.remoteState.code;
    const { data, error } = await this.supabase
      .from('logs')
      .select('message, color, created_at')
      .eq('game_key', code)
      .order('created_at', { ascending: true });

    console.log('restore logs', data, error);

    if (error) {
      alert(error.message);
      console.log(error);
      return [];
    }

    return data.map((log: DbLogEntryData) => ({
      date: new Date(log.created_at),
      message: log.message,
      color: log.color,
    }));
  }

  private startUpChannel(
    code: GameCode,
    role: GameRole,
    id?: PlayerId,
    player?: RemotePlayer
  ) {
    this.channel = this.supabase.channel(code, {
      config: { presence: { key: id } },
    });

    this.channel.on(
      'presence',
      { event: 'join' },
      ({ newPresences }: RealtimePresenceJoinPayload<RoomMember>) => {
        console.log('[channel.presence:join] New presence', newPresences);

        const newPlayers = newPresences
          .filter((presence) => isPlayer(presence.role))
          .filter(
            ({ id }) => !this.remoteState.players.find((p) => p.id === id)
          )
          .map(({ id, name, color }) => ({ id, name, color }));

        if (!newPlayers.length) {
          console.log('[channel.presence:join] no new players');
          return;
        }

        for (const player of newPlayers) {
          if (!player.name) {
            player.name = `Player ${this.remoteState.players.length + 1}`;
          }
          if (!player.color) {
            const firstAvailableColor = PlayerColors.find(
              (c) => !this.remoteState.players.find((p) => p.color === c.code)
            )?.code;
            player.color = firstAvailableColor || ('' as PlayerColor);
          }
        }

        const players = dedup(
          [...this.remoteState.players, ...newPlayers],
          'id'
        );
        this.updateRemoteState({ players });
      }
    );

    this.channel.on(
      'presence',
      { event: 'leave' },
      ({ leftPresences }: RealtimePresenceLeavePayload<RoomMember>) => {
        console.log('[channel.presence:leave]', leftPresences);

        let updatedPlayers = this.remoteState.players;
        leftPresences.forEach((presence) => {
          updatedPlayers = updatedPlayers.filter(
            (player) => player.id !== presence.id
          );
        });

        this.updateRemoteState({ players: updatedPlayers });
      }
    );

    this.channel.on(
      'postgres_changes',
      {
        event: 'UPDATE',
        schema: 'public',
        table: 'games',
        filter: `game_key=eq.${code}`,
      },
      (payload) => {
        console.log(
          '[channel.postgres_changes] Received GameState update',
          payload
        );

        if (isPlayer(this.remoteState.role!)) {
          console.log('updating player');
          const {
            game_state,
            game_status,
            players,
          }: Partial<{
            game_status: GameStatus;
            game_state: DbGameState;
            players: RemotePlayer[];
          }> = payload.new;

          // Update the Status of the game
          const changes: Partial<RemoteState> = {};
          if (game_status && game_status !== this.remoteState.gameStatus) {
            changes.gameStatus = game_status;
          }

          if (!players || diff(players, this.remoteState.players).length) {
            changes.players = players;
          }

          if (Object.keys(changes).length) {
            this.updateRemoteState(changes);
          }

          // Refresh the game State when received a new one
          if (game_state) {
            this.gameStateChangeCb(rehydrateGameState(game_state));
          }
        }
      }
    );

    this.channel.on(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public',
        table: 'logs',
        filter: `game_key=eq.${code}`,
      },
      (payload) => {
        console.log('[channel.postgres_changes] Received new logs', payload);

        if (isPlayer(this.remoteState.role!) && payload.new) {
          console.log('updating player with new logs');
          const log: LogEntryData = {
            date: new Date(payload.new.created_at),
            message: payload.new.message,
            color: payload.new.color,
          };
          this.logReceivedCb(log);
        }
      }
    );

    this.channel.subscribe(async (status, err) => {
      console.log('[channel.subscribe]', status, err);

      if (status === 'SUBSCRIBED') {
        const status = await this.channel!.track({
          online_at: new Date().toISOString(),
          role,
          id,
          name: player?.name,
          color: player?.color,
        });
        console.log('[channel.subscribe:subscribed]', status);
      }
    });
  }

  async joinGame(code: GameCode) {
    const { data } = await this.supabase
      .from('games')
      .select()
      .eq('game_key', code);

    if (!data?.length) {
      alert('Invalid Game Key');
      return;
    }

    const players: RemotePlayer[] = data[0].players;
    if (players.length >= 4) {
      alert('Game is full');
      return;
    }

    const gameType: GameType = data[0].type;
    const gameStatus = GameStatus.WAITING_PLAYERS;
    const role =
      gameType === GameType.ONLINE ? GameRole.ONLINE_PLAYER : GameRole.PLAYER;
    const id = generatePlayerId();

    this.updateRemoteState({ code, gameStatus, role, players });
    this.startUpChannel(code, role, id);

    // persist in URL, for refresh
    setQueryParams([
      { key: 'game', value: code },
      { key: 'role', value: `${role}` },
      { key: 'playerId', value: id },
    ]);
  }

  async updatePlayerInfo(data: Partial<RemotePlayer> & { id: PlayerId }) {
    const players = this.remoteState.players.map((player) => {
      if (player.id === data.id) {
        player = { ...player, ...data };
      }
      return player;
    });
    this.persistGamePlayers(this.remoteState.code!, players);
    this.updateRemoteState({ players });
  }

  leaveGame() {
    this.channel!.unsubscribe();
    removeQueryParams(['game', 'role', 'color', 'name']);
    this.updateRemoteState({
      gameStatus: GameStatus.NOT_STARTED,
      players: [],
    });
  }

  // This is for debugging purposes only... mostly.
  async listGames() {
    const { data, error } = await this.supabase.from('games').select();
    console.log('testing supabase', { data, error });
    return data;
  }

  // This is to be run when the players joined the game.
  // It should check there are enough players.
  startGame() {
    this.updateRemoteState({ gameStatus: GameStatus.PLAYING });
  }

  // For Debugging and coding purposes only
  playAdmin() {
    setQueryParam('role', `${GameRole.ADMIN}`);
    this.updateRemoteState({
      role: GameRole.ADMIN,
      gameStatus: GameStatus.PLAYING,
      players: [
        { id: '111' as PlayerId, name: 'sheniff', color: 'red' as PlayerColor },
        {
          id: '222' as PlayerId,
          name: 'kokixi',
          color: 'orange' as PlayerColor,
        },
        {
          id: '333' as PlayerId,
          name: 'yockie',
          color: 'purple' as PlayerColor,
        },
        { id: '444' as PlayerId, name: 'fersta', color: 'blue' as PlayerColor },
      ],
    });
  }

  async persistGamePlayers(code: GameCode, players: RemotePlayer[]) {
    const all = await this.supabase
      .from('games')
      .update({ players })
      .eq('game_key', code);

    console.log('updated DB', all);
  }

  private gameStateUpdateInTransit = false;
  private enqueuedGameStateUpdate: GameState | undefined;
  async persistGameState(state: GameState) {
    const code = this.remoteState.code;
    if (!code) {
      throw Error('Cannot persist game state without a game code');
    }

    if (this.gameStateUpdateInTransit) {
      console.log(
        'There is a GameState update in transit. Enqueuing update...'
      );
      this.enqueuedGameStateUpdate = { ...state };
      return;
    }

    this.gameStateUpdateInTransit = true;
    const all = await this.supabase
      .from('games')
      .update({ game_state: state, game_status: GameStatus.PLAYING })
      .eq('game_key', code);
    this.gameStateUpdateInTransit = false;

    console.log('[GameState] updated DB', all);

    // If there's a waiting update, send it now.
    if (this.enqueuedGameStateUpdate) {
      console.log('Flushing out enqueued GameState update...');
      const waitingState = this.enqueuedGameStateUpdate;
      this.enqueuedGameStateUpdate = undefined;
      this.gameStateUpdateInTransit = false;
      this.persistGameState(waitingState);
    }
  }

  private gameBoardUpdateInTransit = false;
  private enqueuedGameBoardUpdate: Board | undefined;
  async persistGameBoard(board: Board) {
    const code = this.remoteState.code;
    if (!code) {
      throw Error('Cannot persist board without a game code');
    }

    if (this.gameBoardUpdateInTransit) {
      console.log(
        'There is a GameBoard update in transit. Enqueuing update...'
      );
      this.enqueuedGameBoardUpdate = board;
      return;
    }

    this.gameBoardUpdateInTransit = true;
    const all = await this.supabase
      .from('boards')
      .upsert({ board, game_key: code })
      .eq('game_key', code);
    this.gameBoardUpdateInTransit = false;

    console.log('[GameBoard] updated DB', all);

    // If there's a waiting update, send it now.
    if (this.enqueuedGameBoardUpdate) {
      console.log('Flushing out enqueued GameBoard update...');
      const waitingBoard = this.enqueuedGameBoardUpdate;
      this.enqueuedGameBoardUpdate = undefined;
      this.gameBoardUpdateInTransit = false;
      this.persistGameBoard(waitingBoard);
    }
  }

  private logsQueue: {
    game_key: GameCode;
    message: string;
    color?: PlayerColor;
  }[] = [];
  private logInTransit = false;
  async persistLog(message: string, color?: PlayerColor) {
    const code = this.remoteState.code;
    if (!code) {
      throw Error('Cannot persist log without a game code');
    }

    this.logsQueue.push({ game_key: code, message, color });

    if (this.logInTransit) {
      console.log('There are logs in transit. Enqueuing...', this.logsQueue);
      return;
    }

    this.logInTransit = true;
    // Wait for 1s to see if more events come all in flock, before sending them all.
    setTimeout(() => {
      const logs = [...this.logsQueue];
      this.logsQueue = [];
      console.log('Flushing out logs...', logs);
      this.supabase
        .from('logs')
        .insert(logs)
        .then((all) => {
          this.logInTransit = false;
          console.log('[Logs] updated DB', all);
        });
    }, 1000);
  }

  // ~~~ Player Event receiver (for Director) ~~~
  bindPlayerEvents(director: GameDirector) {
    this.director = director;

    this.bindPlayerEvent('player-play-devcard', (data) => {
      if (!this.director) {
        throw new Error(
          '[Game Remote] Cannot bind player events without a director'
        );
      }
      this.director.handlePlayDevelopmentCard(
        data.payload.playerId,
        data.payload.card
      );
    });

    this.bindPlayerEvent('player-resources-selected', (data) => {
      if (!this.director) {
        throw new Error(
          '[Game Remote] Cannot bind player events without a director'
        );
      }
      this.director.handleResourcesSelected(
        data.payload.playerId,
        data.payload.resources
      );
    });

    this.bindPlayerEvent('player-throw-dices', () => {
      if (!this.director) {
        throw new Error(
          '[Game Remote] Cannot bind player events without a director'
        );
      }
      this.director.throwDices();
    });

    this.bindPlayerEvent('player-start-trade', () => {
      if (!this.director) {
        throw new Error(
          '[Game Remote] Cannot bind player events without a director'
        );
      }
      this.director.startTrade();
    });

    this.bindPlayerEvent('player-next-player', () => {
      if (!this.director) {
        throw new Error(
          '[Game Remote] Cannot bind player events without a director'
        );
      }
      this.director.nextPlayer();
    });

    this.bindPlayerEvent('player-confirm-trade', (data) => {
      if (!this.director) {
        throw new Error(
          '[Game Remote] Cannot bind player events without a director'
        );
      }
      this.director.acceptTradeOffer(
        data.payload.playerId,
        data.payload.withPlayer
      );
    });
    this.bindPlayerEvent('player-cancel-trade', () => {
      if (!this.director) {
        throw new Error(
          '[Game Remote] Cannot bind player events without a director'
        );
      }
      this.director.finishTrade();
    });

    this.bindPlayerEvent('player-make-offer', (data) => {
      if (!this.director) {
        throw new Error(
          '[Game Remote] Cannot bind player events without a director'
        );
      }
      this.director.offerTrade(data.payload.playerId, data.payload.offer);
    });

    this.bindPlayerEvent('player-retire-offer', (data) => {
      if (!this.director) {
        throw new Error(
          '[Game Remote] Cannot bind player events without a director'
        );
      }
      this.director.retireOffer(data.payload.playerId);
    });

    this.bindPlayerEvent('player-trade-bank', (data) => {
      if (!this.director) {
        throw new Error(
          '[Game Remote] Cannot bind player events without a director'
        );
      }
      this.director.handleBankTrade(
        data.payload.playerId,
        data.payload.from,
        data.payload.to
      );
    });

    this.bindPlayerEvent('player-discard', (data) => {
      if (!this.director) {
        throw new Error(
          '[Game Remote] Cannot bind player events without a director'
        );
      }
      this.director.handleDiscard(
        data.payload.playerId,
        data.payload.resources
      );
    });
  }

  private bindPlayerEvent<T extends PlayerEvents, K extends keyof T>(
    event: K,
    callback: (data: {
      payload: T[K] & { playerId: PlayerId };
      type: 'broadcast';
      event: K;
    }) => void
  ) {
    if (!this.channel && this.remoteState.role === GameRole.ADMIN) {
      // Bypass error alert in Admin mode. Call out otherwise.
      return;
    }

    if (this.boundEvents[event as string]) {
      console.warn(`[Game Remote] Event ${event as string} already bound`);
      return;
    }

    // Note: this "callback as any" is not very problematic as the type is covered
    //        in the signature of this private method.
    this.channel!.on('broadcast', { event: event as string }, callback as any);
    this.boundEvents[event as string] = true;
  }

  getThisPlayer(): PlayerId | undefined {
    if (!this.channel && this.remoteState.role === GameRole.ADMIN) {
      // Bypass error alert in Admin mode. Call out otherwise.
      return;
    }

    return this.channel!.params.config.presence?.key as PlayerId;
  }

  // ~~~ Player Event emitter (For local players) ~~~
  emitPlayDevelopmentCard(card: InHandDevCard) {
    this.emitPlayerEvent('player-play-devcard', { card });
  }

  emitResourcesSelected(resources: ResourceCount) {
    this.emitPlayerEvent('player-resources-selected', { resources });
  }

  emitThrowDices() {
    this.emitPlayerEvent('player-throw-dices', undefined);
  }

  emitStartTrade() {
    this.emitPlayerEvent('player-start-trade', undefined);
  }

  emitNextPlayer() {
    this.emitPlayerEvent('player-next-player', undefined);
  }

  emitConfirmTrade(withPlayer?: PlayerColor) {
    this.emitPlayerEvent('player-confirm-trade', { withPlayer });
  }
  emitCancelTrade() {
    this.emitPlayerEvent('player-cancel-trade', undefined);
  }
  emitOfferChange(offer: ResourceCount) {
    this.emitPlayerEvent('player-make-offer', { offer });
  }
  emitBankTrade(from: Resource, to: Resource) {
    this.emitPlayerEvent('player-trade-bank', { from, to });
  }
  emitRetireOffer() {
    this.emitPlayerEvent('player-retire-offer', undefined);
  }
  emitDiscard(resources: ResourceCount) {
    this.emitPlayerEvent('player-discard', { resources });
  }

  private emitPlayerEvent<T extends PlayerEvents, K extends keyof T>(
    event: K,
    payload: T[K]
  ) {
    if (!isPlayer(this.remoteState.role!)) {
      throw new Error(
        `[Game Remote] Only Players can emit player events. Got: ${this.remoteState.role}`
      );
    }

    const playerId = this.getThisPlayer();

    if (!playerId) {
      throw new Error(`[Game Remote] Player has no key assigned`);
    }

    this.channel!.send({
      payload: { ...payload, playerId },
      event: event as string,
      type: 'broadcast',
    });
  }

  // ~~~ State update receiver (for players)
  onGameStateUpdate(callback: (gameState: GameState) => void) {
    this.gameStateChangeCb = callback;
  }

  // ~~~ Logs receiver (for players)
  onLogsUpdate(callback: (newLog: LogEntryData) => void) {
    this.logReceivedCb = callback;
  }
}

// ~~~ GameRemote Singleton ~~~

let gameRemoteSingleton: GameRemote;

export const getOrCreateGameRemote = (
  initialState: RemoteState,
  onStateChange: StateChangeCb
) => {
  if (!gameRemoteSingleton) {
    gameRemoteSingleton = new GameRemote(initialState, onStateChange);
  } else {
    gameRemoteSingleton.reset(initialState, onStateChange);
  }
  return gameRemoteSingleton;
};
