import { useEffect, useState } from 'react';
import { PlayerColor } from '../engine/types';
import { GameRemote } from '../game/GameRemote';
import { useRemoteContext } from '../game/RemoteProvider';
import { PlayerColorSelector } from './ColorSelector';
import styled, { css } from 'styled-components';
import { ActionButton, TextField } from '@adobe/react-spectrum';
import { PlayerList } from './PlayerList';
import { getQueryParam } from '../queryParamHelpers';
import { GameRole, PlayerId, RemotePlayer } from '../game/RemoteState';

interface Props {
  remote: GameRemote;
}

const Wrapper = styled.div`
  border: 3px solid;
  background-color: #ffffffdd;

  ${({ color }: { color: PlayerColor }) =>
    css`
      border-color: ${color};
    `}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Code = styled.div`
  font-size: 1.2em;
  color: #fff;
  padding: 4px 16px;

  ${({ color }: { color: PlayerColor }) =>
    css`
      background-color: ${color};
    `}
`;

const OwnPlayer = styled.div`
  padding: 16px 16px 0;
  display: flex;
  flex-direction: column;
`;

const OtherPlayers = styled.div`
  padding: 0 16px 16px;
`;

// TODO: on name/color change -> update to list of players
export const PlayerWaitingRoom = ({ remote }: Props) => {
  const { code, players } = useRemoteContext();
  const [name, setName] = useState<string>('');
  const [color, setColor] = useState<PlayerColor>('black' as PlayerColor);

  // Initialize the player's name and color, if not available
  useEffect(() => {
    const playerId = getQueryParam('playerId');
    const player = players.find((p) => p.id === playerId);

    if (player) {
      if (!name && player.name) {
        setName(player.name);
      }

      if ((!color || color === ('black' as PlayerColor)) && player.color) {
        setColor(player.color);
      }
    }
  }, [name, color, players]);

  const leaveGame = () => {
    remote.leaveGame();
  };

  const updatePlayer = (info: Partial<RemotePlayer>) => {
    const playerId = getQueryParam('playerId');
    if (!playerId) {
      throw Error('User has no player ID');
    }
    remote.updatePlayerInfo({
      ...info,
      id: playerId as PlayerId,
    });
  };

  const handleColorChange = (color: PlayerColor) => {
    setColor(color);
    updatePlayer({ color });
  };

  const handleNameChange = () => {
    updatePlayer({ name });
  };

  const role = getQueryParam('role');

  return (
    <Wrapper color={color}>
      <Header>
        <Code color={color}>{code}</Code>
        <ActionButton isQuiet onPress={leaveGame}>
          Leave game
        </ActionButton>
      </Header>

      {/* Own player's color and name */}
      <OwnPlayer>
        <TextField
          value={name}
          UNSAFE_style={{ width: '100%' }}
          label='Name'
          onChange={setName}
          onBlur={handleNameChange}
        />
        <PlayerColorSelector
          color={color}
          currentPlayers={players}
          onChange={handleColorChange}
        />
      </OwnPlayer>

      <OtherPlayers>
        <PlayerList players={players} />
      </OtherPlayers>

      {!!role && +role === GameRole.ONLINE_PLAYER && (
        <ActionButton
          isDisabled={players.length < 2}
          onPress={() => remote.startGame()}
        >
          Start Game!
        </ActionButton>
      )}
    </Wrapper>
  );
};
