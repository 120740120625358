import styled, { css } from 'styled-components';
import { LogEntry } from './LogEntry';
import { LogEntryData } from '../../engine/types';
import { useState } from 'react';

const ViewerWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 8px;
  width: calc(100% - 8px * 2);
  margin-inline: auto;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 8px 16px 32px;
  cursor: pointer;
  display: flex;
  z-index: 1;
  transition: height 0.5s;

  ${({ expanded }: { expanded: boolean }) =>
    css`
      height: ${expanded ? 'calc(100% - 8px * 2)' : '72px'};
    `}
`;

const Viewer = styled.div`
  overflow: auto;
  text-align: left;
  line-height: 16px;
  font-size: 14px;
  display: flex;
  flex-direction: column-reverse;
  max-height: 100%;
  width: 100%;
`;

interface Props {
  logs: LogEntryData[];
}

export function LogViewer({ logs }: Props) {
  const [expanded, setExpanded] = useState(false);

  if (!logs || !logs.length) {
    return null;
  }

  return (
    <ViewerWrapper
      expanded={expanded}
      onDoubleClick={(e) => {
        e.stopPropagation();
        setExpanded(!expanded);
      }}
    >
      <Viewer>
        {logs
          .slice()
          .reverse()
          .map((entry, index) => (
            <LogEntry key={`log-entry-${index}`} entry={entry} />
          ))}
      </Viewer>
    </ViewerWrapper>
  );
}
