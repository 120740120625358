import { DbPlayer, Player } from './player';

enum EdgeType {
  Road,
  // Bridge // Currently unused. For potential expansion
}

interface EdgeOccupation {
  owner: Player;
  type: EdgeType;
}

export interface DbEdgeOccupation {
  owner: DbPlayer;
  type: EdgeType;
}

export interface DbEdge {
  occupation?: DbEdgeOccupation;
}

export class Edge {
  private occupation: EdgeOccupation | undefined;

  constructor(owner?: Player, type: EdgeType = EdgeType.Road) {
    if (owner) {
      this.occupation = {
        owner,
        type,
      };
    }
  }

  placeRoad(owner: Player) {
    if (this.occupation && this.occupation.owner !== owner) {
      throw new Error(
        'There is already a road in this edge, owned by ' +
          this.occupation?.owner.getName()
      );
    }

    this.occupation = {
      owner,
      type: EdgeType.Road,
    };
  }

  getOwner(): Player | null {
    return this.occupation?.owner || null;
  }

  isOwner(player: Player): boolean {
    return (
      !!this.occupation &&
      !!player &&
      (player === this.occupation.owner ||
        player.getId() === this.occupation.owner.getId())
    );
  }
}
