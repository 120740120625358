import { Layout } from 'react-hexgrid';
import styled from 'styled-components';
import { Corners } from './corner/CatanCorners';
import { Tiles } from './tile/CatanTiles';
import { Corner as CornerData } from '../engine/corner';
import { Edge as EdgeData } from '../engine/edge';
import { BaseTile, Tile } from '../engine/tile';
import { Edges } from './edge/CatanEdges';
import { TileCorner, TileEdge } from '../engine/tileHelpers';
import { DevCardsDeck } from './developmentCards/DevCardsDeck';
import { getTileBackgrounds } from './tile/CatanTile';
import { PlayerId } from '../game/RemoteState';
import {
  useBoardContext,
  useDirectorContext,
  useGameContext,
} from '../game/GameProvider';

interface Props {
  playerId?: PlayerId;
}

// TODO: move this somewhere else when we decide what to do with the UI in all the cases
const StyledWrapper = styled.div<{ online: boolean }>`
  background-color: lightblue;
  display: grid;
  place-content: ${({ online }) => (online ? 'start' : 'center')};
`;

const StyledSvg = styled.svg`
  height: 100vh;
  grid-row: 1;
  grid-column: 1;
`;

export const CatanBoard = ({ playerId }: Props) => {
  const board = useBoardContext();
  const director = useDirectorContext();
  const { currentPlayer } = useGameContext();

  if (!board || !director) {
    return null;
  }

  const hexagons = board.getHexes();
  const layout = {
    size: { x: 70, y: 70 },
    spacing: 1.02,
    flat: false,
  };
  // Note: kinda hacky, but the game director in "local mode" will have no playerId,
  // and it'll always be able to run actions.
  // TODO: revisit.
  const playing = !playerId || playerId === currentPlayer?.getId();

  const handleCornerClick = (
    corner: CornerData,
    tile: BaseTile,
    dir: TileCorner
  ) => {
    console.log('clicked corner!', corner, tile, dir);
    if (!playing) {
      console.log('This is not this player turn!');
      return;
    }
    director.handleCornerClick(corner, tile, dir);
  };
  const handleEdgeClick = (edge: EdgeData, tile: BaseTile, dir: TileEdge) => {
    console.log('clicked edge!', edge, tile, dir);
    if (!playing) {
      console.log('This is not this player turn!');
      return;
    }
    director.handleEdgeClick(edge, tile, dir);
  };
  const handleTileClick = (tile: Tile) => {
    console.log('clicked tile!', tile);
    if (!playing) {
      console.log('This is not this player turn!');
      return;
    }
    director.handleTileClick(tile);
  };
  const handleDevCardsDeckClick = () => {
    console.log('clicked dev cards deck!');
    if (!playing) {
      console.log('This is not this player turn!');
      return;
    }
    director.handleDevCardsDeckClick();
  };

  return (
    <StyledWrapper online={!!playerId}>
      <StyledSvg
        viewBox='-350 -330 700 660'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>{getTileBackgrounds(layout.size)}</defs>
        <Layout size={layout.size} spacing={layout.spacing} flat={layout.flat}>
          <Tiles
            hexagons={hexagons}
            board={board}
            onClick={handleTileClick}
            playing={playing}
          />
          <Edges
            hexagons={hexagons}
            board={board}
            onClick={handleEdgeClick}
            playing={playing}
          />
          <Corners
            hexagons={hexagons}
            board={board}
            onClick={handleCornerClick}
            playing={playing}
          />
        </Layout>
      </StyledSvg>
      <DevCardsDeck onClick={handleDevCardsDeckClick} />
    </StyledWrapper>
  );
};

CatanBoard.displayName = 'CatanBoard';
