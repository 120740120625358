import styled, { css } from 'styled-components';
import { PlayerColor } from '../../engine/types';

interface Props {
  name: string;
  color: PlayerColor;
  longestRoad?: boolean;
  largestArmy?: boolean;
  victoryPoints: number;
}

const Wrapper = styled.div`
  display: flex;
`;

const Name = styled.div`
  padding: 4px 16px;
  ${({ color }: { color: PlayerColor }) =>
    css`
      background-color: ${color};
      color: ${color === ('white' as PlayerColor) ? 'black' : 'white'};
    `}
`;

const HeaderData = styled.div`
  padding: 4px 16px;
`;

export const Header = ({
  name,
  color,
  victoryPoints,
  longestRoad,
  largestArmy,
}: Props) => {
  return (
    <Wrapper>
      <Name color={color}>{name}</Name>
      <HeaderData>
        <span>🏆 {victoryPoints}</span>
        {longestRoad && <span>🛣 {/* longest road */}</span>}
        {largestArmy && <span>🐴 {/* largest army */}</span>}
      </HeaderData>
    </Wrapper>
  );
};
