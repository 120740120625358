import { useEffect, useState } from 'react';
import { useRemote, useRemoteContext } from '../game/RemoteProvider';
import { GameRole, GameStatus, GameType } from '../game/RemoteState';
import { JoinGame } from './JoinGame';
import { PlayerWaitingRoom } from './PlayerWaitingRoom';
import { ActionButton, ButtonGroup } from '@adobe/react-spectrum';
import styled, { css } from 'styled-components';
import { OnlyDirector, OnlyPlayer } from '../game/helpers';
import newgame from './newgame.png';
import joingame from './joingame.png';
import { animated, useSpring } from '@react-spring/web';
import { FakePlayerView } from '../view/debug/FakePlayerView';
import { useScreenOrientation } from '../view/ScreenOrientation';
import { PlayerList } from './PlayerList';
import { DebugToggleBoat } from '../view/debug/DebugToggleBoat';
import {
  getQueryParam,
  removeQueryParam,
  setQueryParam,
} from '../queryParamHelpers';

const Page = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  @media (orientation: portrait) {
    flex-direction: column;
  }
`;

const Option = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;

  @media (orientation: portrait) {
    width: 100%;
  }

  @media (orientation: landscape) {
  }
`;

const OptionNew = styled(Option)`
  background-image: url(${newgame});

  @media (orientation: portrait) {
    border-bottom: 1px solid;
  }

  @media (orientation: landscape) {
    border-right: 1px solid;
  }
`;

const OptionJoin = styled(Option)`
  border-left: 1px solid;
  background-image: url(${joingame});
`;

const Separator = styled.div`
  text-transform: uppercase;
  border: 2px solid;
  border-radius: 100px;
  padding: 8px;
  font-weight: bold;
  position: absolute;
  background-color: white;
  z-index: 1;
  min-width: 40px;
  min-height: 40px;

  @media (orientation: portrait) {
    margin-bottom: -22px;
  }

  @media (orientation: landscape) {
    margin-right: -22px;
  }

  ${({ option }: { option: 'new' | 'join' | null }) =>
    !!option &&
    css`
      cursor: pointer;
      font-size: 30px;
      line-height: 15px;

      @media (orientation: portrait) {
        transform: rotate(90deg);
      }
    `}
`;

/**
 * This is the entry page before joining/creating a game.
 *
 * Whether this ends up living inside Game or outside, we'll see.
 */
export const LandingPage = () => {
  const remote = useRemote();
  const { code, players, gameStatus, role } = useRemoteContext();
  const [fakePlayerView, setFakePlayerView] = useState(false);
  const orientation = useScreenOrientation();
  const [debug, setDebug] = useState(false);

  const option =
    gameStatus === GameStatus.WAITING_PLAYERS
      ? role === GameRole.DIRECTOR
        ? 'new'
        : 'join'
      : null;

  // ~~ Animations ~~

  const orSpring = useSpring({
    to: {
      [orientation === 'portrait' ? 'bottom' : 'right']:
        option === 'new' ? '5%' : option === 'join' ? '95%' : '50%',
    },
  });

  const joinSpr = useSpring({
    to: {
      [orientation === 'portrait' ? 'maxHeight' : 'maxWidth']:
        option === 'new' ? '5%' : '100%',
    },
  });

  const joinInnerSpr = useSpring({
    to: {
      opacity: option === 'new' ? 0 : 1,
    },
  });

  const newSpr = useSpring({
    to: {
      [orientation === 'portrait' ? 'maxHeight' : 'maxWidth']:
        option === 'join' ? '5%' : '100%',
    },
  });

  const newInnerSpr = useSpring({
    to: {
      opacity: option === 'join' ? 0 : 1,
    },
  });

  // END ~~ Animations ~~

  const initGame = async (type: GameType) => {
    await remote.initGame(type);
  };

  const startGame = () => {
    // Move the game status to PLAYING, so that both players and director start to run the game
    remote.startGame();
  };

  const soloGame = () => {
    remote.playAdmin();
  };

  const resetOption = () => {
    remote.resetGame();
  };

  useEffect(() => {
    if (getQueryParam('debug') === '1') {
      setDebug(true);
    }
  }, []);

  const toggleDebug = () => {
    if (debug) {
      setDebug(false);
      removeQueryParam('debug');
    } else {
      setDebug(true);
      setQueryParam('debug', '1');
    }
  };

  return (
    <Page>
      <OptionNew as={animated.div} style={newSpr}>
        <animated.div style={newInnerSpr}>
          {gameStatus === GameStatus.NOT_STARTED && (
            <>
              <ActionButton onPress={() => initGame(GameType.LOCAL)}>
                Local Game
              </ActionButton>
              <ActionButton onPress={() => initGame(GameType.ONLINE)}>
                Online Game
              </ActionButton>
            </>
          )}

          {gameStatus === GameStatus.NOT_STARTED && debug && (
            <div>
              <h3>Working hard...</h3>
              <ButtonGroup orientation='vertical' align='center'>
                <ActionButton onPress={soloGame}>Solo Game</ActionButton>
                <ActionButton onPress={() => setFakePlayerView(true)}>
                  Fake Player View
                </ActionButton>
              </ButtonGroup>
            </div>
          )}

          {gameStatus === GameStatus.WAITING_PLAYERS && (
            <>
              <OnlyDirector>
                <div>Game Code: {code}</div>
                <PlayerList players={players} />
                <ActionButton
                  isDisabled={players.length < 2}
                  onPress={startGame}
                >
                  Start Game!
                </ActionButton>
              </OnlyDirector>
            </>
          )}
        </animated.div>
      </OptionNew>

      <Separator
        as={animated.div}
        style={orSpring}
        onClick={resetOption}
        option={option}
      >
        {option === 'new' ? '‹' : option === 'join' ? '›' : 'or'}
      </Separator>

      <OptionJoin as={animated.div} style={joinSpr}>
        <animated.div style={joinInnerSpr}>
          {debug ? (
            <PlayerWaitingRoom remote={remote} />
          ) : gameStatus === GameStatus.NOT_STARTED ? (
            <JoinGame remote={remote} />
          ) : null}
          {gameStatus === GameStatus.WAITING_PLAYERS && (
            <OnlyPlayer>
              <PlayerWaitingRoom remote={remote} />
            </OnlyPlayer>
          )}
        </animated.div>
      </OptionJoin>
      {fakePlayerView && (
        <FakePlayerView onDismiss={() => setFakePlayerView(false)} />
      )}
      <DebugToggleBoat isDebugging={debug} onToggleDebug={toggleDebug} />
    </Page>
  );
};

LandingPage.displayName = 'LandingPage';
