import styled from 'styled-components';
import { PlayerColor } from '../../../engine/types';
import { TradeState } from '../../../game/GameState';
import { PlayerOffer } from './PlayerOffer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface Props {
  offers: TradeState;
  onSelect: (playerColor: PlayerColor) => void;
  selected?: PlayerColor;
}

export const PlayerOffers = ({ offers, onSelect, selected }: Props) => {
  return (
    <Wrapper>
      {offers.map((offer) => (
        <PlayerOffer
          key={`player-offer-${offer.color}`}
          offer={offer}
          onSelect={() => onSelect(offer.color)}
          selected={offer.color === selected}
        />
      ))}
    </Wrapper>
  );
};

PlayerOffers.displayName = 'PlayerOffers';
