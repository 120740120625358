/**
 * This is going to be the entry point to the Player UI.
 *
 * This UI is mainly, initially intended for handheld devices, to show all
 * the information and actions the user might need to play in an in-person game
 * (using a tablet on the table).
 *
 * I'll be extending from here to other use cases (like full online mode)
 *
 * What this UI will display:
 * - User name & color
 * - Available constructions (roads, settlements, cities)
 * - Available resources
 * - Total victory points
 * - Current turn (1, 2, 3...)
 * - Longest road card
 * - Largest army card
 * - Development cards
 *  - At hand
 *  - Already played
 *
 * Some details for the UI:
 * - Resources will show up visually as a deck of same-kind resources (and a x3 indicating how many we have)
 * - Same stacking will apply to dev cards. E.g, having all the knights piled up
 * - "Available constructions" will show a dashed line for each resource that's already been used, to make it clear what you have used and left :)
 *
 * Some considerations re: data consumption and event handling:
 * - The UI should receive the minium amount of data necessary to render (Player info and maybe some additional data from the Game State), not the entire Game State
 * - The UI will communicate all the actions upwards to the entry layer, which will eventually take care to communicate with the Game Director
 *      - This should facilitate a future decoupled system
 */

import styled, { css } from 'styled-components';
import { InHandDevCard, Player } from '../../engine/player';
import { PlayerColor, Resource, ResourceCount } from '../../engine/types';
import { GamePhase, TradeState } from '../../game/GameState';
import { ResourcesSelector } from '../resourcesSelector/ResourcesSelector';
import { Constructions } from './constructions/Constructions';
import { DevelopmentCards } from './developmentCards/DevelopmentCards';
import { GameActions } from './GameActions';
import { Header } from './Header';
import { Resources } from './resources/Resources';
import { Trade } from './trade/Trade';
import { RewardLayer } from './rewards/RewardLayer';
import { DiscardResourcesSelector } from '../resourcesSelector/DiscardResourcesSelector';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: beige;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ color }: { color: PlayerColor }) =>
    css`
      border: 4px solid ${color};
    `}
`;

const Block = styled.div`
  margin: 0 16px;
`;

interface Props {
  player: Player;
  trade: TradeState;
  playing: boolean;
  turn: number;
  phase: GamePhase;
  onPlayCard: (card: InHandDevCard) => void;
  onSelectResources: (resources: ResourceCount) => void;
  onThrowDices: () => void;
  onTrade: () => void;
  onPass: () => void;
  onConfirmTrade: (fromPlayer?: PlayerColor) => void;
  onCancelTrade: () => void;
  onTradeOfferChange: (offer: ResourceCount) => void;
  onDiscard: (offer: ResourceCount) => void;
  onBankTrade: (from: Resource, to: Resource) => void;
}

// TODO: move maxXXX to `settings`
export const PlayerUI = ({
  player,
  trade,
  playing,
  turn,
  phase,
  onPlayCard,
  onSelectResources,
  onThrowDices,
  onTrade,
  onPass,
  onConfirmTrade,
  onCancelTrade,
  onTradeOfferChange,
  onDiscard,
  onBankTrade,
}: Props) => {
  const shouldPlayerDiscard =
    phase === 'discard' &&
    player.getNumResources() > 7 &&
    !player.getDiscardedInCurrentTurn();

  const shouldChooseResources =
    playing && (phase === 'devCard_2resources' || phase === 'devCard_monopoly');
  // To show trade, either you're the playing player or the playing player will have made an offer.
  const shouldTrade =
    phase === 'trade' && (playing || !!trade.find((t) => !!t.playing));

  return (
    <Wrapper color={player.getColor()}>
      <Header
        name={player.getName()}
        color={player.getColor()}
        victoryPoints={player.getVictoryPoints()}
        longestRoad={player.getLongestRoad()}
        largestArmy={player.getLargestArmy()}
      />
      <Block>
        <Resources resources={player.getResources()} />
      </Block>
      <Block>
        <DevelopmentCards
          played={player.getPlayedDevelopmentCards()}
          hand={player.getInHandDevelopmentCards()}
          onCardSelect={onPlayCard}
          turn={turn}
        />
      </Block>
      <Block>
        <GameActions
          enabled={playing}
          phase={phase}
          onThrowDices={onThrowDices}
          onTrade={onTrade}
          onPass={onPass}
        />
      </Block>
      <Block>
        <Constructions
          color={player.getColor()}
          settlements={player.getNumSettlements()}
          maxSettlements={5}
          cities={player.getNumCities()}
          maxCities={4}
          roads={player.getNumRoad()}
          maxRoads={14}
        />
      </Block>
      {shouldChooseResources && (
        <ResourcesSelector phase={phase} onSelect={onSelectResources} />
      )}
      {shouldTrade && (
        <Trade
          player={player}
          trade={trade}
          playing={playing}
          onConfirm={onConfirmTrade}
          onCancel={onCancelTrade}
          onOfferChange={(offer) => onTradeOfferChange(offer)}
          onBankTrade={onBankTrade}
        />
      )}
      {shouldPlayerDiscard && (
        <DiscardResourcesSelector
          resources={player.getResources()}
          onSelect={onDiscard}
        />
      )}
      <RewardLayer
        color={player.getColor()}
        resources={player.getResources()}
        devCards={player.getInHandDevelopmentCards()}
      />
    </Wrapper>
  );
};

PlayerUI.displayName = 'PlayerUI';
