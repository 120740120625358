import React from 'react';
import styled from 'styled-components';
import { Player } from './engine/player';
import { TileCorner, TileEdge } from './engine/tileHelpers';
import { Hex, PlayerColor, Resource } from './engine/types';
import {
  useBoardContext,
  useDirectorContext,
  useGameContext,
} from './game/GameProvider';
import { PlayerId } from './game/RemoteState';
import { clearQueryParams } from './queryParamHelpers';
import { CoordsToggleButton } from './view/debug/CoordsToggleButton';
import { DebugButton } from './view/debug/DebugButton';
import { DiceResult } from './view/debug/DiceResult';

interface DebugData {
  showCoords: boolean;
  toggleCoords: () => void;
}

const debug: DebugData = {
  showCoords: false,
  toggleCoords: () => {},
};

export const DebugContext = React.createContext(debug);

interface Props extends React.PropsWithChildren {
  readonly enabled: boolean;
}

const StyledPanel = styled.div`
  position: fixed;
  padding: 16px;
  border: 2px dashed black;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
`;

/**
 * Panel to show debug options and listen to keystrokes
 * to enable, disable them as well.
 */
export const Debug = ({ enabled, children }: Props) => {
  const director = useDirectorContext();
  const board = useBoardContext();

  // We want to make 'toggleCoords' available to the DebugContext, in case we need to call it
  // from anywhere else. This way, we can inject it to the DebugData instance when we init it.
  const toggleCoords = React.useCallback(() => {
    // https://reactjs.org/docs/hooks-reference.html#functional-updates
    setDebugData((prevDebugData) => ({
      ...prevDebugData,
      showCoords: !prevDebugData.showCoords,
    }));
  }, []);
  const [debugData, setDebugData] = React.useState({ ...debug, toggleCoords });
  const { phase, currentPlayer, diceNumber } = useGameContext();

  if (!director || !board) {
    return null;
  }

  /**
   * Start a game with 4 players
   */
  const startGame = () => {
    director.addPlayer(
      new Player('111' as PlayerId, 'sheniff', 'red' as PlayerColor)
    );
    director.addPlayer(
      new Player('222' as PlayerId, 'kokixi', 'orange' as PlayerColor)
    );
    director.addPlayer(
      new Player('333' as PlayerId, 'yockie', 'purple' as PlayerColor)
    );
    director.addPlayer(
      new Player('444' as PlayerId, 'fersta', 'blue' as PlayerColor)
    );
    director.startGame();
  };

  // Helper to avoid having to select initial set up for all the players every time...
  const setupRandom = () => {
    // initial setup
    setupIn({ q: 0, r: -1, s: 1 });
    setupIn({ q: -1, r: 0, s: 1 });
    setupIn({ q: -2, r: 1, s: 1 });
    setupIn({ q: 0, r: 0, s: 0 });
    // second setup
    setupIn({ q: -1, r: 1, s: 0 });
    setupIn({ q: 1, r: 0, s: -1 });
    setupIn({ q: 0, r: 1, s: -1 });
    setupIn({ q: -1, r: 2, s: -1 });
  };

  const setupIn = (hex: Hex) => {
    const { handleCornerClick, handleEdgeClick } = director;
    const tile = board.getTile(hex);

    const corner = tile.getCorners()[TileCorner.N];
    handleCornerClick(corner, tile, TileCorner.N);

    const edge = tile.getEdges()[TileEdge.NE];
    handleEdgeClick(edge, board.getTile(hex), TileEdge.NE);
  };

  const giveResources = (player: Player) => {
    player.addResources({
      [Resource.Sheep]: 1,
      [Resource.Clay]: 1,
      [Resource.Wood]: 1,
      [Resource.Stone]: 1,
      [Resource.Wheat]: 1,
    });
    director.updateGameState({});
  };

  const { showCoords } = debugData;

  return (
    <DebugContext.Provider value={debugData}>
      {children}
      {enabled && (
        <StyledPanel>
          <CoordsToggleButton enabled={showCoords} onClick={toggleCoords} />
          <DebugButton onClick={() => clearQueryParams()}>
            Clear cache
          </DebugButton>
          <DebugButton disabled={!!phase} onClick={startGame}>
            {phase ? 'playing' : 'Start game'}
          </DebugButton>
          {phase === 'initialSetup' && (
            <DebugButton onClick={setupRandom}>Skip setup phase</DebugButton>
          )}
          {currentPlayer && phase === 'construction' && (
            <DebugButton onClick={() => giveResources(currentPlayer)}>
              Ana es la banca!
            </DebugButton>
          )}
          {currentPlayer && diceNumber && (
            <DiceResult player={currentPlayer} dice={diceNumber} />
          )}
          {/* <DebugButton
          onClick={() =>
            director.updateGameState({ phase: 'devCard_2resources' })
          }
        >
          Show Resource Selector
        </DebugButton> */}
        </StyledPanel>
      )}
    </DebugContext.Provider>
  );
};

Debug.displayName = 'Debug';
