import { Corner, DbCorner, Occupation } from './corner';
import { DbEdge, Edge } from './edge';
import { Player } from './player';
import { Resource } from './types';

export enum TileType {
  TILE = 1,
  OFFSET,
}

export interface DbTile {
  tileId: string;
  type: TileType;
  edges: DbEdge[];
  corners: DbCorner[];
  resource: Resource | undefined;
  diceNumber: number | undefined;
  robber: boolean;
}

interface InitData {
  dbTile: DbTile;
  players: Player[];
}

export abstract class BaseTile {
  /* 
    Note to self: Each tile will store
    - 2 corners (N, S)
    - 3 edges (NE, NW, W)
    The corners and edges that fall outside of boundaries
    will be stored in the "offset tiles" in Board.
  */
  protected corners: Corner[];
  protected edges: Edge[];

  constructor(
    public tileId: string,
    protected type: TileType,
    init?: InitData
  ) {
    if (init) {
      this.corners = init.dbTile.corners.map((dbCorner) => {
        return new Corner(
          dbCorner.port,
          this.getCornerOccupation(dbCorner, init.players)
        );
      });
      this.edges = init.dbTile.edges.map(
        (dbEdge) => new Edge(this.getEdgeOwner(dbEdge, init.players))
      );
    } else {
      this.corners = this.initCorners();
      this.edges = this.initEdges();
    }
  }

  getTileType() {
    return this.type;
  }

  getCorners() {
    return this.corners;
  }

  getEdges() {
    return this.edges;
  }

  private initCorners(): Corner[] {
    return [new Corner(), new Corner()];
  }

  private initEdges(): Edge[] {
    return [new Edge(), new Edge(), new Edge()];
  }

  private getCornerOccupation(
    dbCorner: DbCorner,
    players: Player[]
  ): Occupation | undefined {
    if (!dbCorner.occupation) {
      return;
    }

    return {
      reward: dbCorner.occupation.reward,
      owner: players.find(
        (player) => player.getId() === dbCorner.occupation!.owner.id
      )!,
    };
  }

  private getEdgeOwner(dbEdge: DbEdge, players: Player[]) {
    return players.find(
      (player) => player.getId() === dbEdge.occupation?.owner.id
    );
  }
}

/**
 * Defines the special type of tile to wrap around the board, to store
 * offset corners and edges.
 */
export class OffsetTile extends BaseTile {
  constructor(public tileId: string, init?: InitData) {
    super(tileId, TileType.OFFSET, init);
  }
}

/**
 * Da tile.
 */
export class Tile extends BaseTile {
  private resource: Resource | undefined;
  private diceNumber: number | undefined;
  private robber: boolean;
  // Following structure from
  // https://www.redblobgames.com/grids/parts/#hexagons

  constructor(public tileId: string, init?: InitData) {
    super(tileId, TileType.TILE, init);
    this.robber = init?.dbTile.robber || false;
    this.resource = init?.dbTile.resource;
    this.diceNumber = init?.dbTile.diceNumber;
  }

  setResource(r: Resource) {
    this.resource = r;
  }

  getResource() {
    return this.resource;
  }

  setDiceNumber(n: number) {
    this.diceNumber = n;
  }

  getDiceNumber() {
    return this.diceNumber || 0;
  }

  didMatchDice(diceNumber: number): boolean {
    return diceNumber === this.diceNumber && !this.robber;
  }

  setRobber() {
    this.robber = true;
  }

  removeRobber() {
    this.robber = false;
  }

  hasRobber() {
    return this.robber;
  }
}
