import { OffsetTile, Tile, BaseTile, TileType, DbTile } from './tile';
import { Hex, Resource } from './types';
import { HexUtils } from 'react-hexgrid';
import { BasicGameConfig, TileGameData } from '../game/config';
import { getCorners } from './tileHelpers';
import { Player } from './player';

export type Tiles = { [id: string]: BaseTile };

function initEmptyTiles(hexes: Hex[], boardRadius: number): Tiles {
  const tiles: Tiles = {};

  hexes.forEach((hex) => {
    const id = HexUtils.getID(hex);
    // any tile outside of the board radius will be considered the offset,
    // only if it is part of the immediate border. Further tiles will throw.
    const largestCoord = Math.max(...[hex.q, hex.r, hex.s].map(Math.abs));

    if (largestCoord < boardRadius + 1) {
      tiles[id] = new Tile(id);
    } else if (largestCoord === boardRadius + 1) {
      tiles[id] = new OffsetTile(id);
    } else {
      throw new Error(
        'Unexpected hexagons outside the offset ring, got coord:' + id
      );
    }
  });

  return tiles;
}

function initLayout(tiles: Tiles, layout: { [tileId: string]: TileGameData }) {
  Object.keys(tiles).forEach((tileId) => {
    const tile: Tile = tiles[tileId] as Tile;
    const conf = layout[tileId];
    if (conf && tile.getTileType() === TileType.TILE) {
      tile.setResource(conf.resource);
      tile.setDiceNumber(conf.diceNumber);
      if (conf.resource === Resource.Desert) {
        tile.setRobber();
      }
      if (conf.ports) {
        conf.ports.forEach((port) => {
          const corners = getCorners(tile, tiles);
          corners[port.corner].setPort(port.resource);
        });
      }
    }
  });
}

export function initTiles(
  hexes: Hex[],
  boardRadius: number,
  config?: BasicGameConfig
): Tiles {
  const tiles = initEmptyTiles(hexes, boardRadius);

  if (config) {
    // TODO: initialize game config (aka, tile types, ports, etc...)
    initLayout(tiles, config.resourcesLayout);
  }

  return tiles;
}

/**
 * Helpers to restore and rehydrate Game Board when getting it from DB
 */

type DbTiles = Record<string, DbTile>;

export interface DbBoard {
  size: number;
  tiles: DbTiles;
  hexagons: Hex[];
}

export function hydrateTiles(dbTiles: DbTiles, players: Player[]): Tiles {
  const tiles: Tiles = {};
  Object.keys(dbTiles).forEach((id) => {
    const dbTile = dbTiles[id];
    const isOffset = dbTile.type === TileType.OFFSET;
    tiles[id] = isOffset
      ? new OffsetTile(id, { dbTile, players })
      : new Tile(id, { dbTile, players });
  });
  return tiles;
}
