import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import boatSvg from './boat.svg';

const Boat = styled.button`
  position: absolute;
  width: 50px;
  height: 50px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: filter 500ms;

  ${({
    isDebugging,
    position,
  }: {
    isDebugging: boolean;
    position: typeof defaultPosition;
  }) =>
    css`
      top: calc(${position.top}px - 25px);
      left: calc(${position.left}px - 25px);
      filter: grayscale(${isDebugging ? 1 : 0});
    `};
`;

const Image = styled.img`
  width: 100%;
  pointer-events: none;
`;

interface Props {
  isDebugging: boolean;
  onToggleDebug: () => void;
}

const defaultPosition = {
  top: 50,
  left: 50,
};

export const DebugToggleBoat = ({ isDebugging, onToggleDebug }: Props) => {
  const [moving, setMoving] = useState(false);
  const [position, setPosition] = useState(defaultPosition);

  useEffect(() => {
    const updatePosition = (event: MouseEvent) => {
      setPosition({
        left: event.clientX,
        top: event.clientY,
      });
    };

    const updatePositionTouch = (event: TouchEvent) => {
      setPosition({
        left: event.touches[0].clientX,
        top: event.touches[0].clientY,
      });
    };

    const stopMoving = () => {
      setMoving(false);
    };

    if (moving) {
      document.addEventListener('mousemove', updatePosition);
      document.addEventListener('mouseup', stopMoving);
      document.addEventListener('touchmove', updatePositionTouch);
      document.addEventListener('touchend', stopMoving);
    }

    return () => {
      document.removeEventListener('mousemove', updatePosition);
      document.removeEventListener('mouseup', stopMoving);
      document.removeEventListener('touchmove', updatePositionTouch);
      document.removeEventListener('touchend', stopMoving);
    };
  }, [moving]);

  return (
    <Boat
      isDebugging={isDebugging}
      position={position}
      onMouseDown={() => setMoving(true)}
      onTouchStart={() => setMoving(true)}
      onDoubleClick={onToggleDebug}
    >
      <Image src={boatSvg} alt='debug boat' />
    </Boat>
  );
};
DebugToggleBoat.displayName = 'DebugToggleBoat';
