import { Resource, ResourceCount } from '../../../engine/types';
import { useEffect, useState } from 'react';
import { ResourceCard } from '../resources/ResourceCard';
import { Card, Wrapper } from './RewardCards';

interface Props {
  resources: Partial<ResourceCount>;
  onDismiss: () => void;
}

interface PrizeCard {
  res: Resource;
  tapped: boolean;
}

export const ResourceRewardCards = ({ resources, onDismiss }: Props) => {
  const [remainingCards, setRemainingCards] = useState<PrizeCard[]>([]);

  useEffect(() => {
    const resourceItem: PrizeCard[] = [];
    for (let r in resources) {
      const resource = resources[+r];
      if (!!resource) {
        for (let i = 0; i < resource!; i++) {
          resourceItem.push({ res: +r as Resource, tapped: false });
        }
      }
    }
    setRemainingCards(resourceItem);
  }, [resources]);

  const tap = (index: number) => {
    const newRemainingCards = [...remainingCards];
    newRemainingCards[index].tapped = true;
    setRemainingCards(newRemainingCards);

    // Give time to the animation to finish
    setTimeout(() => {
      if (newRemainingCards.filter((card) => !card.tapped).length === 0) {
        onDismiss();
      }
    }, 300);
  };

  return (
    <Wrapper>
      {remainingCards.map((resource, index) => (
        <Card onClick={() => tap(index)} key={index} tapped={resource.tapped}>
          <ResourceCard resource={resource.res} />
        </Card>
      ))}
    </Wrapper>
  );
};

ResourceRewardCards.displayName = 'ResourceRewardCards';
