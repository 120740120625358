import {
  useSpring,
  animated,
  useTransition,
  useSpringRef,
  useChain,
} from '@react-spring/web';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { GamePhase } from '../../game/GameState';
import { DiceRollingOverlay } from './DiceRollingOverlay';

interface Props {
  phase: GamePhase;
  diceNumber?: number;
  onClick: () => void;
}

interface Coords {
  from: {
    x: number;
    y: number;
  };
  to: {
    x: number;
    y: number;
  };
}

const Wrapper = styled.div`
  width: 20px;
  height: 20px;
`;

const Dice = styled.div`
  position: fixed;
  cursor: pointer;
`;

const DiceNumber = styled.span`
  display: inline-block;
  margin-left: 4px;
`;

export const DiceRollingButton = ({ phase, diceNumber, onClick }: Props) => {
  const [coords, setCoords] = useState<Coords>({
    from: { x: 0, y: 0 },
    to: { x: 0, y: 0 },
  });
  const diceRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const dicePos = diceRef.current?.getBoundingClientRect();

    if (dicePos) {
      setCoords({
        from: {
          x: dicePos.left,
          y: dicePos.top,
        },
        to: {
          x: window.innerWidth / 2,
          y: window.innerHeight / 2,
        },
      });
    }
  }, []);

  const handleDiceRoll = () => {
    if (phase === 'dice' && !diceNumber) {
      onClick();
    }
  };

  // ~~ Animations ~~
  const numberTransApi = useSpringRef();
  const numberTrans = useTransition(!!diceNumber, {
    ref: numberTransApi,
    from: { opacity: 0, scale: 0, width: 0 },
    enter: (item) => async (next) => {
      await next({ width: 15 });
      await next({ opacity: 1, scale: 1 });
    },
    leave: { opacity: 0, scale: 0, width: 0 },
  });

  const overlayTransApi = useSpringRef();
  const overlayTrans = useTransition(phase === 'dice', {
    ref: overlayTransApi,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: phase !== 'dice' ? 1000 : 0,
  });

  const buttonSprApi = useSpringRef();
  const buttonSpr = useSpring({
    ref: buttonSprApi,
    to: {
      opacity: phase !== 'dice' && !diceNumber ? 0 : 1,
      transform: !diceNumber && phase === 'dice' ? 'scale(3)' : 'scale(1)',
      left: !diceNumber && phase === 'dice' ? coords.to.x : coords.from.x,
      top: !diceNumber && phase === 'dice' ? coords.to.y : coords.from.y,
    },
    delay: phase !== 'dice' ? 800 : 0,
  });

  useChain(
    [numberTransApi, overlayTransApi, buttonSprApi],
    [0, phase === 'dice' ? 0 : 0.7]
  );

  // TODO: dice spinning while number shows up... doesn't look great yet
  const emojiSpr = useSpring({
    to: {
      transform: !!diceNumber ? 'rotate(-1080deg)' : 'rotate(0deg)',
    },
  });
  // END ~~ Animations ~~

  return (
    <Wrapper ref={diceRef as any}>
      {overlayTrans(
        (style, item) =>
          item && (
            <animated.div style={style}>
              <DiceRollingOverlay onRoll={handleDiceRoll} />
            </animated.div>
          )
      )}
      <Dice as={animated.span} style={buttonSpr} onClick={handleDiceRoll}>
        <animated.span style={{ display: 'inline-block', ...emojiSpr }}>
          🎲
        </animated.span>
        {numberTrans((style, item) =>
          item ? (
            <DiceNumber as={animated.span} style={style}>
              {diceNumber}
            </DiceNumber>
          ) : (
            ''
          )
        )}
      </Dice>
    </Wrapper>
  );
};
DiceRollingButton.displayName = 'DiceRollingButton';
