import { Hexagon, HexUtils } from 'react-hexgrid';
import styled, { css } from 'styled-components';
import { Tile } from '../../engine/tile';
import {
  Hex,
  Resource,
  ResourceImage,
  ResourceKeys,
  ResourceNames,
} from '../../engine/types';
import { TileDebugLayer } from '../debug/TileDebugLayer';
import { DiceNumber } from './DiceNumber';
import { Robber } from './Robber';
import { useGameContext } from '../../game/GameProvider';

interface Props {
  hex: Hex;
  tile: Tile;
  onClick: (tile: Tile) => void;
  playing?: boolean;
}

const colorByResource: { [index: number]: string } = {
  [Resource.Desert]: 'burlywood',
  [Resource.Clay]: 'darkred',
  [Resource.Sheep]: 'lightgreen',
  [Resource.Wheat]: 'gold',
  [Resource.Wood]: 'forestgreen',
  [Resource.Stone]: 'darkgray',
};

function getTileBg(tile: Tile) {
  const resource = tile.getResource();
  if (resource === undefined) {
    return 'black';
  }
  return `url(#${ResourceKeys[resource]})` || 'black';
}

export function CatanTile({ hex, tile, onClick, playing }: Props) {
  const { phase } = useGameContext();
  const key = HexUtils.getID(hex);
  const diceNumber = tile.getDiceNumber();

  return (
    <Hexagon
      key={key}
      q={hex.q}
      r={hex.r}
      s={hex.s}
      cellStyle={{ fill: getTileBg(tile) }}
      onClick={() => onClick(tile)}
    >
      {diceNumber && <DiceNumber diceNumber={diceNumber} />}
      {tile.hasRobber() && <Robber />}
      {!tile.hasRobber() && phase === 'robber' && playing && (
        <Robber placeholder />
      )}
      <TileDebugLayer hex={hex} tile={tile} />
    </Hexagon>
  );
}

const Overlay = styled.rect`
  ${({ color }: { color: string }) =>
    css`
      opacity: 0.6;
      fill: ${color};
    `}
`;
/**
 * Helper method to inject SVG's <defs> object to be used later on in the tiles.
 */
export function getTileBackgrounds(size: { x: number; y: number }) {
  return Object.keys(ResourceNames).map((resource) => {
    const res = resource as unknown as Resource;
    const name = ResourceKeys[res];
    const dimensions = { width: size.x * 2, height: size.y * 2 };
    return (
      <pattern
        id={name}
        key={`resource-pattern-${name}`}
        patternUnits='userSpaceOnUse'
        {...dimensions}
        x={size.x}
        y={size.y}
      >
        <image href={ResourceImage[res]} {...dimensions} />
        <Overlay color={colorByResource[res]} {...dimensions} />
      </pattern>
    );
  });
}
