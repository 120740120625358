import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Resource, ResourceCount } from '../../../engine/types';
import { ResourceStack } from './ResourceStack';
import { filterEmptyResources } from '../trade/helpers';

interface Props {
  resources: ResourceCount;
  selectable?: boolean;
  onSelectionChange?: (selection: ResourceCount) => void;
  selectionType?: 'select' | 'discard';
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

// Always ensure the same order
const RESOURCES = [
  Resource.Wood,
  Resource.Clay,
  Resource.Sheep,
  Resource.Wheat,
  Resource.Stone,
];

export const Resources = ({
  resources,
  selectable,
  onSelectionChange,
  selectionType,
}: Props) => {
  const [selection, setSelection] = useState<ResourceCount>({});

  // Clean up selection if resources change
  useEffect(() => {
    setSelection({});
  }, [resources]);

  const changeSelection = (resource: Resource, amount: number) => {
    const newSelection = filterEmptyResources({
      ...selection,
      [resource]: amount,
    });
    setSelection(newSelection);
    onSelectionChange?.(newSelection);
  };

  return (
    <Wrapper>
      {RESOURCES.map((resource) => (
        <ResourceStack
          key={`player-resource-${resource}`}
          resource={resource}
          amount={resources[resource] || 0}
          selectable={!!selectable}
          onSelectionChange={(amount) => changeSelection(resource, amount)}
          selectionType={selectionType}
        />
      ))}
    </Wrapper>
  );
};
