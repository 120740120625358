import styled, { css } from 'styled-components';
import { useGameContext } from '../../game/GameProvider';
import { getDiceProbabilities } from './helpers';

const Badge = styled.circle`
  fill: beige;
  stroke-width: 2;
  ${({ diceNum }: { diceNum: number }) =>
    css`
      stroke: ${diceNum === 6 || diceNum === 8 ? '#bf1205' : 'black'};
    `}
`;

const Number = styled.text<{ diceNum: number }>`
  font-size: 1.7rem;
  text-anchor: middle;
  fill: ${({ diceNum }) =>
    diceNum === 6 || diceNum === 8 ? '#bf1205' : 'black'};
`;

const Wrapper = styled.g<{ prob: number }>`
  transform: scale(${({ prob }) => 0.7 + 0.15 * prob});
`;

const Rotator = styled.g<{ triggered: boolean }>`
  @keyframes spin {
    0% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }

  ${({ triggered }) =>
    triggered &&
    css`
      animation: spin 3s ease 2.2s infinite;
    `}
`;

export function DiceNumber({ diceNumber }: { diceNumber: number }) {
  const { diceNumber: rolledNumber } = useGameContext();

  const probability = getDiceProbabilities();

  return (
    <Rotator triggered={diceNumber === rolledNumber}>
      <Wrapper prob={probability[diceNumber]}>
        <Badge r='20' diceNum={diceNumber}></Badge>
        <Number y='9px' diceNum={diceNumber}>
          {diceNumber}
        </Number>
      </Wrapper>
    </Rotator>
  );
}
