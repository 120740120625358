import { PlayerColor } from '../engine/types';

declare enum GameCodeTag {
  type = 'GAME_CODE',
}
export type GameCode = string & GameCodeTag;

declare enum PlayerIdTag {
  type = 'PLAYER_ID',
}
export type PlayerId = string & PlayerIdTag;

export enum GameStatus {
  NOT_STARTED = 1,
  WAITING_PLAYERS = 2,
  PLAYING = 3,
  FINISHED = 4,
}

export enum GameRole {
  DIRECTOR = 1,
  PLAYER = 2,
  ONLINE_PLAYER = 3,
  ADMIN = 99,
}

export enum GameType {
  LOCAL = 1,
  ONLINE = 2,
}

export interface RemotePlayer {
  id: PlayerId;
  name: string;
  color: PlayerColor;
}

export interface RemoteState {
  gameStatus: GameStatus;
  code?: GameCode;
  role?: GameRole;
  players: RemotePlayer[];
}

export function getEmptyRemoteState(): RemoteState {
  return {
    gameStatus: GameStatus.NOT_STARTED,
    players: [],
  };
}
