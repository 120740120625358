import { useState, useEffect } from 'react';
import { InHandDevCard } from '../../../engine/player';
import { Wrapper, Card } from '../rewards/RewardCards';
import { DevCard } from '../developmentCards/DevelopmentCard';

interface DevCardProps {
  devCards: InHandDevCard[];
  onDismiss: () => void;
}

interface DevPrizeCard {
  card: InHandDevCard;
  tapped: boolean;
}

export const DevelopmentRewardCards = ({
  devCards,
  onDismiss,
}: DevCardProps) => {
  const [remainingCards, setRemainingCards] = useState<DevPrizeCard[]>([]);

  useEffect(() => {
    const item: DevPrizeCard[] = devCards.map((card) => {
      return { card, tapped: false };
    });
    setRemainingCards(item);
  }, [devCards]);

  const tap = (index: number) => {
    const newRemainingCards = [...remainingCards];
    newRemainingCards[index].tapped = true;
    setRemainingCards(newRemainingCards);

    // Give time to the animation to finish
    setTimeout(() => {
      if (newRemainingCards.filter((card) => !card.tapped).length === 0) {
        onDismiss();
      }
    }, 300);
  };

  return (
    <Wrapper>
      {remainingCards.map((devCard, index) => (
        <Card onClick={() => tap(index)} key={index} tapped={devCard.tapped}>
          <DevCard card={devCard.card.card} />
        </Card>
      ))}
    </Wrapper>
  );
};

DevelopmentRewardCards.displayName = 'DevelopmentRewardCards';
