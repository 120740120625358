import { TileCornerDir } from '../engine/tileHelpers';
import {
  ConstructionCosts,
  DevCardCount,
  DevelopmentCard,
  GenericResource,
  PortResources,
  Resource,
  ResourceCount,
} from '../engine/types';

export interface TileGameData {
  resource: Resource;
  diceNumber: number;
  ports?: { corner: TileCornerDir; resource: PortResources }[];
}

/**
 * Randomize resources in layout, if we want it to change
 */
function randomizeLayoutResources(
  layout: { [tileId: string]: TileGameData },
  numResources: { [resource: number]: number }
) {
  // TODO
}

export class BasicGameConfig {
  public readonly maxPlayers: number; // not very useful. Might want to remove.
  public readonly numResources: ResourceCount;
  public readonly boardRadius: number;
  public readonly resourcesLayout: { [tileId: string]: TileGameData };
  public readonly constructionCosts: ConstructionCosts;
  public readonly developmentCards: DevCardCount;

  constructor(randomizeResources = false) {
    this.maxPlayers = 4;
    this.boardRadius = 2;
    this.numResources = {
      [Resource.Clay]: 3,
      [Resource.Sheep]: 4,
      [Resource.Stone]: 3,
      [Resource.Wheat]: 4,
      [Resource.Wood]: 4,
      [Resource.Desert]: 1,
    };
    this.resourcesLayout = {
      '0,-2,2': {
        resource: Resource.Wood,
        diceNumber: 6,
        ports: [
          { corner: TileCornerDir.N, resource: GenericResource.Generic },
          { corner: TileCornerDir.NW, resource: GenericResource.Generic },
        ],
      },
      '1,-2,1': {
        resource: Resource.Sheep,
        diceNumber: 3,
        ports: [
          { corner: TileCornerDir.N, resource: Resource.Sheep },
          { corner: TileCornerDir.NE, resource: Resource.Sheep },
        ],
      },
      '2,-2,0': { resource: Resource.Sheep, diceNumber: 8 },
      '-1,-1,2': {
        resource: Resource.Wheat,
        diceNumber: 2,
        ports: [
          { corner: TileCornerDir.NW, resource: Resource.Stone },
          { corner: TileCornerDir.SW, resource: Resource.Stone },
        ],
      },
      '0,-1,1': { resource: Resource.Stone, diceNumber: 4 },
      '1,-1,0': { resource: Resource.Wheat, diceNumber: 5 },
      '2,-1,-1': {
        resource: Resource.Wood,
        diceNumber: 10,
        ports: [
          { corner: TileCornerDir.N, resource: GenericResource.Generic },
          { corner: TileCornerDir.NE, resource: GenericResource.Generic },
        ],
      },
      '-2,0,2': { resource: Resource.Wood, diceNumber: 5 },
      '-1,0,1': { resource: Resource.Clay, diceNumber: 9 },
      '0,0,0': { resource: Resource.Desert, diceNumber: 0 },
      '1,0,-1': { resource: Resource.Stone, diceNumber: 6 },
      '2,0,-2': {
        resource: Resource.Wheat,
        diceNumber: 9,
        ports: [
          { corner: TileCornerDir.NE, resource: GenericResource.Generic },
          { corner: TileCornerDir.SE, resource: GenericResource.Generic },
        ],
      },
      '-2,1,1': {
        resource: Resource.Wheat,
        diceNumber: 10,
        ports: [
          { corner: TileCornerDir.NW, resource: Resource.Wheat },
          { corner: TileCornerDir.SW, resource: Resource.Wheat },
        ],
      },
      '-1,1,0': { resource: Resource.Stone, diceNumber: 11 },
      '0,1,-1': { resource: Resource.Wood, diceNumber: 3 },
      '1,1,-2': {
        resource: Resource.Sheep,
        diceNumber: 12,
        ports: [
          { corner: TileCornerDir.SE, resource: Resource.Clay },
          { corner: TileCornerDir.S, resource: Resource.Clay },
        ],
      },
      '-2,2,0': {
        resource: Resource.Clay,
        diceNumber: 8,
        ports: [
          { corner: TileCornerDir.S, resource: GenericResource.Generic },
          { corner: TileCornerDir.SW, resource: GenericResource.Generic },
        ],
      },
      '-1,2,-1': {
        resource: Resource.Sheep,
        diceNumber: 4,
        ports: [
          { corner: TileCornerDir.SE, resource: Resource.Wood },
          { corner: TileCornerDir.S, resource: Resource.Wood },
        ],
      },
      '0,2,-2': { resource: Resource.Clay, diceNumber: 11 },
      // WIP
    };

    this.constructionCosts = {
      road: {
        [Resource.Clay]: 1,
        [Resource.Wood]: 1,
      },
      settlement: {
        [Resource.Clay]: 1,
        [Resource.Sheep]: 1,
        [Resource.Wheat]: 1,
        [Resource.Wood]: 1,
      },
      city: {
        [Resource.Stone]: 3,
        [Resource.Wheat]: 2,
      },
      devCard: {
        [Resource.Sheep]: 1,
        [Resource.Stone]: 1,
        [Resource.Wheat]: 1,
      },
    };

    this.developmentCards = {
      [DevelopmentCard.Knight]: 14,
      [DevelopmentCard.Monopoly]: 2,
      [DevelopmentCard.TwoResources]: 2,
      [DevelopmentCard.TwoRoads]: 2,
      [DevelopmentCard.VictoryPoint]: 5,
    };

    if (randomizeResources) {
      randomizeLayoutResources(this.resourcesLayout, this.numResources);
    }
  }
}
