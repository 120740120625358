import { ActionButton } from '@adobe/react-spectrum';
import styled from 'styled-components';

interface CurrentPlayerActionsProps {
  canMakeOffer: boolean;
  offerSent: boolean;
  onMakeOffer: () => void;
  canTradeWithBank: boolean;
  onTradeWithBank: () => void;
  canConfirmTrade: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const Actions = styled.div`
  display: grid;
  margin: 0 16px;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
`;

export const CurrentPlayerActions = ({
  canMakeOffer,
  offerSent,
  onMakeOffer,
  canTradeWithBank,
  onTradeWithBank,
  canConfirmTrade,
  onConfirm,
  onCancel,
}: CurrentPlayerActionsProps) => {
  return (
    <Actions>
      <ActionButton isDisabled={!canMakeOffer} onPress={onMakeOffer}>
        {!offerSent ? '🙂 ↔ 🙂🙂🙂' : 'Offer sent'}
      </ActionButton>
      <ActionButton isDisabled={!canTradeWithBank} onPress={onTradeWithBank}>
        🙂 ↔ 🏦
      </ActionButton>
      <ActionButton isDisabled={!canConfirmTrade} onPress={onConfirm}>
        🤝 Accept Trade
      </ActionButton>
      <ActionButton onPress={onCancel}>❌</ActionButton>
    </Actions>
  );
};

CurrentPlayerActions.displayName = 'CurrentPlayerActions';
