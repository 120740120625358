import styled, { css } from 'styled-components';
import { Player } from '../../engine/player';
import { GamePhase } from '../../game/GameState';
import { DiceRollingButton } from './DiceRollingButton';

interface Props {
  player: Player;
  current: boolean;
  diceNumber: number | undefined;
  phase: GamePhase;
  vertInvert?: boolean;
  horInvert?: boolean;
  onClick: () => void;
  onDiceClick: () => void;
  onDoubleClick: () => void;
}

const Summary = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  align-items: center;
  ${({
    vertInvert,
    horInvert,
  }: {
    vertInvert: boolean;
    horInvert: boolean;
  }) => {
    if (vertInvert && horInvert) {
      return css`
        grid-template-areas:
          'numbers avatar'
          'name avatar';
      `;
    }

    if (vertInvert && !horInvert) {
      return css`
        grid-template-areas:
          'avatar numbers'
          'avatar name';
      `;
    }

    if (!vertInvert && horInvert) {
      return css`
        grid-template-areas:
          'name avatar'
          'numbers avatar';
      `;
    }

    return css`
      grid-template-areas:
        'avatar name'
        'avatar numbers';
    `;
  }}
`;
const Avatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  grid-area: avatar;
  ${({ color }: { color: string }) =>
    css`
      background-color: ${color};
    `}
`;
const Name = styled.div`
  grid-area: name;
  display: flex;
  align-items: center;
  padding: 0 4px;
  ${({
    horInvert,
    vertInvert,
    color,
  }: {
    horInvert: boolean;
    vertInvert: boolean;
    color: string;
  }) =>
    css`
      justify-content: space-between;
      border-${vertInvert ? 'top' : 'bottom'}: 3px solid ${color};
      flex-direction: ${horInvert ? 'row-reverse' : 'row'};
    `}
`;
const Numbers = styled.ul`
  grid-area: numbers;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
`;

const Number = styled.li`
  padding: 0 4px;
`;

export const PlayerSummary = ({
  player,
  current,
  diceNumber,
  phase,
  vertInvert,
  horInvert,
  onClick,
  onDiceClick,
  onDoubleClick,
}: Props) => {
  return (
    <Summary
      vertInvert={!!vertInvert}
      horInvert={!!horInvert}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <Avatar color={player.getColor()} />
      <Name
        horInvert={!!horInvert}
        vertInvert={!!vertInvert}
        color={player.getColor()}
      >
        {player.getName()}
        {!!current && (
          <DiceRollingButton
            phase={phase}
            diceNumber={diceNumber}
            onClick={onDiceClick}
          />
        )}
      </Name>
      {/* <Separator /> */}
      <Numbers>
        <Number>🏆 {player.getVictoryPoints()}</Number>
        <Number>🎴 {player.getNumResources()}</Number>
        <Number>🐴 {player.getNumPlayedKnights()}</Number>
        <Number>🛣 {player.getLongestRoadSize()}</Number>
      </Numbers>
    </Summary>
  );
};
PlayerSummary.displayName = 'PlayerSummary';
