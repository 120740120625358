import { useEffect, useState } from 'react';
import { PlayerColor, ResourceCount } from '../../../engine/types';
import { RewardScreen } from './RewardScreen';
import { diffResources } from './helpers';
import { InHandDevCard } from '../../../engine/player';
import { ResourceRewardCards } from './ResourceRewardCards';
import { DevelopmentRewardCards } from '../resources/DevelopmentRewardCards';

interface Props {
  resources: Partial<ResourceCount>;
  color: PlayerColor;
  devCards: InHandDevCard[];
}

export const RewardLayer = ({ resources, devCards, color }: Props) => {
  const [justInit, setJustInit] = useState(true);
  const [reward, setReward] = useState<Partial<ResourceCount> | null>(null);
  const [prevResources, setPrevResources] = useState<Partial<ResourceCount>>(
    {}
  );
  const [rewardDevCards, setRewardDevCards] = useState<InHandDevCard[] | null>(
    null
  );
  const [prevDevCards, setPrevDevCards] = useState<InHandDevCard[]>([]);

  const onClose = () => {
    setReward(null);
    setRewardDevCards(null);
  };

  // Keep track of the change in resources, to determine if we should show the reward
  useEffect(() => {
    // We don't want an app refresh to trigger this UI
    if (justInit) {
      setJustInit(false);
      setPrevResources(resources);
      setPrevDevCards(devCards);
      return;
    }

    const diff = diffResources(prevResources, resources);
    if (Object.keys(diff).length > 0) {
      setReward(diff);
      setPrevResources(resources);
    }

    const devCardDiff = devCards.length - prevDevCards.length;
    if (devCardDiff > 0) {
      setRewardDevCards(devCards.slice(-devCardDiff));
      setPrevDevCards(devCards);
    }
  }, [resources, prevResources, justInit, devCards, prevDevCards]);

  if (reward) {
    return (
      <RewardScreen color={color} onClose={onClose}>
        {(onDismiss) => (
          <ResourceRewardCards resources={reward} onDismiss={onDismiss} />
        )}
      </RewardScreen>
    );
  }

  if (rewardDevCards) {
    return (
      <RewardScreen color={color} onClose={onClose}>
        {(onDismiss) => (
          <DevelopmentRewardCards
            devCards={rewardDevCards}
            onDismiss={onDismiss}
          />
        )}
      </RewardScreen>
    );
  }

  return null;
};
