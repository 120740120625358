import { Debug } from '../Debug';
import { CatanBoard } from '../view/CatanBoard';
import { DebugToggleBoat } from '../view/debug/DebugToggleBoat';
import { PlayerSummaryList } from '../view/playerSummary/PlayerSummaryList';
import { GameProvider } from './GameProvider';
import { useState, useEffect } from 'react';
import {
  getQueryParam,
  removeQueryParam,
  setQueryParam,
} from '../queryParamHelpers';

export const DirectorGame = () => {
  const [debug, setDebug] = useState(false);

  useEffect(() => {
    if (getQueryParam('debug') === '1') {
      setDebug(true);
    }
  }, []);

  const toggleDebug = () => {
    if (debug) {
      setDebug(false);
      removeQueryParam('debug');
    } else {
      setDebug(true);
      setQueryParam('debug', '1');
    }
  };

  return (
    <>
      <GameProvider>
        <Debug enabled={debug}>
          <CatanBoard />
          <PlayerSummaryList />
        </Debug>
      </GameProvider>
      <DebugToggleBoat isDebugging={debug} onToggleDebug={toggleDebug} />
    </>
  );
};
