import { Edge as EdgeData } from '../../engine/edge';
import styled, { css } from 'styled-components';
import { BaseTile } from '../../engine/tile';
import {
  assertPlaceRoad,
  getCornerEdgesFromCorner,
  getEdgeEndpoints,
  getTileEdgeDirFromHoldingTile,
  TileEdge,
} from '../../engine/tileHelpers';
import { useGameContext } from '../../game/GameProvider';
import { GamePhase } from '../../game/GameState';
import { Tiles } from '../../engine/boardHelpers';
import { Player } from '../../engine/player';
import { PlayerColor } from '../../engine/types';
import { BasicGameConfig } from '../../game/config';

interface Props {
  onClick?: (edge: EdgeData, tile: BaseTile, dir: TileEdge) => void;
  edge: EdgeData;
  coords: { x: number; y: number };
  tile: BaseTile;
  tiles: Tiles;
  dir: TileEdge;
  height: number;
  position: TileEdge;
  playing?: boolean;
}

const BaseEdge = styled.rect`
  fill: gray;
  stroke: black;
  stroke-width: 2;
  transform-box: fill-box;
  transition: opacity 200ms ease-in-out;
  opacity: 0;

  ${({ position }: { position: TileEdge }) =>
    css`
      transform: rotate(
        ${position === TileEdge.NE ? 300 : position === TileEdge.NW ? 60 : 0}deg
      );
    `}
`;

type ClickableEdgeProps = {
  x: number;
  y: number;
  hintColor?: string;
};

const ClickableEdge = styled(BaseEdge)`
  @keyframes show-edge {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.7;
    }
  }
  animation: show-edge 0.6s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  ${({ hintColor }: ClickableEdgeProps) =>
    hintColor &&
    css`
      opacity: 0.7;
      fill: transparent;
      stroke-dasharray: 8;

      &:hover {
        fill: ${hintColor};
      }
    `}
`;

const OwnedEdge = styled(BaseEdge)`
  opacity: 0.8;

  ${({ color }: { color: PlayerColor }) =>
    css`
      fill: ${color};
      stroke: white;
    `}
`;

const clickablePhases: GamePhase[] = [
  'initialSetup',
  'initialSetup_secondBuild',
  'construction',
  'devCard_road1',
  'devCard_road2',
];

export function Edge({
  edge,
  tile,
  tiles,
  dir,
  coords,
  height,
  onClick,
  position,
  playing,
}: Props) {
  const { phase, currentPlayer } = useGameContext();
  const clickable =
    !!playing &&
    !!currentPlayer &&
    isClickable(currentPlayer, phase, tile, dir, tiles);

  const commonProps = {
    width: '10',
    height: height,
    x: coords.x,
    y: coords.y,
    onClick: () => clickable && onClick?.(edge, tile, dir),
    position: position,
  };

  let Edge = null;
  const owner = edge.getOwner();

  if (owner) {
    Edge = <OwnedEdge {...commonProps} color={owner.getColor()} />;
  } else if (clickable) {
    // Determine if this edge is elegible for building, then hint it
    const player = currentPlayer;
    Edge = <ClickableEdge {...commonProps} hintColor={player.getColor()} />;
  } else {
    Edge = <BaseEdge {...commonProps} />;
  }

  return Edge;
}

function isClickable(
  currentPlayer: Player,
  phase: GamePhase,
  tile: BaseTile,
  dir: TileEdge,
  tiles: Tiles
) {
  if (phase === 'initialSetup' && currentPlayer.getNumRoad() === 1) {
    return false;
  }

  if (phase === 'initialSetup_secondBuild') {
    if (
      currentPlayer.getNumRoad() === 2 ||
      // do not hint roads until second settlement is ready
      currentPlayer.getNumSettlements() === 1
    ) {
      return false;
    }

    const tileEdgeDir = getTileEdgeDirFromHoldingTile(dir);
    if (tileEdgeDir !== undefined) {
      // Do not hint this edge if it's adjacent to the first settlement
      // first find if this road doesn't have any settlement in an end. That means it's part of the first setlement.
      if (
        !getEdgeEndpoints(tile, tileEdgeDir, tiles).find((corner) =>
          corner?.hasSettlement()
        )
      ) {
        return false;
      }

      // Now, check if this road is adjacent to any other built road,
      // if so, only hint this road if it has a settlement in each end (meaning
      // it can be just a connection between two settlements in setup phase)
      if (
        getEdgeEndpoints(tile, tileEdgeDir, tiles).find(
          (corner) =>
            corner &&
            corner.hasSettlement() &&
            !!getCornerEdgesFromCorner(corner, tiles)?.find((edge) =>
              edge.isOwner(currentPlayer)
            )
        )
      ) {
        return false;
      }
    }
  }

  // Determine if the edge is not viable to construct due to engine restrictions
  if (
    !assertPlaceRoad(
      tile,
      getTileEdgeDirFromHoldingTile(dir)!,
      tiles,
      currentPlayer,
      true
    )
  ) {
    return false;
  }

  const isFree =
    phase === 'initialSetup' ||
    phase === 'initialSetup_secondBuild' ||
    phase === 'devCard_road1' ||
    phase === 'devCard_road2';
  // TODO: Provide GameConfig from the instance of the current game!
  if (
    !isFree &&
    !currentPlayer.hasEnoughResources(
      new BasicGameConfig().constructionCosts.road
    )
  ) {
    return false;
  }

  return clickablePhases.indexOf(phase) !== -1;
}
