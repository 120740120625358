import React from 'react';
import { getQueryParam } from '../queryParamHelpers';
import { GameRemote, getOrCreateGameRemote } from './GameRemote';
import {
  GameCode,
  GameRole,
  getEmptyRemoteState,
  PlayerId,
  RemoteState,
} from './RemoteState';

const remoteInit: RemoteState = getEmptyRemoteState();

const RemoteContext = React.createContext<{
  remoteState: RemoteState;
  remote: GameRemote;
}>({ remoteState: remoteInit, remote: {} as GameRemote });

export const useRemoteContext = () =>
  React.useContext(RemoteContext).remoteState;

export const useRemote = () => React.useContext(RemoteContext).remote;

/**
 * Remote controller
 */
export const RemoteProvider = ({ children }: React.PropsWithChildren) => {
  const [remoteState, setRemoteState] = React.useState(remoteInit);

  // Initialize the remote controller and memoize it
  const remote = React.useMemo(() => {
    const gameRemote = getOrCreateGameRemote(
      remoteInit,
      (newState: RemoteState) => {
        setRemoteState(newState);
      }
    );

    const game = getQueryParam('game') as GameCode;
    const role = getQueryParam('role');
    const playerId = getQueryParam('playerId') as PlayerId;

    if (role !== null) {
      if (+role === GameRole.ADMIN) {
        gameRemote.playAdmin();
      } else if (game) {
        gameRemote.restoreGame(game, +role, playerId);
      }
    }

    return gameRemote;
  }, []);

  const value = React.useMemo(
    () => ({ remoteState, remote }),
    [remoteState, remote]
  );

  return (
    <RemoteContext.Provider value={value}>{children}</RemoteContext.Provider>
  );
};
