import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Resource, ResourceCount } from '../../engine/types';
import { GamePhase } from '../../game/GameState';
import { Resources } from '../player/resources/Resources';
import { ActionButton } from '@adobe/react-spectrum';
import { filterEmptyResources } from '../player/trade/helpers';

type ResourcesSelectorCallback = (selection: ResourceCount) => void;

interface Props {
  onSelect: ResourcesSelectorCallback;
  phase: GamePhase;
}

const Selector = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  inset: 0;
  align-items: center;
  justify-content: center;
  background: rgba(200, 200, 200, 0.8);
  backdrop-filter: blur(2px);
  gap: 16px;
`;

const getResourceSelectorAmount = (phase: GamePhase) => {
  switch (phase) {
    case 'devCard_2resources':
      return 2;
    case 'devCard_monopoly':
    case 'trade':
      return 1;
  }
  return 0;
};

export const ResourcesSelector = ({ onSelect, phase }: Props) => {
  const [amountSelected, setAmountSelected] = useState<ResourceCount>({});
  const [resources, setResources] = useState<ResourceCount>({});

  useEffect(() => {
    const amount = getResourceSelectorAmount(phase);
    const r: ResourceCount = {};
    [
      Resource.Wood,
      Resource.Sheep,
      Resource.Clay,
      Resource.Wheat,
      Resource.Stone,
    ].map((res) => (r[res] = amount));
    setResources(r);
  }, [phase]);

  const handleSelect = () => {
    onSelect(filterEmptyResources(amountSelected));
  };

  const numSelectedResources = Object.values(amountSelected).reduce(
    (acc, resAmount) => acc + resAmount,
    0
  );
  const left = getResourceSelectorAmount(phase) - numSelectedResources;

  return (
    <Selector>
      <Resources
        resources={resources}
        selectable={left > 0}
        onSelectionChange={setAmountSelected}
        selectionType='select'
      />
      <ActionButton isDisabled={left > 0} onPress={handleSelect}>
        Confirm {left > 0 && ` (${left} left)`}
      </ActionButton>
    </Selector>
  );
};
