import styled, { css } from 'styled-components';
import { PlayerColor } from '../../../engine/types';

interface CityProps {
  color: PlayerColor;
  available: boolean;
}

const StyledSvg = styled.svg`
  width: 20px;
`;

const StyledCircle = styled.circle`
  stroke: black;
  stroke-width: 5%;

  ${({ color, available }: CityProps) =>
    css`
      fill: ${available ? color : 'transparent'};
      stroke-dasharray: ${available ? 20 : 5}%;
    `}
`;

export const City = (props: CityProps) => {
  return (
    <StyledSvg viewBox='0 0 1 1'>
      <StyledCircle r='45%' cx='50%' cy='50%' {...props} />
    </StyledSvg>
  );
};

City.displayName = 'City';
